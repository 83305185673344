export default function CashFastSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg" 
      width="22" 
      height="11" 
      viewBox="0 0 22 11" 
      fill="none"
      {...props}
    >
       <path d="M15.2178 3.55667C14.718 3.04126 14.0401 2.75171 13.3333 2.75171C12.6265 2.75171 11.9487 3.04126 11.4489 3.55667C10.9491 4.07207 10.6683 4.77111 10.6683 5.5C10.6683 6.22889 10.9491 6.92793 11.4489 7.44333C11.6964 7.69854 11.9901 7.90097 12.3135 8.03909C12.6368 8.1772 12.9834 8.24829 13.3333 8.24829C13.6833 8.24829 14.0299 8.1772 14.3532 8.03909C14.6765 7.90097 14.9703 7.69854 15.2178 7.44333C15.4652 7.18813 15.6615 6.88516 15.7955 6.55172C15.9294 6.21829 15.9983 5.86091 15.9983 5.5C15.9983 5.13909 15.9294 4.78171 15.7955 4.44828C15.6615 4.11484 15.4652 3.81187 15.2178 3.55667ZM6.22222 0V11H20.4444V0H6.22222ZM18.6667 7.33333C18.1956 7.33333 17.7422 7.52583 17.4133 7.87417C17.0756 8.21333 16.8889 8.68083 16.8889 9.16667H9.77778C9.77778 8.68083 9.59111 8.21333 9.25333 7.87417C8.92444 7.52583 8.47111 7.33333 8 7.33333V3.66667C8.47111 3.66667 8.92444 3.47417 9.25333 3.12583C9.59111 2.78667 9.77778 2.31917 9.77778 1.83333H16.8889C16.8889 2.31917 17.0756 2.78667 17.4133 3.12583C17.7422 3.47417 18.1956 3.66667 18.6667 3.66667V7.33333ZM4.44444 1.83333H2.66667C2.17778 1.83333 1.77778 1.42083 1.77778 0.916667C1.77778 0.4125 2.17778 0 2.66667 0H4.44444V1.83333ZM4.44444 6.41667H1.77778C1.28889 6.41667 0.888889 6.00417 0.888889 5.5C0.888889 4.99583 1.28889 4.58333 1.77778 4.58333H4.44444V6.41667ZM4.44444 11H0.888889C0.398222 11 0 10.5875 0 10.0833C0 9.57917 0.398222 9.16667 0.888889 9.16667H4.44444V11Z" fill="white"/>
    </svg>
  )
}