import { React, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import IntlCurrencyInput from 'react-intl-currency-input';

import LogoBranca from '../../assets/logo-branca.png';
import { history } from '../../history';

import { Formik, Field } from 'formik';

import '../../pages/Cadastro/styles.css';

import CpfInputMask from '../../components/MaskedInput';
import api from '../../utils/services';
import Swal from 'sweetalert2';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const CadastroBancario = ({ step, setStep }) => {
  const [accountType, setAccountType] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [cpfOrCnpj, setCpfOrCnpj] = useState(false);
  const [isCpf, setIsCpf] = useState(false);
  const [monthlyincome, setMonthlyincome] = useState(0);

  const onChangeSelectedBankCode = (name) => {
    const code = accountType.find((item) => item.name === name);
    if (code !== undefined) {
      setSelectedBank(code.number);
    }
  };

  const handleDataUser = () => {
    api
      .get('/establishments/self')
      .then((res) => {
        localStorage.setItem('id', res.data.id);
        Swal.fire({
          title: 'Tudo certo',
          text: 'Suas vendas começam logo ali!!',
          icon: 'success',
          confirmButtonText: 'Acessar o painel!',
        }).then((result) => {
          if (result.isConfirmed) {
            history.push('/dashboard');
          }
        });
      })
      .catch((error) => {
        Swal.fire('Algo deu errado, tente novamente', '', 'warning');
        console.log('Ocorreu um erro', error);
        window.location.reload();
      });
  };

  const handleAccountType = () => {
    api
      .get('/bank-accounts/available-banks')
      .then((res) => {
        setAccountType(res.data);
      })
      .catch((err) => {
        //console.log('erro ao listar accounts type', err)
        Swal.fire('Algo deu errado, tente novamente', '', 'warning');
      });
  };

  async function onSubmit(values, actions) {
    let data = {};
    let holderType = 'individual';

    if (!isCpf) holderType = 'company';

    if (values.accountDigitNumber === '') {
      data = {
        holderName: values.holderName,
        holderType: holderType,
        holderDocument: values.holderDocument,
        agencyNumber: values.agencyNumber,
        agencyDigitNumber: values.agencyDigitNumber,
        bankNumber: selectedBank,
        accountNumber: values.accountNumber,
        type: values.type,
        motherName: values.motherName,
        professionalOccupation: values.professionalOccupation,
        monthlyIncome: monthlyincome,
      };
    } else if (values.agencyDigitNumber === '') {
      data = {
        holderName: values.holderName,
        holderType: holderType,
        holderDocument: values.holderDocument,
        agencyNumber: values.agencyNumber,
        accountDigitNumber: values.accountDigitNumber,
        bankNumber: selectedBank,
        accountNumber: values.accountNumber,
        type: values.type,
        motherName: values.motherName,
        professionalOccupation: values.professionalOccupation,
        monthlyIncome: monthlyincome,
      };
    } else {
      data = {
        holderName: values.holderName,
        holderType: holderType,
        holderDocument: values.holderDocument,
        agencyNumber: values.agencyNumber,
        accountDigitNumber: values.accountDigitNumber,
        bankNumber: selectedBank,
        accountNumber: values.accountNumber,
        agencyDigitNumber: values.agencyDigitNumber,
        type: values.type,
        motherName: values.motherName,
        professionalOccupation: values.professionalOccupation,
        monthlyIncome: monthlyincome,
      };
    }

    api
      .post('bank-accounts', data)
      .then((_) => {
        handleDataUser();
      })
      .catch((_) => {
        Swal.fire({
          title: 'Erro',
          text: 'Algo deu errado. Tente novamente!',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      });
  }

  useEffect(() => {
    handleAccountType();
  }, []);

  const handleChangeCurrency = (event, value) => {
    event.preventDefault();
    setMonthlyincome(value);
  };

  return (
    <div className="container">
      <div className="content">
        <img
          style={{ marginTop: 30 }}
          id="logo"
          onClick={() => history.goBack()}
          src={LogoBranca}
          alt=""
        />

        <Formik
          onSubmit={onSubmit}
          initialValues={{
            holderName: '',
            holderDocument: '',
            agencyNumber: '',
            agencyDigitNumber: '',
            bankNumber: '',
            accountNumber: '',
            accountDigitNumber: '',
            motherName: '',
            monthlyIncome: '',
            professionalOccupation: '',
          }}
          render={({ values, handleSubmit, handleChange, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="box-form">
              <div className="box">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h1>Informe dados sobre sua conta bancaria</h1>
                  <h4 style={{ color: '#38a2e9' }}>Etapa 3 de 3</h4>
                </div>
                <div className="input-column">
                  <div className="input-container">
                    <label>Nome do proprietário da conta*</label>
                    <Field
                      required
                      className="c1"
                      type="text"
                      name="holderName"
                    />
                  </div>

                  <div className="input-container">
                    <label className="justify-label">
                      CPF/CNPJ*
                      {cpfOrCnpj === false ? (
                        <span></span>
                      ) : (
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => setCpfOrCnpj(false)}
                        >
                          <svg
                            width="16px"
                            height="16px"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="var(primary-cor)"
                            class="bi bi-arrow-return-left"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"
                            />
                          </svg>
                        </span>
                      )}
                    </label>
                    {cpfOrCnpj === false ? (
                      <div style={{ margin: 0 }}>
                        <button
                          className="btn-cpfCnpj"
                          onClick={() => {
                            setCpfOrCnpj(true);
                            setIsCpf(true);
                          }}
                          type="button"
                        >
                          CPF
                        </button>
                        <button
                          style={{ marginLeft: 10 }}
                          className="btn-cpfCnpj"
                          onClick={() => {
                            setCpfOrCnpj(true);
                            setIsCpf(false);
                          }}
                          type="button"
                        >
                          CNPJ
                        </button>
                      </div>
                    ) : (
                      <div style={{ margin: 0 }}>
                        {isCpf === true ? (
                          <CpfInputMask
                            name="holderDocument"
                            mask="999.999.999-99"
                            value={values.holderDocument}
                            onChange={(event) =>
                              setFieldValue(
                                'holderDocument',
                                event.target.value
                              )
                            }
                          />
                        ) : (
                          <CpfInputMask
                            name="holderDocument"
                            mask="99.999.999/9999-99"
                            value={values.holderDocument}
                            onChange={(event) =>
                              setFieldValue(
                                'holderDocument',
                                event.target.value
                              )
                            }
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div className="input-column">
                  <div className="input-row">
                    <div className="input-container">
                      <label>Ocupação Profissional*</label>
                      <Field
                        required
                        type="text"
                        className="c1"
                        name="professionalOccupation"
                      />
                    </div>

                    <div
                      style={{
                        display: values.bankNumber === '104' ? 'none' : 'flex',
                      }}
                      className="input-container"
                    >
                      <label>Renda mensal*</label>
                      <IntlCurrencyInput
                        currency="BRL"
                        config={currencyConfig}
                        onChange={handleChangeCurrency}
                        name="monthlyIncome"
                      />
                    </div>
                  </div>
                </div>

                <div className="input-column">
                  <div className="input-container">
                    <label>Nome da mãe</label>
                    <Field
                      required
                      className="c1"
                      type="text"
                      name="motherName"
                    />
                  </div>
                </div>

                {/* 1 Linha  */}

                <div className="input-column">
                  <div className="input-row">
                    <div className="input-container">
                      <label>Nome do banco*</label>
                      <input
                        autocomplete="off"
                        type="search"
                        id="search"
                        onChange={handleChange}
                        onBlur={() =>
                          onChangeSelectedBankCode(values.bankNumber)
                        }
                        name="bankNumber"
                        placeholder="Pesquisar..."
                        list="options"
                      />

                      {/* <Field required maxlength="6"  type="text" className="c2" name="bankNumber" /> */}
                      <datalist id="options" required className="c2">
                        <option value="" placeholder="Escolha uma opção">
                          Escolha uma tipo
                        </option>
                        {accountType.map((item, index) => {
                          return (
                            <option
                              key={index}
                              value={item.name}
                              name="bankNumber"
                            >
                              {item.name}
                            </option>
                          );
                        })}
                      </datalist>
                      <p>Você escolheu: {values.bankNumber}</p>
                    </div>
                  </div>
                </div>

                <div className="input-column">
                  <div className="input-row">
                    <div className="input-container">
                      <label>Tipo de conta*</label>
                      <select
                        onChange={handleChange}
                        className="c2"
                        name="type"
                      >
                        <option value="" placeholder="Escolha uma opção">
                          Escolha uma tipo*
                        </option>
                        <option value="checking" name="type">
                          Corrente
                        </option>
                        <option value="savings" name="type">
                          Poupança
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* 2 Linha  */}

                <div className="input-column">
                  <div className="input-row">
                    <div className="input-container">
                      <label>Número da agência*</label>
                      <Field
                        required
                        type="text"
                        className="c2"
                        name="agencyNumber"
                      />
                    </div>

                    <div
                      style={{
                        display: values.bankNumber === '104' ? 'none' : 'flex',
                      }}
                      className="input-container"
                    >
                      <label>Dígito</label>
                      <Field
                        type="text"
                        className="c4"
                        name="agencyDigitNumber"
                      />
                    </div>
                  </div>
                </div>

                {/* 3 Linha  */}

                <div className="input-column">
                  <div className="input-row">
                    <div className="input-container">
                      <label>Número da conta*</label>
                      <Field
                        required
                        type="text"
                        className="c2"
                        name="accountNumber"
                      />
                    </div>
                    <div className="input-container">
                      <label>Dígito*</label>
                      <Field
                        required
                        type="text"
                        className="c4"
                        name="accountDigitNumber"
                      />
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  onClick={() => onSubmit(values)}
                  className="btn-submit"
                >
                  Cadastrar
                </button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default CadastroBancario;
