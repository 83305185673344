import React, { useState, useEffect, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Alert from '../modal/Alert';

import api from '../../utils/services';
// import {getId } from '../../utils/auth'

import DefaultProfile from '../../assets/default-profile.png';
import Swal from 'sweetalert2';

import loadingGif from '../../assets/loading.gif';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    marginTop: 15,
    // flexDirection: 'column',
  },
}));

const GeneralInfo = ({ setLoading }) => {
  const classes = useStyles();

  const [file, setFile] = useState('');
  const preview = useMemo(() => {
    return file ? URL.createObjectURL(file) : null;
  }, [file]);

  const [generalInfo, setGeneralInfo] = useState({});
  const [newGeneralInfo, setNewGeneralInfo] = useState({});
  // const [establishment, setEstablishment] = useState({})
  const [info, setInfo] = useState(false);

  const [action, setAction] = useState(false);
  const [submit, setSubmit] = useState(false);

  const handleChangeGeneralInfo = (event) => {
    const { name, value } = event.target;
    setNewGeneralInfo({ ...newGeneralInfo, [name]: value });
  };

  const handleModal = () => {
    setAction(true);
  };

  function onChangeImg(ev) {
    // setFieldValue('profileImage', ev.target.files[0])
    setFile(ev.target.files[0]);
  }
  useEffect(() => {
    async function loadUser() {
      if (Object.keys(generalInfo).length === 0) {
        api
          .get('establishments/self')
          .then((res) => {
            setGeneralInfo(res.data);
          })
          .catch((error) => {
            // alert('Sua sessão expirou, faça o Login novamente')
            console.log('Erro', error);
            //  logout();
            //window.location.reload()
          });
      }
    }
    loadUser();
  }, []);

  useEffect(() => {
    async function handlesubmit() {
      if (submit === true) {
        setLoading(true);
        let formData = new FormData();

        Object.entries(newGeneralInfo).map(([key, value]) => {
          return formData.append(key, value);
        });

        formData.append('profileImage', file);
        api
          .put(`users`, formData)
          .then((res) => {
            Swal.fire('Informações de usuário cadastrada com sucesso!');
            setLoading(false);
            setSubmit(false);
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo deu errado, tente novamente',
            });
            setSubmit(false);
            setLoading(false);
          });
      }
    }
    handlesubmit();
  }, [submit]);

  return (
    <>
      {Object.keys(generalInfo).length === 0 ? (
        <h2>Carregando...</h2>
      ) : (
        <>
          <Paper elevation={0} className={classes.paper}>
            {action === true ? (
              <Alert
                Title="Tem certeza que deseja alterar essas informações?"
                SubTitle="Clicando em Sim, os dados enviados irão alterar. Para corrigir é so repetir o processo"
                onClose={() => setAction(false)}
                onSubmit={() => setSubmit(true)}
              />
            ) : null}

            <div className="form-edit">
              <div
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                className="form-btn-edit-row"
              >
                <h2>Editar Informações</h2>
                {info === false ? (
                  <button
                    onClick={() => setInfo(true)}
                    style={{ backgroundColor: '#4A78FF' }}
                    className="btn-action-edit"
                  >
                    Editar
                  </button>
                ) : (
                  <div>
                    <button
                      onClick={() => setInfo(false)}
                      style={{ backgroundColor: '#E8505B' }}
                      className="btn-action-edit"
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={() => {
                        setInfo(false);
                        handleModal();
                      }}
                      style={{ backgroundColor: '#31DD61', marginLeft: 10 }}
                      className="btn-action-edit"
                    >
                      Salvar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Paper>

          {/* Info ADM */}

          <Paper elevation={0} className={classes.paper}>
            <div className="form-edit">
              <h3 style={{ marginBottom: '20px' }}>
                Informações do Administrador
              </h3>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <label>Imagem do Estabelicimento: </label> */}

                {info === false ? (
                  <img
                    alt="Perfil do empreendedor"
                    style={{ width: '100px', borderRadius: '50%' }}
                    src={generalInfo.user.profileImage}
                  />
                ) : (
                  <>
                    <label>Adicione uma imagem de perfil</label>
                    {!preview ? (
                      <>
                        <label htmlFor="profileImage">
                          <img
                            for
                            style={{
                              width: '100px',
                              borderRadius: '50%',
                              cursor: 'pointer',
                            }}
                            src={generalInfo.user.profileImage}
                            alt="Default imagem Profile"
                          />
                        </label>
                      </>
                    ) : (
                      <img
                        src={preview}
                        alt="Foto do produto"
                        style={{ cursor: 'pointer' }}
                        width="250px"
                        height="250px"
                        samesite="none"
                      />
                    )}
                    <input
                      type="file"
                      style={{ width: '100%', opacity: 0, fontSize: 1 }}
                      id="profileImage"
                      name="profileImage"
                      onChange={onChangeImg}
                    />
                  </>
                )}
              </div>

              <div className="form-edit-row">
                <label>Nome: </label>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="name"
                  onChange={handleChangeGeneralInfo}
                  defaultValue={generalInfo.user.name}
                />
              </div>

              <div className="form-edit-row">
                <label>Sobrenome: </label>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="lastName"
                  onChange={handleChangeGeneralInfo}
                  defaultValue={generalInfo.user.lastName}
                />
              </div>

              <div className="form-edit-row">
                <label>Email: </label>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="email"
                  onChange={handleChangeGeneralInfo}
                  defaultValue={generalInfo.user.email}
                />
              </div>

              <div className="form-edit-row">
                <label>CPFJ/CNPJ: </label>
                <input
                  id="input-focus-width"
                  disabled={true}
                  type="text"
                  name="cpfCnpj"
                  onChange={handleChangeGeneralInfo}
                  defaultValue={generalInfo.user.cpfCnpj}
                />
              </div>

              <div className="form-edit-row">
                <label>Telefone: </label>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="phone"
                  onChange={handleChangeGeneralInfo}
                  defaultValue={generalInfo.user.phone}
                />
              </div>
            </div>
          </Paper>
        </>
      )}
    </>
  );
};

export default GeneralInfo;
