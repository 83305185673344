import { Box, Container, Grid, Paper } from '@material-ui/core';
import React, { useState, useMemo } from 'react'
import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import { makeStyles } from '@material-ui/core/styles';

import ObsSection from '../../../components/obsSection';

import PhotoCamera from '@material-ui/icons/PhotoCamera';
import ImagemVertical from '../../../assets/Imagem-na-vertical.png'
import api from '../../../utils/services';
import { ArrowBack } from '@material-ui/icons';

import loadingGif from '../../../assets/loading.gif'
import Swal from 'sweetalert2';

// import { history } from '../../../history'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  titleContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: 'row',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 0,
    height: '100vh',
    overflow: 'auto',
    padding: '0',
    width: '100vw',
    backgroundColor: '#f5f4f6',
    // backgroundColor: '#F4F5F7',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

}));

const CreateStory = () => {

  const classes = useStyles();

  const [loading, setLoading] = useState(false)

  const [file, setFile] = useState('');

  const preview = useMemo(() => {
    return file ? URL.createObjectURL(file) : null;
  }, [file]);

  function onChangeImg(ev) {
    // setFieldValue('profileImage', ev.target.files[0])
    setFile(ev.target.files[0])
  }

  const handleSubmit = () =>{
    setLoading(true)

    let formData = new FormData();
    formData.append('files', file)
    formData.append('active', true)

    api.post('/stories', formData).then( _ =>{
      //alert('Tudo certo. Story publicado');
      Swal.fire({
        title: 'Tudo certo',
        text: 'Story publicado',
        icon: 'success',
        confirmButtonText: 'Continuar'
      }).then(_ => setFile(''))
      
      setLoading(false)
    }).catch( err => {
      setLoading(false)
      alert('Algo deu errado, tente novamente mais tarde')
      console.log('Erro ao enviar story', err)
    })
  }

  return (
    <div className={classes.root}>

      {
        loading ?
          <div className='loading-container'>
            <img src={loadingGif} alt="Gif de carregamento" />
          </div>
          :
          null
     }

      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

          <ObsSection
            icon={<PhotoCamera />}
            title="Story"
            message="Adicione story no perfil da sua loja para aumentar a conversão de clientes"
          />

            <Paper className={classes.titleContainer} elevation={0}  >
              <h1>Adicione um Story</h1>
              <ArrowBack  width="40px" style={{cursor: "pointer"}} onClick={() => window.location.pathname = "story"} />
             </Paper>


          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item xs={12} sm="2" md="3"  elevation={0} className={classes.paper}>
                <div style={{ marginTop: 30 }} className="input-container">
                  <div style={{ display: 'flex' }} >
                    {
                      !preview ? null : <button  onClick={handleSubmit} style={{ width: '100%'}} className='btn-adc-primary' >Salvar</button>
                    }
                  </div>
                  {!preview ? (
                    <>
                      <label htmlFor="profileImage">
                        <img
                          for
                          style={{
                            cursor: 'pointer',
                            width: '100%',
                            objectFit: 'contain'
                          }}
                          src={ImagemVertical}
                          alt="Default imagem Profile"
                        />
                      </label>
                    </>
                  ) : (
                    <div>
                        <button  onClick={() => { setFile(); }} style={{width: '100%', marginTop: '10px'}} className='btn-cancel-primary'>Cancelar</button>
                      <img
                        src={preview}
                        alt="Foto do produto"
                        style={{cursor: 'pointer', width: '100%', marginTop: '10px'}}

                        samesite="none"
                      />
                    </div>
                  )}
                  <input type="file"

                    style={{ width: '100%', opacity: 0, fontSize: 1 }}
                    id="profileImage"
                    name="profileImage"
                    onChange={(ev) => onChangeImg(ev)}
                  />
                </div>
          
              </Grid>


            </Grid>

          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default CreateStory;




