import { useState } from 'react';
import api from '../../../../utils/services';
import { getId } from '../../../../utils/auth';
import MaterialTable from 'material-table';
import defaultImage from '../../../../assets/default-profile.png';
import CardPromotionAnnouncements from './CardPromotionAnnouncements';
import formatedCurrencyBr from '../../../../common/formatCurrency';
import Swal from 'sweetalert2';

const initialInfosPromotionAnnouncements = {
  active: '',
  limit: 0,
  onlyNewClients: '',
  totalPerUser: 0,
};

const ContentModalAddAnnouncements = ({
  handleClose,
  announcements,
  setAnnouncements,
  setHasChanges,
}) => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const [productsSelected, setProductsSelected] = useState([]);
  const [listProducts, setListProducts] = useState([]);

  const [infosPromotionAnnouncements, setInfosPromotionAnnouncements] =
    useState(initialInfosPromotionAnnouncements);

  const handleLoadCategories = () => {
    if (categories.length === 0) {
      api
        .get(`/category-products-services/establishment/${getId()}`)

        .then((res) => {
          setCategories(res.data);
          setProducts([]);
        })
        .catch((err) => console.log('Erro categories', err));
    }
  };
  const handleLoadProduct = (category) => {
    api
      .get(`/announcements/category/${category}`)
      .then((res) => {
        setProducts(res.data.announcements);
      })
      .catch((error) => {
        console.log('error', error.response);
      });
  };

  const handleChangePromotionAnnouncements = (event) => {
    const { name, value, type } = event.target;

    if (type === 'number') {
      setInfosPromotionAnnouncements({
        ...infosPromotionAnnouncements,
        [name]: Number(value.replace(/[^0-9]/g, '')),
        // [name]: Number(value),
      });
    } else {
      setInfosPromotionAnnouncements({
        ...infosPromotionAnnouncements,
        [name]: value,
      });
    }
  };

  // Manipulate data
  const handleSetListProducts = (ev) => {
    ev.preventDefault();
    // Validar se ja existe item na lista

    const hasDuplicateItem = announcements.some((announcement) =>
      productsSelected.some((product) => product.id === announcement.id)
    );

    if (hasDuplicateItem) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Produto já adicionado!',
      });
      setListProducts([]);
      setProductsSelected([]);
      setProducts([]);
      setCategories([]);
      setInfosPromotionAnnouncements(initialInfosPromotionAnnouncements);
    } else {
      const updatedProductsSelected = productsSelected.map((product) => {
        return { ...product, ...infosPromotionAnnouncements };
      });
      setListProducts([...listProducts, ...updatedProductsSelected]);
      setProductsSelected([]);
      setProducts([]);
      setCategories([]);
      setInfosPromotionAnnouncements(initialInfosPromotionAnnouncements);
    }
  };

  const handleRemoveProduct = (id) => {
    const newList = listProducts.filter((product) => product.id !== id);
    setListProducts(newList);
  };

  const validationQuantity = () => {
    if (
      infosPromotionAnnouncements.totalPerUser === '' ||
      infosPromotionAnnouncements.totalPerUser === '0'
    ) {
      alert('Total por usuário não pode ser zero');
    }

    if (
      Number(infosPromotionAnnouncements.totalPerUser) >
      Number(infosPromotionAnnouncements.limit)
    ) {
      alert(
        'A quantidade por usuário não pode ser maior que a quantidade total'
      );
      setInfosPromotionAnnouncements({
        ...infosPromotionAnnouncements,
        totalPerUser: 0,
      });
    }
  };
  const handleUpdate = () => {
    let productsToSend = listProducts
      .map((product) => {
        return {
          name: product.name,
          id: product.id,
          active: product.active === 'true',
          limit: Number(product.limit),
          totalPerUser: Number(product.totalPerUser),
          onlyNewClients: product.onlyNewClients === 'true',
        };
      })
      .concat(announcements);

    // let newAnnouncements = listProducts.concat(announcements);

    setAnnouncements(productsToSend);
    setHasChanges(true);
    handleClose();
  };

  const findAnnouncementsInList = (name) => {
    for (let i = 0; i < listProducts.length; i++) {
      if (listProducts[i].name === name) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="container-modal-promotion">
      <div className="modal-promotion">
        <div className="modal-promotion-header">
          <h2>Adicionar anúncio</h2>
          <button onClick={() => handleClose()}>X</button>
        </div>

        <div
          style={{
            backgroundColor: '#e8f4ff',
            padding: '12px 16px',
            borderRadius: '8px',
          }}
        >
          <div className="modal-products-container">
            <label>Categoria</label>
            <select
              onChange={(event) => {
                handleLoadProduct(event.target.value);
              }}
              onClick={handleLoadCategories}
              name="category"
              type="text"
            >
              <option value="">Escolha uma categoria</option>
              {categories.length > 0 &&
                categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="modal-products-table">
            <MaterialTable
              elevation={0}
              columns={[
                {
                  title: 'Imagem',
                  field: 'image',
                  render: (rowData) =>
                    rowData.images.length > 0 ? (
                      <img
                        src={rowData.images[0].url}
                        alt="imagem do produto"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: '50%',
                          objectFit: 'contain',
                        }}
                      />
                    ) : (
                      <img
                        src={defaultImage}
                        style={{
                          width: 70,
                          height: 70,
                          borderRadius: '50%',
                          objectFit: 'contain',
                        }}
                        alt="Não existe imagem"
                      />
                    ),
                },
                { title: 'Nome', field: 'name' },
                {
                  title: 'Preço',
                  field: 'price',
                  render: (rowData) => formatedCurrencyBr(rowData.price),
                },
                { title: 'Quantidade', field: 'quantity' },
              ]}
              data={products}
              title="Lista de produtos"
              localization={{
                pagination: {
                  labelDisplayedRows: '{from}-{to} de {count}',
                  labelRowsSelect: 'Linhas',
                },
                toolbar: {
                  searchPlaceholder: 'Pesquisar',
                  nRowsSelected: '{0} linha(s) selecionada(s)',
                },
                header: {
                  actions: 'Ações',
                },
                body: {
                  emptyDataSourceMessage: 'Não há registros a serem exibidos',
                },
              }}
              options={{
                actionsColumnIndex: -1,
                paging: false,
                showSelectAllCheckbox: false,
                selection: true,
                selectionProps: (rowData) => ({
                  disabled: findAnnouncementsInList(rowData.name),
                  color: 'primary',
                }),
              }}
              onSelectionChange={(rows) => setProductsSelected(rows)}
            />
          </div>

          <form
            onSubmit={(ev) => handleSetListProducts(ev)}
            style={{ marginTop: '20px' }}
          >
            {productsSelected.length > 0 && (
              <>
                <h4>Informações sobre o produto</h4>
                <div className="modal-product-infos-container">
                  <div>
                    <label>Status</label>
                    <select
                      onChange={handleChangePromotionAnnouncements}
                      name="active"
                      type="text"
                      required
                    >
                      <option value="">Escolha uma opção</option>
                      <option value={true}>Ativa</option>
                      <option value={false}>Inativa</option>
                    </select>
                  </div>
                  <div>
                    <label>Somente novos usuarios?</label>
                    <select
                      onChange={handleChangePromotionAnnouncements}
                      name="onlyNewClients"
                      type="text"
                      required
                    >
                      <option value="">Escolha uma opção</option>
                      <option value={true}>Sim</option>
                      <option value={false}>Não</option>
                    </select>
                  </div>
                  <div>
                    <label>Quantidade de anúncio na promoção</label>
                    <input
                      onChange={handleChangePromotionAnnouncements}
                      name="limit"
                      placeholder="quantidade"
                      type="text"
                      value={infosPromotionAnnouncements.limit}
                      required
                    />
                  </div>
                  <div>
                    <label>Total por usuário</label>
                    <input
                      onChange={handleChangePromotionAnnouncements}
                      name="totalPerUser"
                      placeholder="quantidade"
                      type="text"
                      value={infosPromotionAnnouncements.totalPerUser}
                      onBlur={validationQuantity}
                      required
                    />
                  </div>
                </div>
              </>
            )}

            <div style={{ textAlign: 'right', marginTop: '20px' }}>
              <button
                type="submit"
                style={{ marginBottom: '1rem' }}
                className="btn-adc-item"
                disabled={productsSelected.length === 0}
              >
                <span>+</span>Adicionar produtos
              </button>
            </div>
          </form>

          <ul className="container-list-products">
            {listProducts.length > 0 &&
              listProducts.map((product) => (
                <CardPromotionAnnouncements
                  key={product.id}
                  handleRemoveProduct={handleRemoveProduct}
                  promotion={product}
                />
              ))}
          </ul>
        </div>

        <div style={{ textAlign: 'right', marginTop: '20px' }}>
          <button
            type="submit"
            onClick={handleUpdate}
            style={{ marginBottom: '1rem' }}
            className="btn-adc-item"
          >
            Confirmar mudanças
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentModalAddAnnouncements;
