import { React } from 'react'

import LogoBranca from '../../assets/logo-branca.png'
import { history } from '../../history'

import '../../pages/Cadastro/styles.css'
//import './styles.css'

const Form = () => {

  return (
    <>
      <div className="container">
        <div className="content">
          <img id="logo" onClick={() => history.goBack()} src={LogoBranca} alt="" />

          <form className="box-form">
            <div className="box-term">
              <div>
                <p className="c8 c23"><span className="c1 c3"></span></p>
              </div>
              <p className="c27"><span className="c37">TERMOS E CONDI&Ccedil;&Otilde;ES GERAIS </span></p>
              <p className="c6 c8 c42"><span className="c1 c34"></span></p>
              <p className="c22 c8" id="h.gjdgxs"><span className="c1 c34"></span></p>
              <h2 className="c42 c32 c62" id="term-color">
              Ao se cadastrar no Painel do Empreendedor da Logo Ali você ESTÁ DE ACORDO COM OS TERMOS E CONDIÇÕES da Plataforma. <br></br><br></br> É de extrema importância a leitura atenta dos termos e condições. Se você recusar os Termos da Plataforma Web, isso impedirá que você faça pedidos e solicitações na nossa Plataforma Web.
              </h2>
              <h3 className="c17" style={{marginTop: '20px'}} id="h.qe8ry933p3dn"><span className="c54">1. Servi&ccedil;os </span><span className="c1 c0">Oferecidos</span>
              </h3>
              <p className="c2f" id="h.u9wozj23lpsi"><span className="c0">1.1</span><span className="c3 c33">&nbsp;</span><span className="c3">Este
                TERMO se aplica para regular o uso do servi&ccedil;o oferecido pela Logo Ali Solu&ccedil;&otilde;es Digitais
                LTDA</span><span className="c3">&nbsp;</span><span className="c3">aos EMPREENDEDORES, qual seja, possibilitar a escolha,
                  pelos USU&Aacute;RIOS/CLIENTE FINAL, de EMPREENDEDORES cadastrados e, via on-line, efetivar
                  solicita&ccedil;&otilde;es para aquisi&ccedil;&atilde;o (entrega em domic&iacute;lio</span><span
                    className="c3 c7">&nbsp;</span><span className="c3">e retirada no local</span><span className="c1 c3">) de produtos varejistas
                      (vendas de bens), g&ecirc;neros aliment&iacute;cios, servi&ccedil;os fornecidos pelos EMPREENDEDORES, de acordo
                      com o perfil do empreendedor disponibilizado, sendo poss&iacute;vel, igualmente, aos USU&Aacute;RIOS/CLIENTE
                      FINAL, a efetiva&ccedil;&atilde;o do pagamento do pre&ccedil;o dos produtos e servi&ccedil;os via on-line.</span>
              </p>
              <p className="c2f" id="h.u9wozj23lpsi-1"><span className="c0">1.2</span><span className="c3 c33">&nbsp;</span><span className="c3">O
                servi&ccedil;o da Logo Ali Solu&ccedil;&otilde;es Digitais LTDA</span><span className="c3">&nbsp;</span><span
                  className="c3">consiste, portanto, em ap noroximar, atrav&eacute;s do nosso marketplace, os CONSUMIDORES </span><span
                    className="c3">FINAIS</span><span className="c3">&nbsp;e os EMPREENDEDORES cadastrados, possibilitando que os CONSUMIDORES
                      FINAIS encaminhem, aos EMPREENDEDORES, pedidos de entrega produtos varejistas (vendas de bens), de g&ecirc;neros
                      aliment&iacute;cios, </span><span className="c0">disponibiliza&ccedil;&atilde;o </span><span className="c1 c3">de
                        prestadores de servi&ccedil;os, bem como, sendo essa a op&ccedil;&atilde;o dos USU&Aacute;RIOS/CLIENTE FINAL,
                        receber on-line pagamento do pre&ccedil;o dos produtos entregues aos CONSUMIDORES FINAIS pelos
                        EMPREENDEDORES.</span></p>
              <p className="c2f" id="h.u9wozj23lpsi-2"><span className="c0">1.3</span><span className="c3">&nbsp;Desde logo fica esclarecido ao
              </span><span className="c3">EMPREENDEDORES</span><span className="c3">&nbsp;- o qual se declara ciente - que o
                servi&ccedil;o oferecido pela Logo Ali Solu&ccedil;&otilde;es Digitais LTDA se relaciona apenas &agrave;
                </span><span className="c0">intermedia&ccedil;&atilde;o (com op&ccedil;&atilde;o de pagamento on-line)</span><span
                  className="c1 c3">&nbsp;para comercializa&ccedil;&atilde;o de produtos varejistas, produtos aliment&iacute;cios,
                  servi&ccedil;os, n&atilde;o abarcando preparo, embalagem, deslocamento, disponibiliza&ccedil;&atilde;o e entrega
                  f&iacute;sica (via motoboy ou outros meios) dos produtos, sendo esses itens de responsabilidade integral do
                  EMPREENDEDOR, a quem dever&atilde;o ser direcionados quaisquer reclamos acerca de problemas decorrentes de
                  v&iacute;cio, deslocamento, servi&ccedil;os prestados de forma ineficiente, defeito ou inexecu&ccedil;&atilde;o da
                  feitura, preparo, entrega de produtos e alimentos.</span></p>
              <h3 className="c17" id="h.vknbv4buxt8s"><span className="c1 c15">2. Cadastro</span></h3>
              <p className="c2f" id="h.u9wozj23lpsi-3"><span className="c0">2.1</span><span className="c3">&nbsp;O EMPREENDEDOR, para utilizar os
                servi&ccedil;os acima descritos, </span><span className="c3">dever&aacute; ter capacidade jur&iacute;dica para atos
                  civis e dever&aacute;</span><span className="c3">, necessariamente, </span><span className="c0">prestar as
                    informa&ccedil;&otilde;es exigidas no</span><span className="c3">&nbsp;</span><span className="c0">CADASTRO</span><span
                      className="c1 c3">, assumindo integralmente a responsabilidade (inclusive civil e criminal) pela exatid&atilde;o e
                  veracidade das informa&ccedil;&otilde;es fornecidas no CADASTRO, que poder&aacute; ser verificado, a qualquer
                  momento, pela Logo Ali Solu&ccedil;&otilde;es Digitais LTDA.</span></p>
              <p className="c2f" id="h.u9wozj23lpsi-4"><span className="c0">2.1.1</span><span className="c3">&nbsp;Em caso de inf</span><span
                className="c1 c3">orma&ccedil;&otilde;es incorretas, inver&iacute;dicas ou duvidosas, bem assim na hip&oacute;tese da
                negativa em corrigi-las ou de enviar documenta&ccedil;&atilde;o que comprove a corre&ccedil;&atilde;o, a Logo Ali
                Solu&ccedil;&otilde;es Digitais LTDA se reserva o direito de n&atilde;o concluir o cadastramento em curso ou,
                ainda, de bloquear o cadastro j&aacute; existente, impedindo o USU&Aacute;RIO/EMPREENDEDOR de utilizar os
                servi&ccedil;os on-line at&eacute; que, a crit&eacute;rio da Logo Ali Solu&ccedil;&otilde;es Digitais LTDA, a
                situa&ccedil;&atilde;o de anomalia esteja regularizada. A Logo Ali Solu&ccedil;&otilde;es Digitais LTDA se reserva
                o direito de impedir, a seu crit&eacute;rio, novos CADASTROS, ou cancelar os j&aacute; efetuados, em caso de ser
                detectada anomalia que, em sua an&aacute;lise, seja revestida de gravidade ou demonstre tentativa deliberada de
                burlar as regras aqui descritas, obrigat&oacute;rias para todos os USU&Aacute;RIOS/EMPREENDEDOR. Tamb&eacute;m
                agir&aacute; a Logo Ali Solu&ccedil;&otilde;es Digitais LTDA de tal forma caso verifique descumprimento, pelo
                USU&Aacute;RIO/EMPREENDEDOR, de qualquer obriga&ccedil;&atilde;o prevista no presente TERMO.</span></p>
              <p className="c2f" id="h.u9wozj23lpsi-5"><span className="c0">2.2</span><span className="c3">&nbsp;Efetuado com sucesso, o
                CADASTRO, o USU&Aacute;RIO/EMPREENDEDOR ter&aacute; acesso aos servi&ccedil;os por meio de login e senha,
                preenchidos no momento da tela de cadastro, dados esses que se compromete a </span><span className="c0">n&atilde;o
                  divulgar a terceiros</span><span className="c1 c3">, ficando sob sua exclusiva responsabilidade, qualquer
                    solicita&ccedil;&atilde;o de servi&ccedil;o que seja feita com o uso de login e senha de sua titularidade.</span>
              </p>
              <h3 className="c17" id="h.xmc6qehxs49"><span className="c1 c15">3. Obriga&ccedil;&otilde;es do
                Usu&aacute;rio/Empreendedor</span></h3>
              <p className="c2f" id="h.u9wozj23lpsi-6"><span className="c0">3.1</span><span className="c3">&nbsp;Efetuado com sucesso o CADASTRO
                do USU&Aacute;RIO/EMPREENDEDOR, este se obriga a </span><span className="c0">n&atilde;o divulgar a terceiros login e
                  senha de acesso</span><span className="c1 c3">, nem permitir o uso de tais informa&ccedil;&otilde;es por terceiros,
                    responsabilizando-se pelas consequ&ecirc;ncias do uso de login e senha de sua titularidade.</span></p>
              <p className="c2f" id="h.u9wozj23lpsi-7"><span className="c0">3.2</span><span className="c3">&nbsp;&Eacute; obriga&ccedil;&atilde;o
                do EMPREENDEDOR </span><span className="c0">fornecer informa&ccedil;&otilde;es cadastrais totalmente ver&iacute;dicas,
                  exatas e atualizadas</span><span className="c3">, responsabilizando-se exclusiva e integralmente (em todas as
                    &aacute;reas jur&iacute;dicas) por todo o conte&uacute;do por si informado no item </span><span
                      className="c0">CADASTRO</span><span className="c1 c3">, mantendo atualizado e confirmado o endere&ccedil;o para retirada
                        dos produtos adquiridos; endere&ccedil;o para entrega dos pedidos solicitados.</span></p>
              <p className="c2f" id="h.u9wozj23lpsi-8"><span className="c0">3.3</span><span className="c3">&nbsp;O EMPREENDEDOR se obriga,
                tamb&eacute;m, a </span><span className="c0">fornecer integralmente o pre&ccedil;o dos produtos</span><span
                  className="c3">&nbsp;por si oferecidos ao CLIENTE FINAL, seja elas disponibilizadas, diretamente no PAINEL DO
                  EMPREENDEDOR </span><span className="c3 c7">(cart&atilde;o de cr&eacute;dito e PIX)</span><span className="c3">, exceto
                    apenas para as pessoas que forem utilizar o aplicativo para fornecer servi&ccedil;o, esse, o valor ser&aacute;
                    combinado entre o prestador e tomador do servi&ccedil;o. </span></p>
              <p className="c2f" id="h.u9wozj23lpsi-9"><span className="c0">3.4</span><span className="c3">&nbsp;O EMPREENDEDOR que seja menor de
                18 anos de idade est&aacute; </span><span className="c0">ciente de que n&atilde;o poder&aacute; utilizar os
                  servi&ccedil;os oferecidos no PAINEL DO EMPREENDEDOR</span><span className="c3">, em qualquer hip&oacute;tese,
                    responsabilizando-se pela correta informa&ccedil;&atilde;o de sua idade no item</span><span
                      className="c3 c30">&nbsp;</span><span className="c0">CADASTRO</span><span className="c21 c0 c30">.</span></p>
              <p className="c2f" id="h.798r21bzha5y"><span className="c0">3.5 </span><span className="c3">Garantir a exist&ecirc;ncia dos
                produtos ou servi&ccedil;os ofertados pelo aplicativo para os consumidores finais, n&atilde;o se passando ou
                tentando enganar/fraudar para levar vantagem ou receber de forma il&iacute;cita, o dinheiro dos consumidores por
                n&atilde;o ter recebido o produto ou realizado o servi&ccedil;o. &nbsp; </span></p>
              <h3 className="c17" id="h.sw8abga3ngi6"><span className="c54">4. Obriga&ccedil;&otilde;es da </span><span className="c54">Logo Ali
                Solu&ccedil;&otilde;es Digitais LTDA</span></h3>
              <p className="c2f" id="h.u9wozj23lpsi-10"><span className="c0">4.1</span><span className="c3">&nbsp;</span><span
                className="c0">Disponibilizar no site </span><span className="c0"><a className="c10"
                  href="https://www.google.com/url?q=http://www.logoaliapp.com.br&amp;sa=D&amp;source=editors&amp;ust=1684287560758972&amp;usg=AOvVaw20Y2PusTp07thVOgMtNcoS">logoaliapp.com.br</a></span><span
                    className="c0">&nbsp;</span><span className="c0">espa&ccedil;o virtual, denominado Painel do Empreendedor que permita ao
                      EMPREENDEDOR</span><span className="c3">&nbsp;devidamente cadastrado, efetivar cria&ccedil;&atilde;o,
                        atualiza&ccedil;&atilde;o/leitura, exclus&atilde;o e atualiza&ccedil;&atilde;o das informa&ccedil;&otilde;es do
                        administrador, do estabelecimento, hor&aacute;rio de funcionamento</span><span className="c3">, </span><span
                          className="c3">pedidos de compra e cat&aacute;logo de produtos varejistas (consumo de bens), g&ecirc;neros
                  aliment&iacute;cios anunciados, comercializados e demais fun&ccedil;&otilde;es que vierem a ser criadas e
                  servi&ccedil;os prestados pelos EMPREENDEDORES e, tamb&eacute;m, disponibilizar ao CONSUMIDOR FINAL m</span><span
                    className="c1 c3">eios de pagamento do pre&ccedil;o dos produtos.</span></p>
              <p className="c2f" id="h.u9wozj23lpsi-11"><span className="c0">4.2</span><span className="c3 c33">&nbsp;</span><span
                className="c0">Proteger</span><span className="c1 c3">, por meio de armazenamento em servidores ou quaisquer outros meios
                  magn&eacute;ticos de alta seguran&ccedil;a, a confidencialidade de todas as informa&ccedil;&otilde;es e cadastros
                  relativos aos EMPREENDEDORES, assim como valores atinentes &agrave;s opera&ccedil;&otilde;es financeiras advindas
                  da operacionaliza&ccedil;&atilde;o dos servi&ccedil;os previstos no presente TERMO. Contudo, n&atilde;o
                  responder&aacute; pela repara&ccedil;&atilde;o de preju&iacute;zos que possam ser derivados de apreens&atilde;o e
                  coopta&ccedil;&atilde;o de dados por parte de terceiros que, rompendo os sistemas de seguran&ccedil;a, consigam
                  acessar essas informa&ccedil;&otilde;es. A prote&ccedil;&atilde;o especificada, trata dos dados que est&atilde;o
                  em controle e posse da Logo Ali, n&atilde;o dos dados e informa&ccedil;&otilde;es armazenadas em terceiros ou
                  outros dispositivos que a empresa &nbsp;n&atilde;o tenha acesso. </span></p>
              <h3 className="c17" id="h.jlgdcnon0vcq"><span className="c1 c15">5. Modifica&ccedil;&otilde;es Deste Termo</span></h3>
              <p className="c2f" id="h.u9wozj23lpsi-12"><span className="c0">5.1</span><span className="c1 c3">&nbsp;O presente TERMO DE USO E
                CONDI&Ccedil;&Otilde;ES GERAIS poder&aacute;, a qualquer tempo, ter seu conte&uacute;do, ou parte dele,
                modificados para adequa&ccedil;&otilde;es e inser&ccedil;&otilde;es, tudo com vistas ao aprimoramento dos
                servi&ccedil;os disponibilizados.</span></p>
              <p className="c2f" id="h.u9wozj23lpsi-13"><span className="c0">5.2</span><span className="c3">&nbsp;As novas
                condi&ccedil;&otilde;es entrar&atilde;o em vig&ecirc;ncia assim que sua veiculada no site, sendo poss&iacute;vel
                ao EMPREENDEDOR manifestar oposi&ccedil;&atilde;o a quaisquer dos termos modificados, desde que o fa&ccedil;a por
                escrito, atrav&eacute;s do site </span><span className="c3"><a className="c10"
                  href="https://www.google.com/url?q=http://www.logoaliapp.com.br&amp;sa=D&amp;source=editors&amp;ust=1684287560759845&amp;usg=AOvVaw3KzVx0X0dDnQdYL5AZTt0x">logoaliapp.com.br</a></span><span
                    className="c3">&nbsp;utilizando a parte de contato da p&aacute;gina principal</span><span className="c1 c3">, o que
                      gerar&aacute; o cancelamento de seu CADASTRO assim que for analisado pela Logo Ali e ter comunicado o empreendedor
                      da sua manifesta&ccedil;&atilde;o.</span></p>
              <h3 className="c17" id="h.1wga7nyf799i"><span className="c1 c15">6. Canal de Comunica&ccedil;&atilde;o </span></h3>
              <p className="c2f" id="h.u9wozj23lpsi-14"><span className="c0">6.1</span><span className="c3">&nbsp;Para estabelecer contato
                entre</span><span className="c15">&nbsp;</span><span className="c3"><a className="c10"
                  href="https://www.google.com/url?q=http://www.logoaliapp.com.br&amp;sa=D&amp;source=editors&amp;ust=1684287560760216&amp;usg=AOvVaw1R3JCG9OYH-A7a-dh71Xgy">logoaliapp.com.br</a></span><span
                    className="c3">&nbsp;e o EMPREENDEDOR fica disponibilizado o endere&ccedil;o eletr&ocirc;nico </span><span
                      className="c0 c38"><a className="c10" href="mailto:contato@logoaliapp.com.br">contato@logoaliapp.com.br</a></span><span
                        className="c3">&nbsp;e whatsapp n&uacute;mero (38)9 9890-4086</span><span className="c3">&nbsp;sendo certo que o
                          EMPREENDEDOR se obriga, igualmente, a manter em seu cadastro endere&ccedil;o eletr&ocirc;nico atual por
                          interm&eacute;dio do qual se far&atilde;o as comunica&ccedil;&otilde;es a ele dirigidas pelo </span><span
                            className="c3"><a className="c10"
                              href="https://www.google.com/url?q=http://www.logoaliapp.com.br&amp;sa=D&amp;source=editors&amp;ust=1684287560760500&amp;usg=AOvVaw2qytCRL4WbGIEUoGCY4X0Y">logoaliapp.com.br</a></span><span
                                className="c1 c3">, desde logo emprestando-se validade jur&iacute;dica e efetividade a esses meios eletr&ocirc;nicos
                  de troca de informa&ccedil;&otilde;es rec&iacute;procas.</span></p>
              <h3 className="c17" id="h.j1mewuk6hs0g"><span className="c1 c15">7. ACEITA&Ccedil;&Atilde;O DO TERMO DE USO</span></h3>
              <p className="c2f" id="h.u9wozj23lpsi-15"><span className="c0">7.1</span><span className="c1 c3">&nbsp;O EMPREENDEDOR declara ter
                lido, entendido e que aceita todas as regras, condi&ccedil;&otilde;es e obriga&ccedil;&otilde;es estabelecidas no
                presente TERMO. Esta ser&aacute; aceita mediante op&ccedil;&atilde;o que ser&aacute; solicitada durante o
                cadastramento do usu&aacute;rio empreendedor. </span></p>
              <h3 className="c17" id="h.3j2s1vkbzd2l"><span className="c1 c15">8. FORO DE ELEI&Ccedil;&Atilde;O</span></h3>
              <p className="c2f" id="h.u9wozj23lpsi-16"><span className="c0">8.1</span><span className="c3">&nbsp;As partes elegem como
                competente para dirimir eventuais controv&eacute;rsias que venham a surgir da interpreta&ccedil;&atilde;o e do
                cumprimento do presente TERMO do foro da Comarca de Diamantina - MG.</span></p>
              <h1 className="c42 c43" id="h.usfzj7857k9y"><span className="c1 c37">DICAS DE SEGURAN&Ccedil;A</span></h1>
              <h3 className="c12" id="h.v96qlm4ux41"><span className="c1 c15">Para empreendedores</span></h3>
              <ul className="c13 lst-kix_jl4sottb9dqt-0 start">
                <li className="c31 c39 li-bullet-0"><span className="c0">Sempre d&ecirc; prefer&ecirc;ncia a dispositivos e redes de
                  confian&ccedil;a:</span><span className="c3">&nbsp;</span><span className="c1 c3">Cuidado com aparelhos e redes de
                    internet p&uacute;blicas ou compartilhadas, pois aumenta a exposi&ccedil;&atilde;o das suas
                    informa&ccedil;&otilde;es;</span></li>
                <li className="c5 c31 li-bullet-0"><span className="c0">Proteja sua conta:</span><span className="c3">&nbsp;</span><span
                  className="c1 c3">nunca compartilhe os c&oacute;digos que enviamos em seu celular ou e-mail. Isso pode permitir que
                  terceiros acessem sua conta Logo Ali;</span></li>
                <li className="c5 c31 li-bullet-0"><span className="c0">Mantenha seu aplicativo atualizado:</span><span
                  className="c1 c3">&nbsp;Quanto mais recente for a sua atualiza&ccedil;&atilde;o, mais seguro o seu aparelho
                  est&aacute;. A falta de atualiza&ccedil;&atilde;o abre brechas de seguran&ccedil;a que podem ser utilizadas por
                  pessoas mal intencionadas;</span></li>
                <li className="c5 c31 li-bullet-0"><span className="c0">Verifique a url:</span><span className="c3">&nbsp;em pedidos na Logo Ali
                  via desktop (fora do aplicativo) cuidado com p&aacute;ginas falsas com URLs semelhantes a da Logo Ali. Confira
                  se a URL se inicia com &ldquo;</span><span className="c3">logoaliapp.com.br&rdquo;</span><span className="c3">&nbsp;e se
                    &eacute; criptografada (identificada pela presen&ccedil;a do &lsquo;S&rsquo; no &lsquo;HTTP&rsquo;, o
                    https://).</span></li>
                <li className="c5 c31 li-bullet-0"><span className="c0">Cadastre seu endere&ccedil;o corretamente:</span><span
                  className="c1 c3">&nbsp;D&ecirc; informa&ccedil;&otilde;es precisas sobre o endere&ccedil;o para retirada dos itens,
                  com complemento (quando existir) e pontos de refer&ecirc;ncia;</span></li>
                <li className="c5 c31 li-bullet-0"><span className="c0">Aten&ccedil;&atilde;o ao entregar:</span><span
                  className="c1 c3">&nbsp;As entregas s&atilde;o exclusivamente feitas pelos Empreendedores. Dessa forma, atentar para
                  quando o pedido estiver sendo entregue;</span></li>
                <li className="c5 c31 li-bullet-0"><span className="c0">Aten&ccedil;&atilde;o ao prestar servi&ccedil;o:</span><span
                  className="c1 c3">&nbsp;As presta&ccedil;&otilde;es de servi&ccedil;os s&atilde;o exclusivamente feitas pelos
                  Empreendedores. Dessa forma, atentar para quando for executar o servi&ccedil;o;</span></li>
                <li className="c40 c31 li-bullet-0"><span className="c0">Nunc</span><span className="c0">a cobramos taxas extras:</span><span
                  className="c3">&nbsp;N&atilde;o existe a op&ccedil;&atilde;o de pagar por servi&ccedil;os que n&atilde;o s&atilde;o
                  oferec</span><span className="c1 c3">idos pela Logo Ali Solu&ccedil;&otilde;es Digitais Ltda. Tamb&eacute;m
                    n&atilde;o ligamos por telefone para falar sobre taxas adicionais;</span></li>
                <li className="c31 c40 li-bullet-0"><span className="c0">Lacre as embalagens dos itens vendidos:</span><span
                  className="c1 c3">&nbsp;Os produtos/refei&ccedil;&otilde;es devem estar devidamente lacradas e se as
                  condi&ccedil;&otilde;es do pedido dever&atilde;o estar em conformidade com o descrito no Perfil do Empreendedor,
                  atendendo assim, as normas do c&oacute;digo de defesa do consumido;</span></li>
                <li className="c40 c31 li-bullet-0"><span className="c0">Aten&ccedil;&atilde;o com e-mail e SMS falso</span><span
                  className="c1 c3">&nbsp;- Verifique se o remetente do email &eacute; @logoaliapp.com.br antes de responder algum
                  contato e n&atilde;o compartilhe seus dados via SMS ou Whatsapp;</span></li>
                <li className="c40 c31 li-bullet-0"><span className="c0">Nos comunique imediatamente:</span><span className="c1 c3">&nbsp;se
                  tiver algum problema procure nos ajudar a entender como resolver. Da mesma forma, se n&atilde;o ocorrer as
                  solicita&ccedil;&otilde;es feitas.</span></li>
                <li className="c22 c31 li-bullet-0"><span className="c0">Verifique sempre os pedidos feitos pelos Consumidores
                  Finais.</span><span className="c1 c3">&nbsp;Caso identifique alguma irregularidade, por favor, nos informe pelo
                    Suporte disponibilizado no Painel do Empreendedor ou pelos canais de comunica&ccedil;&atilde;o. Investimos em
                    tecnologia para garantir a seguran&ccedil;a de cadastros e contra fraudes.</span></li>
              </ul>
              <p className="c22"><span className="c60">&nbsp;</span></p>
              <p className="c27"><span className="c1 c37">Termo para os Empreendedores</span></p>
              <p className="c42 c8 c52"><span className="c21 c0 c63"></span></p>
              <p className="c22 c8"><span className="c1 c0 c25"></span></p>
              <p className="c22 c53"><span className="c0">TERMOS E CONDI&Ccedil;&Otilde;ES GERAIS DE CONTRATA&Ccedil;&Atilde;O DA LOGO ALI
                SOLU&Ccedil;&Otilde;ES DIGITAIS LTDA</span></p>
              <p className="c52 c42 c8"><span className="c1 c34"></span></p>
              <p className="c22" id="h.gjdgxs-17"><span className="c3">Por meio do presente instrumento s&atilde;o estabelecidos os termos e
                as condi&ccedil;&otilde;es gerais para a contrata&ccedil;&atilde;o da Logo Ali Solu&ccedil;&otilde;es Digitais
                Ltda (&ldquo;Logo Ali&rdquo;) pelo Empreendedor, sendo a ades&atilde;o integral pelo Empreendedor a este
                instrumento condi&ccedil;&atilde;o essencial para a presta&ccedil;&atilde;o dos servi&ccedil;os aqui
                descritos.</span></p>
              <ol className="c13 lst-kix_tr0ui6md5ff3-0 start" start="1">
                <li className="c31 c42 c45 li-bullet-0"><span className="c1 c0">Defini&ccedil;&otilde;es</span></li>
              </ol>
              <p className="c5"><span className="c1 c3">1.1. Os termos e as express&otilde;es abaixo, quando iniciados em letra
                mai&uacute;scula nestes Termos e Condi&ccedil;&otilde;es ou no Contrato, no singular ou no plural, no masculino ou
                no feminino, ter&atilde;o os significados que lhes s&atilde;o indicados a seguir:&nbsp;</span></p>
              <p className="c5"><span className="c0">Perfil do Empreendedor:</span><span className="c1 c3">&nbsp;Imagem de capa, logo do
                empreendedor, informa&ccedil;&otilde;es b&aacute;sicas(nome, endere&ccedil;o completo, telefone e hor&aacute;rio
                de funcionamento), rela&ccedil;&atilde;o de itens por categorias, tipo de an&uacute;ncio, nome, com pre&ccedil;o
                individual, limite de quatro imagens por produtos, quantidade dispon&iacute;vel no momento e
                informa&ccedil;&otilde;es individuais para o consumidor final.</span></p>
              <p className="c5"><span className="c0">Consumidor Final:</span><span className="c3">&nbsp;Pessoa consumidora que solicita algum
                produto ou servi&ccedil;o em forma de Pedidos por meio do Aplicativo.&nbsp;&nbsp;</span></p>
              <p className="c22"><span className="c0 c14">Estorno:</span><span className="c3 c14">&nbsp;Um &nbsp;Pedido &nbsp;cujo pagamento
                &nbsp;tenha &nbsp;sido &nbsp;realizado &nbsp;por &nbsp;meio &nbsp;do &nbsp;Sistema &nbsp;de &nbsp;Pagamentos
                Online e que tenha sido estornado com sucesso pelo titular do cart&atilde;o utilizado em referido
                pagamento</span><span className="c3">, &nbsp; incluindo, &nbsp; sem &nbsp; limita&ccedil;&atilde;o, &nbsp; em &nbsp;
                  decorr&ecirc;ncia &nbsp; de &nbsp; pagamento &nbsp; fraudulento, resultando &nbsp; no &nbsp; cancelamento &nbsp;
                  da &nbsp; transfer&ecirc;ncia, &nbsp; pelos &nbsp; fornecedores &nbsp; do &nbsp; Sistema &nbsp; de Pagamentos
                  Online, do valor do Pedido para a Logo Ali. </span><span className="c3">Nos casos em que a transfer&ecirc;ncia do
                    valor &nbsp;do &nbsp;Pedido &nbsp;j&aacute; &nbsp;tenha &nbsp;sido &nbsp;realizada &nbsp;pelos &nbsp;fornecedores
                    &nbsp;do &nbsp;Sistema &nbsp;de &nbsp;Pagamentos Online para a Logo Ali, a ocorr&ecirc;ncia do Estorno implica
                    obriga&ccedil;&atilde;o da Logo Ali de devolver o valor de referida transfer&ecirc;ncia para tais
                    fornecedores</span><span className="c1 c3">. &nbsp;</span></p>
              <p className="c45 c42 c32"><span className="c3">Conforme a legisla&ccedil;&atilde;o, o consumidor tem direito ao
                arrependimento de compra. Conforme o </span><span className="c0">C&oacute;digo de Defesa do Consumidor, o</span><span
                  className="c3">&nbsp;</span><span className="c0 c32">Artigo 49 da Lei n&ordm; 8.078 de 11 de Setembro de 1990</span><span
                    className="c3">&nbsp;p</span><span className="c3">rev&ecirc; que o comprador tem </span><span className="c0">at&eacute; 7
                      dias</span><span className="c1 c3">, a contar do ato de compra ou do recebimento do produto, para desistir da
                        transa&ccedil;&atilde;o, em produtos que possam ser devolvidos, por exemplo, uma roupa com a etiqueta. &nbsp;Caso
                        o comprador deseje desistir de uma compra no prazo de sete dias, o estabelecimento deve conceder a
                        devolu&ccedil;&atilde;o do feita por PIX ou do valor pago pelo cart&atilde;o via estorno. </span></p>
              <p className="c5 c32"><span className="c0">Devolu&ccedil;</span><span className="c0">&atilde;o Delivery: </span><span
                className="c3">Se o Consumidor Final recebeu o produto pelo delivery, a devolu&ccedil;&atilde;o &eacute; de total
                responsabilidade do Empreendedor de buscar o pedido em at&eacute; 2 dias &uacute;teis, isso a partir do momento em
                que ele aceitar a devolu&ccedil;&atilde;o pelo Painel do Empreendedor, onde o mesmo dever&aacute; informar dia e
                hora da coleta do produto. Se o estabelecimento n&atilde;o buscar o produto no pra</span><span className="c3">zo
                  citado acima (em at&eacute; 2 dias &uacute;teis), ser&aacute; cancelada a compra, o valor estornado para o
                  Consumidor Final e </span><span className="c0">s</span><span className="c0">er&aacute; cobrado do Empreendedor
                </span><span className="c0">R$ 0,50. </span><span className="c3">O empreendedor ficar&aacute; respons&aacute;vel de
                  organizar e resolver a devolu&ccedil;&atilde;o com o consumidor final, ficando necess&aacute;rio apenas informar
                  via painel do empreendedor, se foi conclu&iacute;da a devolu&ccedil;&atilde;o ou n&atilde;o. A mesma
                  informa&ccedil;&atilde;o faz necess&aacute;ria para o consumidor final. </span><span className="c1 c0">&nbsp;</span>
              </p>
              <p className="c5 c32"><span className="c0">Devolu&ccedil;&atilde;o Retirada em loja: </span><span className="c3">Se o Consumidor
                Final retirou o produto em loja, &eacute; de total responsabilidade dele ir at&eacute; o local da retirada e
                devolver o produto em at&eacute; 7 dias corridos, a partir do aceite do pedido de devolu&ccedil;&atilde;o pelo
                Empreendedor. Ser&aacute; enviada uma mensagem do suporte da Logo Ali para o WhatsApp do Consumidor Final,
                informando os hor&aacute;rios de devolu&ccedil;&atilde;o do pedido. O consumidor ficar&aacute; respons&aacute;vel
                de solicitar a devolu&ccedil;&atilde;o e combinar com o empreendedor como e quando far&aacute; a
                devolu&ccedil;&atilde;o do produto, nos prazos estipulados pelo c&oacute;digo de defesa do consumidor. </span></p>
              <p className="c5"><span className="c0">Painel do Empreendedor:</span><span className="c1 c3">&nbsp;&Eacute; a plataforma web
                desenvolvida pela Logo Ali, por meio do qual os Pedidos dos Consumidores Finais ser&atilde;o recebidos pelo
                Empreendedor, podendo divulgar e gerenciar as informa&ccedil;&otilde;es relativas ao seu perfil/empreendimento,
                dados cadastrais, produtos, servi&ccedil;os(se for o caso) e operacionais.</span></p>
              <p className="c5"><span className="c0">Plataforma da Logo Ali:</span><span className="c3">&nbsp;Significa todos e quaisquer
                websites e aplicativos para celulares de titularidade da Logo Ali, por meio dos quais, dentre outras
                funcionalidades, (a) o Empreendedor, de um lado, pode ofertar os produtos, alimentos ou oferecer servi&ccedil;os,
                conforme o segmento escolhido por ele; e (b) os Consumidores Finais, de outro lado, podem fazer pedidos de entrega
                de tais produtos, alimentos aos Empreendedores e solicitar servi&ccedil;os.</span><span
                  className="c3 c21 c7">&nbsp;</span></p>
              <p className="c5"><span className="c0">Receita Bruta:</span><span className="c1 c3">&nbsp;&Eacute; o valor total cobrado pelo
                Empreendedor dos Clientes Finais por cada Pedido, incluindo valores relativos &agrave; taxa de entrega dos
                Pedidos, conforme ele houver dispon&iacute;vel.</span></p>
              <p className="c5"><span className="c0">Sinais Distintivos:</span><span className="c1 c3">&nbsp;significa todas e quaisquer marcas,
                logotipos, fotografias e demais sinais distintivos de titularidade do Empreendedor ou sobre as quais ele tenha
                autoriza&ccedil;&atilde;o de uso, que venham a ser por ele disponibilizadas para a elabora&ccedil;&atilde;o da
                Loja Virtual/Perfil.</span></p>
              <p className="c5"><span className="c0 c48">&nbsp; &nbsp; &nbsp; &nbsp;</span><span className="c0">2. Objeto </span></p>
              <p className="c5"><span className="c1 c3">2.1. O Contrato tem por objeto</span></p>
              <p className="c5"><span className="c1 c3">(a) a promo&ccedil;&atilde;o, pela Logo Ali, das atividades do Empreendedor no
                territ&oacute;rio brasileiro por meio da Plataforma Logo Ali, nos termos do art. 710 da Lei n. 10.406/2002; e (b)
                o licenciamento n&atilde;o exclusivo do uso dos Softwares pelo Empreendedor ao seu estabelecimento.</span></p>
              <p className="c5 c11"><span className="c1 c0">3. Informa&ccedil;&otilde;es do Empreendedor</span></p>
              <p className="c22"><span className="c1 c3">3.1. O Empreendedor &nbsp;&eacute; o &uacute;nico respons&aacute;vel por todas e
                quaisquer informa&ccedil;&otilde;es a respeito de suas atividades que venham a ser por ele disponibilizadas na
                Logo Ali e aos Consumidores Finais (as &ldquo;Informa&ccedil;&otilde;es do Empreendedor&rdquo;), e compromete-se a
                mant&ecirc;-las, por meio dos Softwares e/ou de quaisquer outros canais de comunica&ccedil;&atilde;o
                disponibilizados pela Logo Ali, a todo tempo atualizadas e em estrita observ&acirc;ncia &agrave;
                legisla&ccedil;&atilde;o aplic&aacute;vel.</span></p>
              <p className="c4"><span className="c3">3.2. O acesso do Empreendedor ao Painel do Empreendedor ser&aacute; realizado por meio
                de </span><span className="c0">nome de usu&aacute;rio</span><span className="c3">&nbsp;e </span><span className="c0">senha de
                  uso pessoal e intransfer&iacute;vel</span><span className="c1 c3">. O Empreendedor reconhece que ser&aacute; o
                    &uacute;nico respons&aacute;vel por todo e qualquer acesso ao Painel do Empreendedor por meio do seu nome de
                    usu&aacute;rio e da sua senha.</span></p>
              <p className="c22 c8"><span className="c1 c3"></span></p>
              <p className="c22"><span className="c1 c3">3.3. O acesso ao Painel do Empreendedor &eacute; feito de imediato a partir das
                valida&ccedil;&otilde;es das informa&ccedil;&otilde;es cadastradas pelo Empreendedor, a Logo Ali
                disponibilizar&aacute; a Loja Virtual na Plataforma(aplicativo) Logo Ali.</span></p>
              <p className="c4"><span className="c1 c3">3.4. O Empreendedor concorda que todos e quaisquer descontos e
                promo&ccedil;&otilde;es oferecidas em outros canais de delivery por ele utilizados, sejam pr&oacute;prios ou de
                terceiros, dever&atilde;o ser oferecidas tamb&eacute;m aos Consumidores Finais.</span></p>
              <p className="c4"><span className="c3">3.5. O Empreendedor n&atilde;o poder&aacute; disponibilizar aos Consumidores Finais os
                seus </span><span className="c0">n&uacute;meros de telefone</span><span className="c3">&nbsp;e </span><span className="c0">os
                  endere&ccedil;os virtuais de quaisquer outros canais de delivery</span><span className="c1 c3">, sejam eles de
                    titularidade do Empreendedor ou de terceiros, sendo vedado ao Empreendedor, ainda, a utiliza&ccedil;&atilde;o dos
                    dados dos Consumidores Finais para a divulga&ccedil;&atilde;o de tais canais, incluindo, sem
                    limita&ccedil;&atilde;o, o envio de mala direta aos Consumidores Finais.</span></p>
              <p className="c4 c26"><span className="c1 c0">4. Pedidos</span></p>
              <p className="c4"><span className="c3">4.1. A Logo Ali ser&aacute; exclusivamente respons&aacute;vel pela </span><span
                className="c0">intermedia&ccedil;&atilde;o</span><span className="c3">&nbsp;entre o Empreendedor e os Consumidores Finais,
                  por meio da Plataforma Logo Ali, e em nenhuma hip&oacute;tese</span><span className="c65">&nbsp;</span><span
                    className="c1 c3">responder&aacute; por danos emergentes, lucros cessantes ou danos indiretos perante o
                  Empreendedor.</span></p>
              <p className="c4"><span className="c1 c3">4.2. O Empreendedor ser&aacute; o &uacute;nico respons&aacute;vel pela
                execu&ccedil;&atilde;o e pela corre&ccedil;&atilde;o dos Pedidos feitos de forma inadequada ou incompleta, bem
                como pela completa observ&acirc;ncia de todas e quaisquer normas aplic&aacute;veis a suas atividades, incluindo,
                sem limita&ccedil;&atilde;o, as normas sanit&aacute;rias, e pela emiss&atilde;o e entrega de nota fiscal, recibo
                ou documento equivalente para os Consumidores Finais com rela&ccedil;&atilde;o aos Pedidos.</span></p>
              <p className="c4"><span className="c1 c3">4.3. Os Pedidos ser&atilde;o recebidos pelo Empreendedor por meio do site (Painel do
                Empreendedor), cabendo ao Empreendedor aceit&aacute;-los, rejeit&aacute;-los ou cancel&aacute;-los tamb&eacute;m
                por meio do site (Painel do Empreendedor), sendo vedado ao Empreendedor rejeitar e/ou cancelar &nbsp;mais de 7
                pedidos no primeiro m&ecirc;s e nos meses subsequentes mais do que 5% dos Pedidos por ele recebidos dentro de cada
                m&ecirc;s. </span></p>
              <p className="c4"><span className="c1 c3">4.4. O Empreendedor dever&aacute; atualizar o status dos Pedidos por meio do Painel
                do Empreendedor.</span></p>
              <p className="c4"><span className="c3">4.5. Caso o</span><span className="c0">&nbsp;</span><span className="c1 c3">Pedido fuja dos
                padr&otilde;es de uso/consumo, incluindo, sem limita&ccedil;&atilde;o, em decorr&ecirc;ncia do seu valor e/ou da
                quantidade de itens adquiridos, o Empreendedor dever&aacute; imediatamente entrar em contato com a Logo Ali, para
                que as Partes possam, de comum acordo, decidir quais medidas ser&atilde;o tomadas com rela&ccedil;&atilde;o ao
                Pedido em quest&atilde;o. Caso o Empreendedor n&atilde;o consiga entrar em contato com a Logo Ali, o Pedido
                dever&aacute; ser rejeitado pelo Empreendedor. Os Pedidos rejeitados nos termos desta Cl&aacute;usula n&atilde;o
                ser&atilde;o contabilizados para fins da Cl&aacute;usula 4.3.</span></p>
              <p className="c4"><span className="c1 c3">4.6. O Empreendedor dever&aacute; elaborar e embalar os produtos/alimentos, bebidas
                e/ou demais produtos pedidos pelos Consumidores Finais exatamente como consta em seu cat&aacute;logo de pedidos
                (perfil). &Eacute; vedado ao Empreendedor utilizar embalagens de concorrentes da Logo Ali para embalar as
                produtos/refei&ccedil;&otilde;es, bebidas e/ou demais produtos adquiridos pelos Clientes Finais, exceto se tiver
                autoriza&ccedil;&atilde;o para uso de tal marca concorrente da Logo Ali. Autoriza&ccedil;&atilde;o que
                dever&aacute; ser solicitada a Logo Ali pelo site na op&ccedil;&atilde;o contato. </span></p>
              <p className="c4"><span className="c1 c3">4.7. O Empreendedor se compromete a n&atilde;o preparar e/ou entregar aos
                Consumidores Finais, conforme o caso, os Pedidos que houvessem sido por eles cancelados, independentemente do
                motivo do cancelamento.</span></p>
              <p className="c4"><span className="c1 c3">4.8. Caso o Pedido contenha bebidas alco&oacute;licas ou quaisquer outros produtos
                cujo consumo possua restri&ccedil;&otilde;es, nos termos da legisla&ccedil;&atilde;o aplic&aacute;vel, o
                Empreendedor dever&aacute; tomar as medidas necess&aacute;rias para confirmar que o Consumidor Final possui idade
                legal para o consumo de tais produtos.</span></p>
              <p className="c4"><span className="c3">4.9. O Empreendedor </span><span className="c0">assume</span><span className="c1 c3">, em
                car&aacute;ter irrevog&aacute;vel, irretrat&aacute;vel e irrevers&iacute;vel, a obriga&ccedil;&atilde;o de manter
                a Logo Ali a todo tempo livre e indenizado de todas e quaisquer perdas, danos e demandas que a Logo Ali
                eventualmente venha a sofrer de Consumidores Finais ou quaisquer outros terceiros em decorr&ecirc;ncia da
                execu&ccedil;&atilde;o e entrega dos Pedidos pelo Empreendedor ou da viola&ccedil;&atilde;o pelo Empreendedor
                destas Condi&ccedil;&otilde;es Gerais ou de qualquer legisla&ccedil;&atilde;o a ele aplic&aacute;vel.</span></p>
              <p className="c4 c26"><span className="c1 c0">5. Entrega dos Pedidos</span></p>
              <p className="c4"><span className="c1 c3">5.1. O Empreendedor &eacute; respons&aacute;vel pela entrega do pedido ou
                servi&ccedil;o solicitado pelo consumidor final. </span></p>
              <p className="c4"><span className="c1 c3">5.2. O Empreendedor ser&aacute; o &uacute;nico respons&aacute;vel pela entrega dos
                Pedidos, independentemente de os servi&ccedil;os de entrega serem prestados diretamente pelo Empreendedor ou por
                terceiro por ele contratado. O Empreendedor dever&aacute; (a) definir a &aacute;rea de entrega dos Pedidos por
                meio do Painel do Empreendedor; (b) definir o valor da taxa de entrega a ser cobrada dos Consumidores Finais; e
                (c) informar a Logo Ali a ocorr&ecirc;ncia de quaisquer eventualidades relativas aos Pedidos que prejudiquem e/ou
                impossibilitem a realiza&ccedil;&atilde;o das entregas aos Consumidores Finais</span></p>
              <p className="c4 c26"><span className="c1 c0">6. Pagamento dos Pedidos</span></p>
              <p className="c4"><span className="c1 c3">6.1. O Empreendedor concorda que a Logo Ali disponibilizar&aacute; aos Consumidores
                Finais um sistema de pagamentos online integrado &agrave; Plataforma Logo Ali (Sistema de Pagamento Logo Ali), o
                qual poder&aacute; ser fornecido, total ou parcialmente, diretamente pela Logo Ali ou por terceiros contratados
                para essa finalidade. Dessa forma, o Empreendedor outorga a Logo Ali, nos termos do art. 684 da Lei n.
                10.406/2002, poderes para que a Logo Ali possa contratar, em nome do Empreendedor, todos e quaisquer
                servi&ccedil;os necess&aacute;rios ou &uacute;teis &agrave; disponibiliza&ccedil;&atilde;o e
                manuten&ccedil;&atilde;o do Sistema de Pagamentos da Logo Ali, incluindo, mas sem se limitar a isto,
                servi&ccedil;os de credenciamento de empreendimentos comerciais em arranjos de pagamento, de coleta, processamento
                e liquida&ccedil;&atilde;o de transa&ccedil;&otilde;es por cart&otilde;es de cr&eacute;dito e PIX, de abertura de
                contas de pagamento em institui&ccedil;&otilde;es de pagamento parceiras da Logo Ali, dentre outros.</span></p>
              <p className="c4"><span className="c3">6.1.1. Os Pedidos pagos por meio do Sistema de Pagamentos Logo Ali ser&atilde;o
                repassados</span><span className="c0">&nbsp;</span><span className="c1 c3">ao Empreendedor no prazo e na forma previstos
                  na Cl&aacute;usula 8.</span></p>
              <p className="c4"><span className="c3">6.1.2. Em caso de pagamento realizado por meio do Sistema de Pagamentos Logo Ali,
                eventuais Chargeback ser&atilde;o assumidos pelo Empreendedor e a Logo Ali, devendo os valores dos respectivos
                Pedidos serem inclu&iacute;dos nos Repasses.</span><span className="c3 c7">&nbsp;</span><span className="c1 c3">Para os
                  fins do Contrato, &ldquo;Chargeback&rdquo; significa um Pedido cujo pagamento tenha sido realizado por meio do
                  Sistema de Pagamentos Logo Ali e que tenha sido estornado com sucesso pelo titular do cart&atilde;o utilizado no
                  pagamento, incluindo em decorr&ecirc;ncia de pagamento fraudulento, resultando no cancelamento da
                  transfer&ecirc;ncia, pelos fornecedores do Sistema de Pagamentos Logo Ali, do valor do Pedido para a Logo
                  Ali.</span></p>
              <p className="c45 c42"><span className="c3">6.1.3. O Empreendedor ter&aacute; a op&ccedil;&atilde;o de receber os Pagamentos
                por cart&atilde;o de cr&eacute;dito e transfer&ecirc;ncia por PIX.</span></p>
              <p className="c22"><span className="c3">6.2. Em hip&oacute;tese alguma o Empreendedor poder&aacute; disponibilizar aos
                Consumidores Finais a possibilidade de pagamento de forma presencial, no ato da entrega do Pedido, diretamente aos
                entregadores do Empreendedor (o&ldquo;Pagamento Offline&rdquo;). </span></p>
              <p className="c4"><span className="c1 c3">6.2.1. Ao cadastrar para usar os Meios de Pagamento da Logo Ali, o Empreendedor tem
                que aguardar pela valida&ccedil;&atilde;o dos documentos e informa&ccedil;&otilde;es do seu cadastro, caso seja
                necess&aacute;rio, ser&aacute; solicitado pela Logo Ali para confer&ecirc;ncia e valida&ccedil;&atilde;o. Ao
                cadastrar a plataforma e j&aacute; conseguir finalizar o cadastro e identificar que est&aacute; tudo
                v&aacute;lido, j&aacute; ser&aacute; liberado para a utiliza&ccedil;&atilde;o na nossa plataforma e no aplicativo.
              </span></p>
              <p className="c4"><span className="c3">6.2.2. O Empreendedor que optar por excluir a conta na Plataforma da Logo Ali, fica
                ciente que a conta criada no Meio de Pagamento n&atilde;o poder&aacute; ser exclu&iacute;da via API de pagamento.
                Sendo que, por quest&otilde;es regulat&oacute;rias do Banco Central, as contas n&atilde;o podem ser
                exclu&iacute;das. Dessa forma, se quiser se desfazer da conta, voc&ecirc; pode torn&aacute;-la &nbsp;inativa.
              </span></p>
              <p className="c4 c26"><span className="c1 c0">7. Remunera&ccedil;&atilde;o</span></p>
              <p className="c4"><span className="c1 c3">7.1. Pela presta&ccedil;&atilde;o do Servi&ccedil;o, o Empreendedor pagar&aacute; a
                Logo Ali Remunera&ccedil;&atilde;o composta pelos seguintes valores: (a) &ldquo;Comiss&atilde;o&rdquo;,
                correspondente a um percentual 12% em decorr&ecirc;ncia dos Pedidos realizados pelos Consumidores Finais; (b) Taxa
                de servi&ccedil;o por pagamento online &agrave; vista de R$ 0,50. </span></p>
              <p className="c4"><span className="c1 c3">7.2 Para os prestadores de servi&ccedil;o no aplicativo/plataforma, ser&aacute;
                cobrada pela cobran&ccedil;a mensal de R $39,90, conforme cadastro das informa&ccedil;&otilde;es e do meio de
                pagamento, que ser&aacute; aceito apenas por cart&atilde;o de cr&eacute;dito. </span></p>
              <p className="c4 c26"><span className="c1 c0">8. Forma de Pagamento e Repasse</span></p>
              <p className="c4"><span className="c1 c3">8.1. Os Pedidos ser&atilde;o exclusivamente efetuados por Pagamento Online dos
                respectivos Pedidos ao Empreendedor pela forma de pagamento por cart&atilde;o de cr&eacute;dito e
                transfer&ecirc;ncia por PIX. Com rela&ccedil;&atilde;o ao Sistema de Pagamentos Logo Ali, o Empreendedor
                expressamente autoriza a Logo Ali ou quaisquer terceiros por ele contratado a receber dos Consumidores Finais, em
                nome do Empreendedor, o valor total dos Pedidos e a repassar este valor para o Empreendedor, no prazo e nas
                condi&ccedil;&otilde;es previstas nesta Cl&aacute;usula (o &ldquo;Repasse&rdquo;).</span></p>
              <p className="c4"><span className="c3">8.2. O Repasse ser&aacute; feito pelo Meio de Pagamento Logo Ali para a conta criada
                pelo Empreendedor que receber&aacute; os valores de um ou mais pagamentos entre Consumidor Final. O valor &eacute;
                transferido automaticamente para a conta do Empreendedor no momento do pagamento compensado. No prazo
                de</span><span className="c3">&nbsp;</span><span className="c0">30 (trinta</span><span className="c0">)</span><span
                  className="c3">&nbsp;dias contados da data de pagamento do Pedido por meio do Sistema de Pagamentos Logo Ali,
                  observadas as regras previstas nesta Cl&aacute;usula, ou em qualquer outro prazo acordado pelas Partes na
                  Contrata&ccedil;&atilde;o.</span></p>
              <p className="c4"><span className="c1 c3">8.2.1. Na hip&oacute;tese de o Empreendedor desejar alterar a sua conta
                banc&aacute;ria informada no Contrato, dever&aacute; informar, por escrito ou via e-mail, sendo que por
                quest&atilde;o de seguran&ccedil;a, ser&aacute; confirmado via telefone, os dados da nova conta banc&aacute;ria
                para a Logo Ali com anteced&ecirc;ncia m&iacute;nima de 15 (quinze) dias, sob pena de o Repasse em quest&atilde;o
                ser feito pela Logo Ali para a conta banc&aacute;ria anteriormente indicada pelo Empreendedor.</span></p>
              <p className="c4"><span className="c1 c3">8.2.2. Na hip&oacute;tese de o &uacute;ltimo dia do prazo de libera&ccedil;&atilde;o
                dos valores transacionados, cair data em que os bancos comerciais estejam fechados, o prazo limite para o
                pagamento do Repasse Devido em quest&atilde;o ser&aacute; prorrogado para o primeiro dia &uacute;til
                subsequente.</span></p>
              <p className="c4"><span className="c3">8.2.3. As transfer&ecirc;ncias banc&aacute;rias do Sistema de Pagamento Logo Ali
                s&atilde;o no valor de R$ 3,67 por solicitac&#807;a&#771;o para conta banc&aacute;ria, isento para Banco Bradesco
                ou Next. Todo dia 15 ser&aacute; realizado um saque autom&aacute;tico de todo o saldo dispon&iacute;vel,
              </span><span className="c3">efetivado</span><span className="c3">&nbsp;toda segunda-feira ou di&aacute;rio</span><span
                className="c3">, podendo ter varia&ccedil;&atilde;o de at&eacute; 7 dias para cair na conta banc&aacute;ria devido
                  &agrave;s integra&ccedil;&otilde;es com os bancos. </span></p>
              <p className="c4"><span className="c3">8.2.4. Cada Empreendedor pagar&aacute; pela transfer&ecirc;ncia para a sua conta
                banc&aacute;ria conforme o item 8.2. &nbsp;</span></p>
              <p className="c45 c42 c26"><span className="c1 c0">9. Exclusividade</span></p>
              <p className="c22"><span className="c3">9.1. O Empreendedor declara, por meio da </span><span
                className="c0">confirma&ccedil;&atilde;o da Contrata&ccedil;&atilde;o</span><span className="c1 c3">, que est&aacute;
                  ciente e concorda que a Logo Ali Solu&ccedil;&otilde;es Digitais Ltda poder&aacute; prestar os servi&ccedil;os
                  objeto deste Contrato a quaisquer outros estabelecimentos/empreendedores, ainda que estes sejam direta ou
                  indiretamente concorrentes do Empreendedor.</span></p>
              <p className="c4 c26"><span className="c0">10. Propriedade Intelectual</span></p>
              <p className="c4"><span className="c1 c3">10.1. Todos e quaisquer direitos de propriedade intelectual ou industrial relativos
                &agrave; Plataforma Logo Ali e/ou aos Softwares pertencem &uacute;nica e exclusivamente a Logo Ali. Em nenhuma
                hip&oacute;tese, o Contrato implica transfer&ecirc;ncia, no todo ou em parte, de qualquer direito de propriedade
                intelectual ou industrial pela Logo Ali para o Empreendedor. O Empreendedor se compromete a (a) utilizar a
                Plataforma Logo Ali e os Softwares de acordo com as suas finalidades e exig&ecirc;ncias t&eacute;cnicas; (b)
                disponibilizar meio adequado para a utiliza&ccedil;&atilde;o da Plataforma Logo Ali, conforme
                instru&ccedil;&otilde;es da Logo Ali, incluindo, sem limita&ccedil;&atilde;o, com rela&ccedil;&atilde;o a
                hardware, rede, pessoas capacitadas etc.; (c) responsabilizar-se legalmente por quaisquer dados e
                informa&ccedil;&otilde;es que venham a ser armazenados pelo Empreendedor no Painel do Empreendedor; (d) n&atilde;o
                alterar, combinar, adaptar, traduzir, decodificar, fazer ou solicitar a terceiros engenharia reversa dos
                Softwares; e (f) n&atilde;o criar trabalhos deles derivados ou solicitar que terceiros o fa&ccedil;am.</span></p>
              <p className="c4"><span className="c1 c3">10.2. Caso o Empreendedor deseje veicular quaisquer sinais distintivos da Logo Ali
                em seus estabelecimentos/empreendimentos, nos Produtos, Perfil do Empreendedor ou em qualquer outro material de
                divulga&ccedil;&atilde;o, dever&aacute; obter a pr&eacute;via autoriza&ccedil;&atilde;o por escrito da Logo Ali e
                somente poder&aacute; faz&ecirc;-lo de acordo com a orienta&ccedil;&atilde;o da Logo Ali.
                Autoriza&ccedil;&atilde;o que poder&aacute; ser solicitada via contato na p&aacute;gina principal do site da Logo
                Ali. </span></p>
              <p className="c4"><span className="c1 c3">10.3. O Empreendedor outorga a Logo Ali, por meio da
                assinatura/confirma&ccedil;&atilde;o no Contrato e pelo seu prazo de vig&ecirc;ncia, licen&ccedil;a gratuita de
                uso dos Sinais Distintivos, os quais ser&atilde;o veiculados na Plataforma Logo Ali e em outras m&iacute;dias,
                exclusivamente para fins de cumprimento do objeto do Contrato, responsabilizando-se &aacute; Logo Ali pelo uso
                indevido que fizer de referidos Sinais Distintivos.</span></p>
              <p className="c4"><span className="c1 c3">10.3.1. O Empreendedor declara ser o &uacute;nico e exclusivo titular ou possuir a
                devida autoriza&ccedil;&atilde;o de uso dos titulares dos direitos da propriedade intelectual sobre os Sinais
                Distintivos, reconhecendo que o Logo Ali poder&aacute; solicitar a comprova&ccedil;&atilde;o de referida
                titularidade ou autoriza&ccedil;&atilde;o.</span></p>
              <p className="c4 c26"><span className="c1 c0">11. Inadimplemento</span></p>
              <p className="c4"><span className="c1 c3">11.1. O Empreendedor reconhece e concorda que, em caso de descumprimento do
                Contrato, estar&aacute; sujeito &agrave;s seguintes penalidades, a serem determinadas e aplicadas a exclusivo
                crit&eacute;rio da Logo Ali, conforme as caracter&iacute;sticas particulares de cada caso: (a) rebaixamento da
                posi&ccedil;&atilde;o ocupada pelo Empreendedor na lista de empreendedores constante na Plataforma Logo Ali, por
                per&iacute;odo de 1 (um) a 30 (trinta) dias; (b) desativa&ccedil;&atilde;o do Perfil do Empreendedor/Loja Virtual
                pelo per&iacute;odo de 1 (um) a 30 (trinta) dias; e (c) suspens&atilde;o do Repasse, no caso previsto na
                Cl&aacute;usula 13.3, abaixo.</span></p>
              <p className="c4"><span className="c1 c3">11.2. Em hip&oacute;tese de descumprimento deste e de suas penalidades,
                poder&aacute; ser revista pela Logo Ali sem a necessidade de comunica&ccedil;&atilde;o ou aviso pr&eacute;vio ao
                Empreendedor.</span></p>
              <p className="c4"><span className="c1 c3">11.3. Sem preju&iacute;zo do direito da Logo Ali de imediatamente aplicar ao
                Empreendedor as penalidades previstas acima, no caso de uma das Partes tornar-se inadimplente no tocante a uma ou
                mais de suas obriga&ccedil;&otilde;es assumidas no Contrato, a outra Parte poder&aacute; comunic&aacute;-la para
                que, no prazo atribu&iacute;do na comunica&ccedil;&atilde;o, o qual n&atilde;o poder&aacute; ser inferior a 5
                (cinco) dias &uacute;teis, a Parte inadimplente sane e/ou esclare&ccedil;a tal inadimplemento. Se a Parte
                inadimplente n&atilde;o o fizer, a outra Parte poder&aacute; resolver imediatamente o Contrato.</span></p>
              <p className="c4 c26"><span className="c1 c0">12. Altera&ccedil;&atilde;o destas Condi&ccedil;&otilde;es Gerais</span></p>
              <p className="c4"><span className="c1 c3">12.1. O Empreendedor reconhece e concorda que a Logo Ali poder&aacute; alterar estas
                Condi&ccedil;&otilde;es Gerais a qualquer tempo, mediante o envio de notifica&ccedil;&atilde;o escrita ao
                Empreendedor, por meio do Painel do Empreendedor ou correio eletr&ocirc;nico, com anteced&ecirc;ncia m&iacute;nima
                de 30(trinta) dias contados da data de entrada em vigor da nova vers&atilde;o deste instrumento.</span></p>
              <p className="c4"><span className="c1 c3">12.1.1. Se a altera&ccedil;&atilde;o destas Condi&ccedil;&otilde;es Gerais tiver um
                efeito adverso importante sobre o Empreendedor e o Empreendedor n&atilde;o concordar com a
                altera&ccedil;&atilde;o, o Empreendedor poder&aacute; apresentar notifica&ccedil;&atilde;o escrita a Logo Ali
                &nbsp;no prazo de at&eacute; 30 (trinta) dias ap&oacute;s o recebimento da notifica&ccedil;&atilde;o da
                mudan&ccedil;a prevista na Cl&aacute;usula 13.1. &nbsp;A Logo Ali entrar&aacute; em contato com o Empreendedor
                para discutir os motivos pelos quais o Empreendedor n&atilde;o concorda com a altera&ccedil;&atilde;o destas
                Condi&ccedil;&otilde;es Gerais. Se o Empreendedor continuar a se recusar a aceitar a mudan&ccedil;a e a Logo Ali
                recusar-se a retirar a mudan&ccedil;a anunciada, qualquer das Partes poder&aacute; rescindir o Contrato, mediante
                o envio de comunica&ccedil;&atilde;o por escrito &agrave; outra Parte.</span></p>
              <p className="c4"><span className="c1 c3">12.1.2. O Empreendedor reconhece e concorda que n&atilde;o ter&aacute; o direito de
                apresentar obje&ccedil;&atilde;o a qualquer altera&ccedil;&atilde;o nestes Termos e Condi&ccedil;&otilde;es que a
                Logo Ali venha a implantar para o cumprimento de exig&ecirc;ncias legais ou regulat&oacute;rias. Para essas
                altera&ccedil;&otilde;es, per&iacute;odos de notifica&ccedil;&atilde;o menores podem ser aplicados pela Logo Ali,
                conforme necess&aacute;rio para o cumprimento dos requisitos relevantes. </span></p>
              <p className="c4"><span className="c1 c3">12.2. O n&atilde;o envio da notifica&ccedil;&atilde;o de obje&ccedil;&atilde;o
                prevista na Cl&aacute;usula 12.1.1 valer&aacute; como uma concord&acirc;ncia irrevog&aacute;vel e
                irretrat&aacute;vel do Empreendedor quanto &agrave; altera&ccedil;&atilde;o destas</span></p>
              <p className="c22"><span className="c1 c3">Condi&ccedil;&otilde;es Gerais pela Logo Ali.</span></p>
              <p className="c4 c26"><span className="c1 c0">13. Das Boas Pr&aacute;ticas de Integridade</span></p>
              <p className="c4"><span className="c1 c3">13.1. Ao cumprir as obriga&ccedil;&otilde;es previstas no Contrato, o Empreendedor,
                seus funcion&aacute;rios, agentes e representantes devem respeitar, plenamente, todas as leis aplic&aacute;veis
                sobre anticorrup&ccedil;&atilde;o, antissuborno, antiterrorismo, antiboicote, antilavagem de dinheiro e de
                san&ccedil;&otilde;es econ&ocirc;micas e de defesa da concorr&ecirc;ncia, incluindo, mas n&atilde;o limitado
                &agrave; Lei n. 12.846/2013, e conduzir as suas atividades, de acordo com os mais rigorosos conceitos e
                princ&iacute;pios da &eacute;tica, integridade e boa-f&eacute;, evitando por si e/ou por meio de terceiros,
                participa&ccedil;&atilde;o em atividades comerciais il&iacute;citas, incluindo concorr&ecirc;ncia anti&eacute;tica
                ou desleal e demais il&iacute;citos penais, das quais, em fun&ccedil;&atilde;o da atividade exercida, o
                Empreendedor delas sabe ou deveria saber.</span></p>
              <p className="c4"><span className="c1 c3">13. 2. &nbsp;O Empreendedor est&aacute; de acordo com o C&oacute;digo de
                &Eacute;tica e Conduta da Logo Ali e garante que n&atilde;o violar&aacute; as disposi&ccedil;&otilde;es nele
                constantes. </span></p>
              <p className="c4"><span className="c1 c3">13.3. O Empreendedor se compromete a fornecer, no prazo m&aacute;ximo de 30 (trinta)
                dias, documentos no formato original e de forma organizada, bem como esclarecimentos, quando solicitado, seja para
                cadastro, seja para fins de auditoria. Se compromete tamb&eacute;m a, durante o prazo de vig&ecirc;ncia do
                Contrato e um per&iacute;odo adicional de 5 (cinco) anos ap&oacute;s o seu t&eacute;rmino, manter livros
                cont&aacute;beis precisos, completos e registros apurados em conex&atilde;o com os Servi&ccedil;os. O n&atilde;o
                fornecimento dos documentos resultar&aacute; na suspens&atilde;o do Repasse at&eacute; o efetivo fornecimento dos
                documentos solicitados pela Logo Ali.</span></p>
              <p className="c4"><span className="c1 c3">13.4. O Empreendedor dever&aacute; informar ativamente a Logo Ali sobre
                a&ccedil;&otilde;es ou recursos relacionados a processos com alega&ccedil;&otilde;es de corrup&ccedil;&atilde;o,
                lavagem de dinheiro ou competi&ccedil;&atilde;o limitada, assim como investiga&ccedil;&otilde;es e medidas
                coercitivas decorrentes de viola&ccedil;&atilde;o de lei.</span></p>
              <p className="c4"><span className="c1 c3">13.5. O Empreendedor se compromete a manter a Logo Ali livre e indene de todo e
                qualquer dano e perda, incluindo multa, custo, obriga&ccedil;&atilde;o de repara&ccedil;&atilde;o de danos, taxas,
                juros, honor&aacute;rios advocat&iacute;cios ou outras responsabilidades, incluindo as criminosas, imputadas a
                Logo Ali a partir de investiga&ccedil;&atilde;o ou qualquer outro procedimento judicial ou administrativo em face
                da Logo Ali, mas que tenha sido originado a partir de qualquer a&ccedil;&atilde;o ou omiss&atilde;o do
                Empreendedor, diretamente, ou por seus administradores, s&oacute;cios, empregados, agentes, prepostos ou
                representantes, que configuram uma viola&ccedil;&atilde;o das leis e normas da Cl&aacute;usula.</span></p>
              <p className="c22"><span className="c1 c3">13.6. O descumprimento da Cl&aacute;usula anterior garante a Logo Ali a faculdade
                de rescindir o Contrato.</span></p>
              <p className="c22 c8"><span className="c1 c3"></span></p>
              <p className="c5 c26"><span className="c1 c0">14. Privacidade de Dados</span></p>
              <p className="c5"><span className="c1 c3">14.1. O Empreendedor reconhece que poder&aacute; ter acesso a dados enviados pela
                Logo Ali que identifiquem ou permitam a identifica&ccedil;&atilde;o de indiv&iacute;duos (os &ldquo;Dados
                Pessoais&rdquo;). As Partes concordam que a execu&ccedil;&atilde;o do presente Contrato ser&aacute; guiada (i)
                pelos princ&iacute;pios de privacy by design; e (ii) pelas leis brasileiras aplic&aacute;veis, incluindo, mas sem
                se limitar, o Marco Civil da Internet, C&oacute;digo de Defesa do Consumidor e a Lei Geral de
                Prote&ccedil;&atilde;o de Dados.</span></p>
              <p className="c5"><span className="c1 c3">14.2. O acesso, utiliza&ccedil;&atilde;o, coleta, produ&ccedil;&atilde;o,
                recep&ccedil;&atilde;o, classifica&ccedil;&atilde;o, reprodu&ccedil;&atilde;o, transmiss&atilde;o,
                distribui&ccedil;&atilde;o, processamento, arquivamento, armazenamento, elimina&ccedil;&atilde;o,
                avalia&ccedil;&atilde;o ou controle da informa&ccedil;&atilde;o, modifica&ccedil;&atilde;o,
                comunica&ccedil;&atilde;o, transfer&ecirc;ncia, difus&atilde;o ou extra&ccedil;&atilde;o e o compartilhamento pelo
                Empreendedor de Dados Pessoais que lhe forem enviados pela Logo Ali ser&aacute; autorizado e limitado ao
                estritamente necess&aacute;rio para a execu&ccedil;&atilde;o deste Contrato. Fica vedada a
                utiliza&ccedil;&atilde;o dos Dados Pessoais para quaisquer finalidades que n&atilde;o tenham sido expressamente
                autorizadas pelos Clientes Finais e/ou pela Logo Ali.</span></p>
              <p className="c5"><span className="c1 c3">14.3. O Empreendedor somente poder&aacute; realizar o tratamento de Dados Pessoais
                recebidos por for&ccedil;a deste Contrato durante a sua vig&ecirc;ncia e com a finalidade estrita de cumprir as
                obriga&ccedil;&otilde;es do presente instrumento.</span></p>
              <p className="c5"><span className="c1 c3">14.4. Fica vedado ao Empreendedor transferir, no todo ou em parte, os Dados Pessoais
                que lhe forem enviados pela Logo Ali para quaisquer terceiros n&atilde;o necess&aacute;rios ao cumprimento das
                obriga&ccedil;&otilde;es por ele assumidas neste Contrato, mesmo que de forma agregada e/ou anonimizada.</span>
              </p>
              <p className="c5"><span className="c1 c3">14.5. O Empreendedor dever&aacute; promover a exclus&atilde;o definitiva de
                quaisquer Dados Pessoais que lhe foram transmitidos por for&ccedil;a deste Contrato por solicita&ccedil;&atilde;o
                dos respectivos titulares dos Dados Pessoais ou da Logo Ali, com exce&ccedil;&atilde;o dos Dados Pessoais e
                fiscais, como hist&oacute;rico de vendas, data, produto ou servi&ccedil;o, valores e destina&ccedil;&atilde;o dos
                produtos, que dever&atilde;o ser mantidos por conta de obriga&ccedil;&otilde;es legais.</span></p>
              <p className="c5"><span className="c1 c3">14.6. O Empreendedor dever&aacute; notificar a Logo Ali, em at&eacute; 48 (quarenta
                e oito) horas a partir do seu conhecimento, acerca de qualquer vazamento ou comprometimento de suas bases de dados
                relacionadas com o presente Contrato, bem como acerca de qualquer viola&ccedil;&atilde;o da
                legisla&ccedil;&atilde;o de privacidade e de prote&ccedil;&atilde;o de dados pessoais que tiver ci&ecirc;ncia com
                rela&ccedil;&atilde;o aos dados em sua cust&oacute;dia, inclusive viola&ccedil;&atilde;o acidental ou
                culposa.</span></p>
              <p className="c9"><span className="c1 c0">15. Disposi&ccedil;&otilde;es Gerais</span></p>
              <p className="c4"><span className="c1 c3">15.1. A rela&ccedil;&atilde;o jur&iacute;dica estabelecida entre as Partes &eacute;
                de presta&ccedil;&atilde;o de servi&ccedil;os, de modo que o Contrato n&atilde;o estabelece rela&ccedil;&atilde;o
                de consumo, trabalho, representa&ccedil;&atilde;o comercial ou de qualquer outra natureza, sendo certo que as
                Partes s&atilde;o e permanecer&atilde;o a todo tempo aut&ocirc;nomas e independentes entre si.</span></p>
              <p className="c45 c42"><span className="c1 c3">15.2. O Empreendedor reconhece e concorda que a Logo Ali poder&aacute; ceder e
                transferir os seus direitos e obriga&ccedil;&otilde;es previstos no Contrato a quaisquer terceiros.</span></p>
              <p className="c22"><span className="c1 c3">15.3. As rela&ccedil;&otilde;es jur&iacute;dicas estabelecidas pelo Contrato
                s&atilde;o celebradas em car&aacute;ter irrevog&aacute;vel e irretrat&aacute;vel, obrigando as Partes e seus
                sucessores, seja qual for o t&iacute;tulo da sucess&atilde;o, cumprir fielmente todas as cl&aacute;usulas impostas
                em ambas as partes. A eventual toler&acirc;ncia por qualquer das Partes quanto ao inexato ou impontual cumprimento
                das obriga&ccedil;&otilde;es da outra Parte valer&aacute; t&atilde;o somente de forma isolada, n&atilde;o
                constituindo ren&uacute;ncia ou nova&ccedil;&atilde;o.</span></p>
              <p className="c45 c42"><span className="c1 c3">15.4. Caso qualquer disposi&ccedil;&atilde;o do Contrato se torne nula ou
                ineficaz, a validade ou efic&aacute;cia das disposi&ccedil;&otilde;es restantes n&atilde;o ser&aacute; afetada,
                permanecendo em pleno vigor e efeito e, em tal caso, as Partes entraram em negocia&ccedil;&otilde;es de
                boa-f&eacute; visando substituir a disposi&ccedil;&atilde;o ineficaz por outra que, tanto quanto poss&iacute;vel e
                de forma razo&aacute;vel, atinja a finalidade e os efeitos desejados. Se tratando de cancelamento das partes,
                poder&aacute; o empreendedor ou prestador de servi&ccedil;o entrar em contato com a Logo Ali solicitando o seu
                cancelamento na plataforma. A Logo Ali se reserva no direito de tamb&eacute;m rescindir ou cancelar o acesso
                &agrave; plataforma, caso ache inadequada, incoerente ou suspeite de alguma a&ccedil;&atilde;o ou fa&ccedil;anha
                que possa prejudicar a empresa ou causar danos ou infringir as leis vigentes. </span></p>
              <p className="c5"><span className="c1 c3">15.5. O Empreendedor reconhece e concorda que a Logo Ali, a seu exclusivo
                crit&eacute;rio, poder&aacute; fornecer a terceiros interessados, a t&iacute;tulo gratuito ou oneroso, dados e
                informa&ccedil;&otilde;es gerais obtidos a partir do seu banco de dados, incluindo, sem limita&ccedil;&atilde;o,
                informa&ccedil;&otilde;es a respeito de padr&otilde;es de comportamento, h&aacute;bitos de consumo e outras
                estat&iacute;sticas. Referidos dados e informa&ccedil;&otilde;es em nenhuma hip&oacute;tese indicar&atilde;o o
                nome do Empreendedor ou de seu estabelecimento/empreendimento, ser&atilde;o apresentados de modo que possam ser
                identificados como lhe sendo atribu&iacute;dos especificamente. Aceitando esse termo, o empreendedor concorda e
                autoriza que os dados que estejam sobre controle ou acesso da Logo Ali, possam ser compartilhados com terceiros,
                em conson&acirc;ncia com a LGPD.</span></p>
              <p className="c5"><span className="c1 c3">15.6. As disposi&ccedil;&otilde;es contidas no Contrato representam a totalidade dos
                entendimentos mantidos entre as Partes, superando todos e quaisquer entendimentos anteriores, verbais ou escritos,
                consubstanciando-se na declara&ccedil;&atilde;o final de suas vontades.</span></p>
              <p className="c5"><span className="c1 c3">15.7. O Empreendedor declara que est&aacute; ciente e de acordo com que a Logo Ali
                preste os servi&ccedil;os objeto destas Condi&ccedil;&otilde;es Gerais a quaisquer outros estabelecimentos, ainda
                que estes sejam direta ou indiretamente concorrentes do Empreendedor.</span></p>
              <p className="c5"><span className="c1 c3">15.8. Caso o Empreendedor aceite os termos deste Contrato por meio de qualquer
                ferramenta eletr&ocirc;nica ou no cadastro, a Logo Ali poder&aacute; solicitar, a qualquer momento, que o
                Empreendedor assine e rubrique uma via f&iacute;sica do Contrato e destas Condi&ccedil;&otilde;es Gerais. Caso o
                Empreendedor n&atilde;o cumpra essa solicita&ccedil;&atilde;o da Logo Ali no prazo de at&eacute; 5 (cinco) dias
                &uacute;teis contados da data de recebimento da notifica&ccedil;&atilde;o da Logo Ali a este respeito, a Logo Ali
                ter&aacute; o direito de suspender, total ou parcialmente, os servi&ccedil;os descritos nestes Termos e
                Condi&ccedil;&otilde;es, at&eacute; que Empreendedor tenha cumprido tal solicita&ccedil;&atilde;o. A
                solicita&ccedil;&atilde;o ficar&aacute; a crit&eacute;rio da Logo Ali quando achar necess&aacute;rio e pertinente
                tal assinatura para mantermos documentos v&aacute;lidos juridicamente, caso alguma vez possa ser solicitado ou
                questionado por terceiros ou &oacute;rg&atilde;os fiscalizadores. Em caso de d&uacute;vidas ou n&atilde;o
                concordarem com o presente termo, antes da sua assinatura ou aceita&ccedil;&atilde;o, poder&aacute; entrar em
                contato com a Logo Ali para questionamento/esclarecimentos, ficando a crit&eacute;rio do
                empreendedor/usu&aacute;rio consultar a terceiros. </span></p>
              <p className="c5"><span className="c1 c3">15.9. O Contrato ser&aacute; regido e interpretado de acordo com a
                legisla&ccedil;&atilde;o da Rep&uacute;blica Federativa do Brasil. No caso de qualquer reivindica&ccedil;&atilde;o
                ou controv&eacute;rsia decorrente do Contrato, ou a eles relacionada, ou, ainda, resultante de seu inadimplemento,
                as Partes elegem desde j&aacute; o foro da Comarca de Diamantina, Estado de Minas Gerais, para a
                solu&ccedil;&atilde;o da reivindica&ccedil;&atilde;o ou controv&eacute;rsia, com a exclus&atilde;o de qualquer
                outro, por mais privilegiado que seja.</span></p>
              <p className="c5"><span className="c3">15.10 Todas as promo&ccedil;&otilde;es ou atualiza&ccedil;&otilde;es que sejam
                ofertadas pelo a Logo Ali, o empreendedor ficar&aacute; a crit&eacute;rio de participar ou n&atilde;o, sendo que
                automaticamente, por ter aceitado esse termo, entende-se que todas as promo&ccedil;&otilde;es ou
                atualiza&ccedil;&otilde;es que sejam lan&ccedil;adas, incluir&aacute; o empreendimento por fazer parte da
                plataforma/aplicativo da Logo Ali. Caso n&atilde;o deseje participar de alguma promo&ccedil;&atilde;o ou
                atualiza&ccedil;&atilde;o, dever&aacute; o empreendedor entrar em contato com a Logo Ali solicitando a n&atilde;o
                participa&ccedil;&atilde;o da promo&ccedil;&atilde;o ou atualiza&ccedil;&atilde;o, via e-mail ou pela
                p&aacute;gina principal da Logo Ali, em contato. </span>

              </p>
              <p className="c41"><span className="c37">CONTRATA&Ccedil;&Atilde;O DA LOGO ALI SOLU&Ccedil;&Otilde;ES DIGITAIS LTDA</span></p>
              <ol className="c13 lst-kix_38dv21rq0khz-0 start" start="1">
                <li className="c31 c55 li-bullet-0"><span className="c0">Partes</span></li>
              </ol>
              <p className="c6"><span className="c1 c0">Logo Ali:</span></p>
              <p className="c6"><span className="c1 c3">Raz&atilde;o Social: LOGO ALI SOLUCOES DIGITAIS LTDA</span></p>
              <p className="c20"><span className="c1 c3">CNPJ/MF: 42.099.146/0001-86</span></p>
              <p className="c20"><span className="c1 c3">Endere&ccedil;o: Rua Mil Oitavas, n&uacute;mero 325</span></p>
              <p className="c20"><span className="c1 c3">Bairro: Bela Vista</span></p>
              <p className="c20"><span className="c1 c3">Cidade/Estado: Diamantina - Minas Gerais</span></p>
              <p className="c20"><span className="c1 c3">CEP: 39.100-000</span></p>
              <p className="c20"><span className="c3">E-mail: </span><span className="c38 c3"><a className="c10"
                href="mailto:contato@logoaliapp.com.br">contato@logoaliapp.com.br</a></span></p>
              <p className="c20"><span className="c1 c3">Telefone: (38)99890-4086 </span></p>
              <p className="c20 c8"><span className="c1 c3"></span></p>
              <p className="c55"><span className="c1 c0">Empreendedor: </span></p>
              <p className="c6"><span className="c1 c3">Contratante dos servi&ccedil;os oferecidos pela Logo Ali.</span></p>
              <p className="c6 c8"><span className="c1 c3"></span></p>
              <ol className="c13 lst-kix_38dv21rq0khz-0" start="2">
                <li className="c6 c31 li-bullet-0"><span className="c1 c0">Prazo de Vig&ecirc;ncia</span></li>
              </ol>
              <p className="c6 c8"><span className="c1 c0"></span></p>
              <p className="c44"><span className="c1 c3">A partir da aceita&ccedil;&atilde;o/confirma&ccedil;&atilde;o do contrato,
                ter&aacute; o prazo de 30 dias em per&iacute;odo experimental (5. Condi&ccedil;&otilde;es Comerciais Especiais),
                sendo que ap&oacute;s esse prazo, o contrato seguir&aacute; os seguintes par&acirc;metros: O prazo de
                vig&ecirc;ncia ser&aacute; automaticamente renovado por per&iacute;odo de 12 (doze) meses, sendo que ap&oacute;s
                esse prazo, o contrato ser&aacute; por tempo indeterminado. Qualquer das Partes poder&aacute; comunicar a sua
                decis&atilde;o de n&atilde;o renovar o Contrato &agrave; outra Parte com anteced&ecirc;ncia m&iacute;nima de 30
                (trinta) dias contados do t&eacute;rmino do per&iacute;odo de vig&ecirc;ncia inicial ou de qualquer per&iacute;odo
                subsequente.</span></p>
              <p className="c44 c8"><span className="c1 c3"></span></p>
              <ol className="c13 lst-kix_38dv21rq0khz-0" start="3">
                <li className="c18 c46 li-bullet-0"><span className="c0">Plano de </span><span className="c0">Contrata&ccedil;&atilde;o
                </span><span className="c1 c0">&nbsp; &nbsp;</span></li>
              </ol>
              <p className="c16"><span className="c1 c3">Repasse Financeiro: </span></p>
              <p className="c6"><span className="c3">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span><span className="c0">&nbsp;PIX: </span><span
                className="c1 c3">Saldo em conta em D+1</span></p>
              <p className="c6"><span className="c0">&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                className="c0">Cart&atilde;o de Cr&eacute;dito: </span><span className="c3">Saldo em conta em D+15</span></p>
              <p className="c26 c56"><span className="c0">Saque: </span><span className="c1 c3">Autom&aacute;tico, efetivado toda segunda-feira
                ou di&aacute;rio</span></p>
              <p className="c16"><span className="c1 c3">Comiss&atilde;o: 12% </span></p>
              <p className="c16"><span className="c1 c3">Taxa de servi&ccedil;o de terceiros: R$ 0,50</span></p>
              <p className="c16"><span className="c1 c3">Para os prestadores de servi&ccedil;o, se aplica da seguinte forma:</span></p>
              <p className="c16"><span className="c3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                className="c0">Cobran&ccedil;a mensal: </span><span className="c1 c3">R$ 39,90</span></p>
              <p className="c16"><span className="c1 c3">Ser&aacute; cobrado via cart&atilde;o de cr&eacute;dito cadastrado na plataforma.
              </span></p>
              <p className="c16"><span className="c1 c3">Quem possuir as duas atividades(produtos e servi&ccedil;os), ser&atilde;o cobradas
                conforme as condi&ccedil;&otilde;es acima, sendo necess&aacute;rio ter o cart&atilde;o cadastrado para a
                cobran&ccedil;a e a conta conforme feito no cadastro para recebimento dos repasses dos produtos. </span></p>
              <p className="c16 c8"><span className="c1 c3"></span></p>
              <ol className="c13 lst-kix_38dv21rq0khz-0" start="4">
                <li className="c6 c18 li-bullet-0"><span className="c1 c0">Condi&ccedil;&otilde;es Comerciais Especiais</span></li>
              </ol>
              <p className="c29"><span className="c3">Redu&ccedil;&atilde;o de cobran&ccedil;a nos </span><span className="c0">30 primeiros
                dias</span><span className="c3">, a partir da data de lan&ccedil;amento da Plataforma Logo Ali, oferecemos um desconto
                  de utiliza&ccedil;&atilde;o dos servi&ccedil;os de </span><span className="c0">12% de comiss&atilde;o para
                    7%</span><span className="c3">&nbsp;+ R $0,50 incluindo a taxa de servi&ccedil;o.</span><span
                      className="c1 c0">&nbsp;</span></p>
              <p className="c29"><span className="c1 c3">Para os prestadores de servi&ccedil;o, ser&aacute; pelo o mesmo per&iacute;odo
                acima, o valor especial a t&iacute;tulo de lan&ccedil;amento/promo&ccedil;&atilde;o R $11,99.</span></p>
              <p className="c29"><span className="c1 c3">Outras condi&ccedil;&otilde;es especiais, poder&atilde;o e ser&atilde;o criadas
                futuramente. Antes e toda promo&ccedil;&atilde;o que for lan&ccedil;ada, os empreendedores ficar&atilde;o sabendo
                atrav&eacute;s de notifica&ccedil;&atilde;o ou por meio de contato cadastrado na plataforma, podendo ser via
                e-mail ou mensagem no whatsapp. </span></p>
              <p className="c6 c8 c36"><span className="c1 c0"></span></p>
              <ol className="c13 lst-kix_38dv21rq0khz-0" start="5">
                <li className="c6 c18 li-bullet-0"><span className="c1 c0">Termos e Condi&ccedil;&otilde;es</span></li>
              </ol>
              <p className="c23 c8 c47"><span className="c1 c61"></span></p>
              <p className="c44"><span className="c3">O Empreendedor declara ter lido e expressamente concorda, sem quaisquer reservas ou
                ressalvas, com os Termos e Condi&ccedil;&otilde;es Gerais de Contrata&ccedil;&atilde;o da Logo Ali,
                disponibilizados no cadastro do empreendedor, os quais s&atilde;o parte integrante desta
                Contrata&ccedil;&atilde;o. O Empreendedor reconhece e concorda que a sua anu&ecirc;ncia integral com os Termos e
                Condi&ccedil;&otilde;es Gerais de Contrata&ccedil;&atilde;o da Logo Ali &eacute; condi&ccedil;&atilde;o essencial
                para a contrata&ccedil;&atilde;o dos servi&ccedil;os oferecidos pela Logo Ali.</span></p>
              <p className="c6 c8 c47"><span className="c1 c3"></span></p>
              <ol className="c13 lst-kix_38dv21rq0khz-0" start="6">
                <li className="c6 c18 li-bullet-0"><span className="c0">Confirma&ccedil;&atilde;o</span></li>
              </ol>
              <p className="c23 c8 c47"><span className="c1 c3"></span></p>
              <p className="c47 c58"><span className="c1 c3">Ao confirmar/aceitar este Contrato, o Empreendedor declara expressamente que
                leu, compreendeu e concorda com todas as disposi&ccedil;&otilde;es de Contrata&ccedil;&atilde;o e dos Termos e
                Condi&ccedil;&otilde;es.</span></p>
              <p className="c5 c47"><span className="c3">As Partes resolvem celebrar o presente Termo de Acordo (&ldquo;Instrumento&rdquo;),
                segundo as cl&aacute;usulas e condi&ccedil;&otilde;es a seguir, tendo como base as informa&ccedil;&otilde;es
                dispostas na Contrata&ccedil;&atilde;o.</span>

              </p>
              <p className="c23"><span className="c1 c35">Previs&otilde;es Gerais</span></p>
              <p className="c23"><span className="c1 c0">Confidencialidade:</span></p>
              <p className="c5"><span className="c1 c3">O Empreendedor dever&aacute; manter sigilo absoluto sobre todas e quaisquer
                informa&ccedil;&otilde;es a respeito da Logo Ali e dos Clientes Finais a que tiver acesso em decorr&ecirc;ncia da
                contrata&ccedil;&atilde;o dos servi&ccedil;os previstos no Contrato, incluindo das condi&ccedil;&otilde;es
                comerciais definidas na Contrata&ccedil;&atilde;o (&ldquo;Informa&ccedil;&otilde;es Confidenciais&rdquo;), bem
                como dever&aacute; tomar todas as medidas necess&aacute;rias ou &uacute;teis para a manuten&ccedil;&atilde;o da
                confidencialidade e da integridade de referidas informa&ccedil;&otilde;es e somente poder&aacute;
                utiliz&aacute;-las para finalidades que resultem do presente instrumento.</span></p>
              <p className="c5"><span className="c1 c0">Notifica&ccedil;&otilde;es:</span></p>
              <p className="c22"><span className="c3">Quaisquer notifica&ccedil;&otilde;es referentes a este Contrato dever&atilde;o ser
                feitas por escrito; enviadas por quaisquer meios que forne&ccedil;am comprovante autenticado do seu
                conte&uacute;do e data de envio (incluindo por e-mail com confirma&ccedil;&atilde;o de recebimento). As
                notifica&ccedil;&otilde;es ao Empreendedor dever&atilde;o ser enviadas ao endere&ccedil;o informado no
                pre&acirc;mbulo deste Contrato. As notifica&ccedil;&otilde;es a Logo Ali dever&atilde;o ser enviadas em:
              </span><span className="c1 c0">contato@logoaliapp.com.br</span></p>
              <p className="c22 c8"><span className="c1 c3"></span></p>
              <p className="c5"><span className="c1 c0">Declara&ccedil;&atilde;o de Regularidade do Empreendimento:</span></p>
              <p className="c22"><span className="c1 c3">O Empreendedor, ora qualificado no in&iacute;cio desse Instrumento, declara, sob
                pena de n&atilde;o acesso a Plataforma, que &eacute; pessoa jur&iacute;dica/f&iacute;sica formalmente
                constitu&iacute;da e regularmente estabelecida, &uacute;nica e exclusivamente respons&aacute;vel por cumprir e
                observar todos os requisitos legais e infralegais, fiscais e sanit&aacute;rios para abertura e desenvolvimento
                cont&iacute;nuo e pleno de sua atividade, comprometendo-se a atuar em plena regularidade perante todos os
                &oacute;rg&atilde;os, ag&ecirc;ncias e autoridades p&uacute;blicas, durante toda a sua perman&ecirc;ncia na
                plataforma da Logo Ali e na comercializa&ccedil;&atilde;o dos seus produtos atrav&eacute;s dela.</span></p>
              <p className="c22"><span className="c1 c3">Manter&aacute; todas as informa&ccedil;&otilde;es requisitadas pela Logo Ali
                atualizadas devendo, em caso de</span></p>
              <p className="c22"><span className="c1 c3">Qualquer altera&ccedil;&atilde;o, informar a Logo Ali imediatamente,
                comprometendo-se a manter a Logo Ali independente de quaisquer penalidades impostas em raz&atilde;o da n&atilde;o
                observ&acirc;ncia nos termos firmados nesta, em caso de qualquer altera&ccedil;&atilde;o, informar a Logo Ali
                imediatamente.</span></p>
              <p className="c45 c42"><span className="c1 c0">Declara&ccedil;&atilde;o de Compartilhamento de dados:</span></p>
              <p className="c5"><span className="c1 c3">O Empreendedor, ora qualificado no in&iacute;cio deste Instrumento, declara estar
                ciente e autoriza a Logo Ali a compartilhar os dados e informa&ccedil;&otilde;es de sua opera&ccedil;&atilde;o na
                Logo Ali, seja as que o Empreendedor fornecer ou aquelas que forem coletadas automaticamente, com empresas
                parceiras da Logo Ali, de modo que esses parceiros possam oferecer produtos e servi&ccedil;os financeiros ao
                Estabelecimento, direta ou indiretamente. A Logo Ali realizar&aacute; o compartilhamento de dados e
                informa&ccedil;&otilde;es sempre respeitando a legisla&ccedil;&atilde;o de prote&ccedil;&atilde;o de dados
                aplic&aacute;vel, especialmente nos termos de sua pr&oacute;pria Pol&iacute;tica de Privacidade. O Empreendedor
                autoriza que as empresa parceiras verifiquem a regularidade e veracidade dos dados fornecidos, autorizando a
                obten&ccedil;&atilde;o de dados adicionais e o fornecimento das informa&ccedil;&otilde;es fornecidas pelo
                Empreendedor &agrave;s empresas de an&aacute;lises de cr&eacute;dito e de prote&ccedil;&atilde;o ao cr&eacute;dito
                e ao Sistema de Informa&ccedil;&otilde;es de Cr&eacute;dito &ndash; SCR, do Banco Central do Brasil, para fins de
                avalia&ccedil;&atilde;o do risco de cr&eacute;dito e interc&acirc;mbio de informa&ccedil;&otilde;es com outras
                institui&ccedil;&otilde;es financeiras, quando aplic&aacute;vel. O Empreendedor declara estar ciente que (a) as
                empresas parceiras ser&atilde;o as &uacute;nicas respons&aacute;veis pela qualidade dos servi&ccedil;os ou
                produtos financeiros oferecidos ao Empreendedor, e (b) a Logo Ali n&atilde;o possui qualquer inger&ecirc;ncia
                sobre tais servi&ccedil;os e produtos, n&atilde;o podendo ser responsabilizado de qualquer forma.</span></p>
              <p className="c5"><span className="c1 c0">A) Redu&ccedil;&atilde;o de Comiss&atilde;o</span></p>
              <p className="c22"><span className="c1 c3">A Logo Ali garantir&aacute; ao Empreendedor redu&ccedil;&atilde;o nos valores
                devidos a t&iacute;tulo de Comiss&atilde;o durante o prazo definido na Contrata&ccedil;&atilde;o. Encerrado esse
                prazo, a Comiss&atilde;o passar&aacute; a ser cobrada automaticamente, independentemente do envio de
                notifica&ccedil;&atilde;o ou qualquer outra comunica&ccedil;&atilde;o pela Logo Ali ao Empreendedor. Qualquer
                outra redu&ccedil;&atilde;o que possa haver de futuras atualiza&ccedil;&otilde;es ou promo&ccedil;&otilde;es, que
                resulte na diminui&ccedil;&atilde;o dos valores do empreendedor, ficar&aacute; a cargo da Logo Ali explicar e se
                necess&aacute;rio, ressarcir o empreendedor caso seja feita de forma incorreta ou indevida. </span></p>
              <p className="c4 c8"><span className="c1 c3"></span></p>
              <p className="c4 c8"><span className="c1 c3"></span></p>
              <p className="c4 c8"><span className="c1 c3"></span></p>
              <p className="c8 c22"><span className="c1 c3"></span></p>
              <div>
                <p className="c8 c19"><span className="c1 c64"></span></p>
              </div>


            </div>
            <a href='/cadastro' className='btn-termo' >Voltar para o cadastro</a>
          </form>
          {/* <button onClick={() => handleOpen()}>Próximo</button> */}
          <div style={{ height: '60px', width: '100%', marginTop: '60px' }}  >
            <h1></h1>
          </div>
        </div>
      </div>
    </>
  )
}

export default Form
