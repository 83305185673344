import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Slide,
} from '@material-ui/core';
import { logout, myToken } from '../../utils/auth';
import axios from 'axios';

import Alert from '../modal/Alert';
import NewAddress from '../modal/newAddress';
import UpdateAddress from '../modal/UpdateAddress';

import './styles.css';
import api from '../../utils/services';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    marginTop: 15,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Address = () => {
  const classes = useStyles();
  const [info, setInfo] = useState(false);
  const [openModalSubmit, setModalSubmit] = useState(false);

  const [address, setAddress] = useState([]);
  const [newAddress, setNewAddress] = useState({});

  const [action, setAction] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [addAddress, setAddAddress] = useState(false);

  const [select, setSelect] = useState({});

  const handleChangeAddress = (event) => {
    const { name, value } = event.target;

    setNewAddress({ ...newAddress, [name]: value });
  };

  const handleSubmitAddress = async (item) => {
    /*
    axios.get('https://api.sdx.logoaliapp.com.br/addresses',
        {
          headers: {
            "Authorization": `Bearer ${myToken()}`,
          }
        }
      ).then(res => {
        // console.log('Endereço', res)
        setAddress(res.data)
      })
        .catch(error => {
          alert('Sua sessão expirou, faça o Login novamente')
          logout();
          window.location.reload()
      }) */
    /*let data = {
      state: "mg",
      city: "Turmalina",
      street: "Rua joão cordeiro",
      zipCode: "39660000",
      number: 78,
      default: true,
      complement: "casa",
      neighborhood: "campo"
    } */
    /*
    await axios.put(`https://api.sdx.logoaliapp.com.br/addresses/${getId}`, data, {
      headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${myToken()}`,
      }
    }).then(res => {
      alert('Deletado com sucesso')
      handleCloseSubmitModal()
      window.location.reload()
    })
      .catch(error => {
        alert('Sua sessão expirou, faça o Login novamente')
        console.log('Deu erro:' + error)
        handleCloseSubmitModal()
        //  logout();
        //  window.location.reload()
      })
      */
  };

  const handleCloseSubmitModal = () => {
    setModalSubmit(false);
  };

  useEffect(() => {
    async function loadAddress() {
      api
        .get('/addresses')
        .then((res) => {
          setAddress(res.data);
        })
        .catch((error) => {
          alert('Sua sessão expirou, faça o Login novamente');
          logout();
          window.location.reload();
        });
    }
    loadAddress();
  }, []);

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        {addAddress === true ? (
          <NewAddress
            onClose={() => setAddAddress(false)}
            allAddress={address}
            setAllAddress={setAddress}
          />
        ) : null}

        {action === true ? (
          <Alert
            Title="Tem certeza que deseja alterar essas informações?"
            SubTitle="Clicando em Sim, os dados enviados irão alterar. Para corrir é so repetir o processo"
            onClose={() => setAction(false)}
            onSubmit={() => setSubmit(true)}
          />
        ) : null}

        <Paper
          elevation={0}
          className={classes.paper}
          style={{
            width: '100%',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h1>Endereços Cadastrados</h1>

          <button
            onClick={() => {
              setInfo(false);
              setAddAddress(true);
            }}
            style={{ backgroundColor: '#4A78FF', marginLeft: 10 }}
            className="btn-action-edit"
          >
            {' '}
            + Novo Endereço
          </button>
        </Paper>

        {/* Salvar */}

        <Dialog
          open={openModalSubmit}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseSubmitModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'Você deseja realmente salvar esse novo endereço?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Após salvar seu endereço será atualizado.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubmitModal} color="secondary">
              Não
            </Button>
            <Button onClick={() => handleSubmitAddress()} color="primary">
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>

      <Paper
        elevation={0}
        className="container-loop-address"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {info === true ? (
          <UpdateAddress
            onClose={() => setInfo(false)}
            addressDefault={select}
            allAddress={address}
            setAllAddress={setAddress}
          />
        ) : (
          <div>
            {/*                       
                      <button onClick={() => setInfo(false)} style={{ marginLeft: 10, backgroundColor: '#E8505B' }} className="btn-action-edit" >Cancelar</button>
                      <button onClick={() => {
                        setInfo(false);
                        setAction(true)
                        // handleOpenSubmitModal();
                        // console.log(newAddress)
                      }} style={{ backgroundColor: '#31DD61', marginLeft: 10 }} className="btn-action-edit" >Salvar</button> 
                      */}
          </div>
        )}

        {address.map((item, index) => {
          return (
            <div key={item.id} className="form-edit">
              <div
                style={{ justifyContent: 'space-between' }}
                className="form-btn-edit-row"
              >
                <h2>
                  Endereço {index + 1}{' '}
                  <span>{item.default === true ? 'Primario' : null}</span>{' '}
                </h2>

                <button
                  onClick={() => {
                    setInfo(true);
                    setSelect(item);
                  }}
                  style={{ backgroundColor: '#4A78FF' }}
                  className="btn-action-edit"
                >
                  Editar
                </button>
              </div>
              <div className="form-edit-row">
                <h3>Cep: </h3>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="zipCode"
                  onChange={handleChangeAddress}
                  defaultValue={address && address[index].zipCode}
                />
              </div>

              <div className="form-edit-row">
                <h3>Estado: </h3>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="state"
                  onChange={handleChangeAddress}
                  defaultValue={address && address[index].state}
                />
              </div>

              <div className="form-edit-row">
                <h3>Rua: </h3>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="street"
                  onChange={handleChangeAddress}
                  defaultValue={address && address[index].street}
                />
              </div>

              <div className="form-edit-row">
                <h3>Número: </h3>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="number"
                  onChange={handleChangeAddress}
                  defaultValue={address && address[index].number}
                />
              </div>

              <div className="form-edit-row">
                <h3>Complemento: </h3>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="complement"
                  onChange={handleChangeAddress}
                  defaultValue={address && address[index].complement}
                />
              </div>

              <div className="form-edit-row">
                <h3>Bairro: </h3>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="neighborhood"
                  onChange={handleChangeAddress}
                  defaultValue={address && address[index].neighborhood}
                />
              </div>

              <div className="form-edit-row">
                <h3>Usar como padrão?: </h3>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="select"
                  name="neighborhood"
                  onChange={handleChangeAddress}
                  defaultValue={address[index].default === true ? 'Sim' : 'Não'}
                />
              </div>
            </div>
          );
        })}
      </Paper>
    </>
  );
};

export default Address;
