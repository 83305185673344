import React,{useEffect, useState} from 'react'
import { Box, Container, Grid, Paper } from '@material-ui/core';
import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

import ContentModal from './components/ContentModal.js'
import FeedbackModal from './components/FeedbackModal.js'

import MaterialTable from 'material-table'


import './styles.css'
import api from '../../../utils/services';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    height: '100vh',
    overflow: 'auto',
    width: '100vw',
    backgroundColor: '#f5f4f6',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    minWidth: 650,
  },
  btnRegister: {
    border: 'none',
    outline: 'none',
    backgroundColor: '#1976d2',
    padding: '8px',
    fontWeight: 600,
    color: '#FFF',
    cursor: 'pointer'
  },
  paperModal: {
    position: "absolute",
    width: '40%',
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  btnActions: {
    marginRight: 5,
    border: 'none',
    outline: 'none',
    padding: 5,
    color: '#FFF',
    cursor: 'pointer'
  }
}));


const DevolutionAndCanceled = () => {

  const [pedidos, setPedidos] = useState([])

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);


  const [filter, setFilter] = React.useState(false);
  const [pedidoSelect, setPedidoSelect] = useState({})

  const classes = useStyles();

  const [pageSelected, setPageSelected] = useState(1);
  const limitePage = 10;
  const [totalPages, setTotalPages] = useState(0)



  const handlePagination = (action) =>{
    if(action ==='add' && pageSelected+1 <= totalPages){
      api.get(`/sales/requests?page=${pageSelected+1}&limit=${limitePage}&isHistoryRequests=true`)
     .then( res =>{
      let newArray = res.data.requests.map(item => {
        if (item.status === "canceled") {
          item.date = new Date(item.date);
          return item;
        }
        return null; 
      }).filter(item => item !== null);
      setPedidos(newArray);
    })
    .catch( error =>{
      console.log('error', error.response)
      if(error.response.status === 401){
        alert('Sua sessão expirou, faça o Login novamente')
        //logout();
        window.location.reload()
      }
    })
    }else if(action ==='prev' && pageSelected+1 >= 1){
      api.get(`/sales/requests?page=${pageSelected-1}&limit=${limitePage}&isHistoryRequests=true`)
    .then( res =>{
      let newArray = res.data.requests.map(item => {
        if (item.status === "canceled") {
          item.date = new Date(item.date);
          return item;
        }
        return null; 
      }).filter(item => item !== null);
  
      setPedidos(newArray);
    })
    .catch( error =>{
      console.log('error', error.response)
      if(error.response.status === 401){
        alert('Sua sessão expirou, faça o Login novamente')
        //logout();
        window.location.reload()
      }
    })
    }else{
      alert('Não existe')
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPedidoSelect({})
  };

  const handleClose2 = () => {
    setOpen2(false);
    setPedidoSelect({})
  };

  useEffect(() =>{
    function loadProducts(){

      api.get('/sales/requests?page=1&limit=20&isHistoryRequests=true')
      .then(res => {
        const newArray = res.data.requests.map(item => {
          if (item.status === "canceled") {
            item.date = new Date(item.date);
            return item;
          }
          return null; 
        }).filter(item => item !== null);
        setTotalPages(Math.ceil(newArray.length/limitePage))
        setPedidos(newArray);
      })
      .catch(error => console.log('Erro', error));

    }
    loadProducts();
  },[])
  
  return (
    <div className={classes.root}>
      <MenuAdmin />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={1}>

          <Grid item xs={12}>
          <Paper elevation={0} style={{padding: '20px 5px'}} >

      
              <MaterialTable
                elevation={0}
                columns={[
                  { title: 'Código', field: 'orderNumber' },
                  { title: 'Cliente', field: 'clientName' },
                  { title: 'Origem', field: 'canceledBy', render: rowData => rowData.canceledBy === "establishment" ? "Estabelecimento" : "Cliente" },
                  { title: 'Data', field: 'date', render: rowData => format(rowData.date, 'dd/MM/yyyy - HH:mm') },
                  { title: 'Motivo', field: 'reasonToCanceled', render: rowData => rowData.reasonToCanceled === null ? "Nenhum motivo" : rowData.reasonToCanceled },
                  { title: 'Status', field: 'status', render: rowData => rowData.status === "canceled" ? "Cancelado" : rowData.status },
                ]}
                data={pedidos}
                title="Pedidos cancelados"
                localization={{
                  pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'Linhas'
                  },
                  toolbar:{
                    searchPlaceholder: 'Pesquisar',
                    nRowsSelected: '{0} linha(s) selecionada(s)',
                  },
                  header:{
                    actions: "Ações",
                  },
                  body:{
                    emptyDataSourceMessage: 'Não há registros a serem exibidos'
                  }
                }}
                actions={[
                  {
                    icon:()=><Checkbox
                    color="primary"
                    checked={filter}
                    onChange={() => filter === false ? setFilter(true) : setFilter(false)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />,
                  tooltip:"Deseja filtrar? ",
                  isFreeAction:true
                  },
                  {
                    icon: 'visibility',
                    tooltip: 'Visualizar pedido',
                    onClick: (_, rowData) => {
                      setPedidoSelect(rowData)
                      handleOpen()
                    },
                  },
                ]}
                options={{
                  filtering: filter,
                  hideFilterIcons: true,
                  actionsColumnIndex: -1,
                  paging: false,
                }}
              /> 
              <div className='pagination-container' >
                <div className="pagination-content">
                  <button
                    disabled={pageSelected - 1 >= 1 ? false : true}
                    onClick={() => {
                      setPageSelected(pageSelected - 1)
                      handlePagination('prev')
                    }}>
                    Anterior
                  </button>
                  <input style={{ textAlign: 'center' }} type="text" value={pageSelected} />
                  <input style={{ textAlign: 'center', backgroundColor: "#DDD" }} disabled type="text" value={totalPages} />
                  <button
                    disabled={pageSelected + 1 <= totalPages ? false : true}
                    onClick={() => {
                      setPageSelected(pageSelected + 1)
                      handlePagination('add')
                    }}>
                    Próximo
                  </button>
                </div>
              </div> 
              </Paper>
              {
                open &&
                <ContentModal 
                open={open}
                handleClose={handleClose}
                data={pedidoSelect}
              /> 
              }
              {
                open2 &&
                <FeedbackModal 
                open={open2}
                handleClose={handleClose2}
                data={pedidoSelect}
              /> 
              }
          </Grid>

          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default DevolutionAndCanceled;