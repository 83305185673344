import React, { useEffect, useState } from 'react';
import { setupNotifications } from './utils/firebase';
import useVisibilityChange from './utils/hooks/useVisibility';
import Root from './components/Root';
import alertMp3 from './assets/sale.mp3';
import iconBell3d from './assets/bell-3d.png';
import Swal from 'sweetalert2';

const App = () => {
  const [amount, setAmount] = useState(1);

  const isForeground = useVisibilityChange();
  useEffect(() => {
    setupNotifications(() => {
      if (isForeground) {
        let audio = new Audio(alertMp3);
        audio.play();
        setAmount(amount + 1);
        Swal.fire({
          title: 'Um novo pedido',
          text: `${amount} pedido(s)`,
          iconHtml: `<img src=${iconBell3d}>`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Visualizar',
          cancelButtonText: 'Ignorar',
          customClass: {
            icon: 'bell-notification',
          },
        }).then((result) => {
          audio.pause();
          const pathname = '/pedidos';
          if (window.location.pathname !== pathname && result.isConfirmed) {
            window.location.pathname = pathname;
          }
        });
      }
    });
  }, [amount, isForeground]);

  return (
    <>
      <Root />
    </>
  );
};

export default App;
