import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';


import { history } from '../history';

import { logout } from '../utils/auth'
import { ContactSupport } from '@material-ui/icons';
// const url = window.location.href;

// const [select, setSelect] = useState(-1)
export default function SecondaryListMenu() {
  return (
    <>
      <div>
        <ListSubheader inset>Opções</ListSubheader>
        <ListItem button onClick={() => {
          window.open('https://rebrand.ly/suportepainel', '_blank');
        }
        } >
          <ListItemIcon>
            <ContactSupport />
          </ListItemIcon>
          <ListItemText primary="Suporte" />
        </ListItem>

        <ListItem button onClick={() => {
          logout()
          history.push('/')
        }
        } >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </div>
    </>
  )
}
