import { useState } from 'react';
import './styles.css';
import api from '../../../../utils/services';
import { getId } from '../../../../utils/auth';
import MaterialTable from 'material-table';
import defaultImage from '../../../../assets/default-profile.png';
import IntlCurrencyInput from 'react-intl-currency-input';
import CardPromotionAnnouncements from './CardPromotionAnnouncements';
import formatedCurrencyBr from '../../../../common/formatCurrency';
import Swal from 'sweetalert2';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const initialInfosPromotionAnnouncements = {
  active: '',
  limit: '',
  onlyNewClients: '',
  totalPerUser: '',
};

const ContentModalPromotion = ({ handleClose, promotions, setLoading }) => {
  //States
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);

  const [productsSelected, setProductsSelected] = useState([]);
  const [listProducts, setListProducts] = useState([]);

  const [infosPromotionAnnouncements, setInfosPromotionAnnouncements] =
    useState(initialInfosPromotionAnnouncements);
  const [generalPromotionInfos, setGeneralPromotionInfos] = useState({});

  const [typePromotion, setTypePromotion] = useState('');
  const [percentage, setPercentage] = useState(0);

  // onChanges
  const handleChangeGeneralPromotionInfos = (event) => {
    const { name, value, type } = event.target;
    if (type === 'number') {
      setGeneralPromotionInfos({
        ...generalPromotionInfos,
        // [name]: value.replace(/[^0-9]/g, ''),
        [name]: Number(value),
      });
    } else {
      setGeneralPromotionInfos({ ...generalPromotionInfos, [name]: value });
    }
  };
  // Informações do produto
  const handleChangePromotionAnnouncements = (event) => {
    const { name, value, type } = event.target;

    if (type === 'number') {
      setInfosPromotionAnnouncements({
        ...infosPromotionAnnouncements,
        [name]: Number(value.replace(/[^0-9]/g, '')),
        // [name]: Number(value),
      });
    } else {
      setInfosPromotionAnnouncements({
        ...infosPromotionAnnouncements,
        [name]: value,
      });
    }
  };

  const handleChangeCurrency = (event, value) => {
    event.preventDefault();
    setGeneralPromotionInfos({
      ...generalPromotionInfos,
      value,
    });
  };

  const handleChangePercentagePromotionInfos = (event) => {
    // const value = Math.max(1, Math.min(100, Number(event.target.value)));
    const { value } = event.target;
    const validation = value > 100 ? 100 : value < 0 ? 1 : value;
    setPercentage(validation);
    setGeneralPromotionInfos({
      ...generalPromotionInfos,
      value: Number(validation),
    });
  };

  // Functions Get
  const handleLoadCategories = () => {
    if (categories.length === 0) {
      api
        .get(`/category-products-services/establishment/${getId()}`)

        .then((res) => {
          setCategories(res.data);
          setProducts([]);
        })
        .catch((err) => console.log('Erro categories', err));
    }
  };
  const handleLoadProduct = (category) => {
    api
      .get(`/announcements/category/${category}`)
      .then((res) => {
        setProducts(res.data.announcements);
      })
      .catch((error) => {
        console.log('error', error.response);
      });
  };

  // Remove annuncements
  const handleRemoveProduct = (id) => {
    const newList = listProducts.filter((product) => product.id !== id);
    setListProducts(newList);
  };

  // Manipulate data
  const handleSetListProducts = (ev) => {
    ev.preventDefault();
    // Validar se ja existe item na lista
    const updatedProductsSelected = productsSelected.map((product) => {
      return { ...product, ...infosPromotionAnnouncements };
    });
    setListProducts([...listProducts, ...updatedProductsSelected]);

    setProductsSelected([]);
    setProducts([]);
    setCategories([]);
    setInfosPromotionAnnouncements(initialInfosPromotionAnnouncements);
  };

  const validationQuantity = () => {
    if (
      infosPromotionAnnouncements.totalPerUser === '' ||
      infosPromotionAnnouncements.totalPerUser === '0'
    ) {
      alert('Total por usuário não pode ser zero');
    }

    if (
      Number(infosPromotionAnnouncements.totalPerUser) >
      Number(infosPromotionAnnouncements.limit)
    ) {
      alert(
        'A quantidade por usuário não pode ser maior que a quantidade total'
      );
      setInfosPromotionAnnouncements({
        ...infosPromotionAnnouncements,
        totalPerUser: 0,
      });
    }
  };

  const findAnnouncementsInList = (name) => {
    for (let i = 0; i < listProducts.length; i++) {
      if (listProducts[i].name === name) {
        return true;
      }
    }
    return false;
  };

  // Manipulate data and Send form
  const handleSendPromotion = (ev) => {
    ev.preventDefault();
    // setLoading(true);
    let productsToSend = listProducts.map((product) => {
      return {
        id: product.id,
        active: product.active === 'true',
        limit: Number(product.limit),
        totalPerUser: Number(product.totalPerUser),
        onlyNewClients: product.onlyNewClients === 'true',
      };
    });

    let data = {
      ...generalPromotionInfos,
      type: typePromotion,
      promotionAnnouncements: productsToSend,
    };
    // return setLoading(false);

    api
      .post('/promotions', data)
      .then((res) => {
        data = { ...data, id: res.data.id };
        promotions.promotions.push(res.data);
        setLoading(false);
        handleClose();
        Swal.fire({
          title: 'Tudo certo',
          text: 'Promoção cadastrada com sucesso!',
          icon: 'success',
          confirmButtonText: 'Continuar',
        });
      })
      .catch((err) => {
        console.log('erro ao enviar promoção', err);
        setLoading(false);
        handleClose();
        Swal.fire({
          title: 'Algo deu errado',
          text: 'Não foi possível cadastrar a promoção!',
          icon: 'error',
          confirmButtonText: 'Continuar',
        });
      });
  };

  return (
    <div className="container-modal-promotion">
      <div className="modal-promotion">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          <h1>Informações da nova promoção</h1>
          <h1 onClick={handleClose} style={{ cursor: 'pointer' }}>
            X
          </h1>
        </div>
        <div
          style={{
            backgroundColor: '#e8f4ff',
            padding: '12px 16px',
            borderRadius: '8px',
          }}
        >
          <div className="modal-products-container">
            <label>Categoria</label>
            <select
              onChange={(event) => {
                handleLoadProduct(event.target.value);
              }}
              onClick={handleLoadCategories}
              name="category"
              type="text"
            >
              <option value="">Escolha uma categoria</option>
              {categories.length > 0 &&
                categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="modal-products-table">
            <MaterialTable
              elevation={0}
              columns={[
                {
                  title: 'Imagem',
                  field: 'image',
                  render: (rowData) =>
                    rowData.images.length > 0 ? (
                      <img
                        src={rowData.images[0].url}
                        alt="imagem do produto"
                        style={{
                          width: 40,
                          height: 40,
                          borderRadius: '50%',
                          objectFit: 'contain',
                        }}
                      />
                    ) : (
                      <img
                        src={defaultImage}
                        style={{
                          width: 70,
                          height: 70,
                          borderRadius: '50%',
                          objectFit: 'contain',
                        }}
                        alt="Não existe imagem"
                      />
                    ),
                },
                { title: 'Nome', field: 'name' },
                {
                  title: 'Preço',
                  field: 'price',
                  render: (rowData) => formatedCurrencyBr(rowData.price),
                },
                { title: 'Quantidade', field: 'quantity' },
              ]}
              data={products}
              title="Lista de produtos"
              localization={{
                pagination: {
                  labelDisplayedRows: '{from}-{to} de {count}',
                  labelRowsSelect: 'Linhas',
                },
                toolbar: {
                  searchPlaceholder: 'Pesquisar',
                  nRowsSelected: '{0} linha(s) selecionada(s)',
                },
                header: {
                  actions: 'Ações',
                },
                body: {
                  emptyDataSourceMessage: 'Não há registros a serem exibidos',
                },
              }}
              options={{
                actionsColumnIndex: -1,
                paging: false,
                showSelectAllCheckbox: false,
                selection: true,
                selectionProps: (rowData) => ({
                  disabled: findAnnouncementsInList(rowData.name),
                  color: 'primary',
                }),
              }}
              onSelectionChange={(rows) => setProductsSelected(rows)}
              // onSelectionChange={(rows, t) => console.log('a', rows, t)}
            />
          </div>

          <form
            onSubmit={(ev) => handleSetListProducts(ev)}
            style={{ marginTop: '20px' }}
          >
            {productsSelected.length > 0 && (
              <>
                <h4>Informações sobre o produto</h4>
                <div className="modal-product-infos-container">
                  <div>
                    <label>Status</label>
                    <select
                      onChange={handleChangePromotionAnnouncements}
                      name="active"
                      type="text"
                      required
                    >
                      <option value="">Escolha uma opção</option>
                      <option value={true}>Ativa</option>
                      <option value={false}>Inativa</option>
                    </select>
                  </div>
                  <div>
                    <label>Somente novos usuários?</label>
                    <select
                      onChange={handleChangePromotionAnnouncements}
                      name="onlyNewClients"
                      type="text"
                      required
                    >
                      <option value="">Escolha uma opção</option>
                      <option value={true}>Sim</option>
                      <option value={false}>Não</option>
                    </select>
                  </div>
                  <div>
                    <label>Quantidade de anúncio na promoção</label>
                    <input
                      onChange={handleChangePromotionAnnouncements}
                      name="limit"
                      placeholder="quantidade"
                      type="text"
                      value={infosPromotionAnnouncements.limit}
                      // onWheel={(e) => e.target.blur()}
                      required
                    />
                  </div>
                  <div>
                    <label>Total por usuário</label>
                    <input
                      onChange={handleChangePromotionAnnouncements}
                      name="totalPerUser"
                      placeholder="quantidade"
                      type="text"
                      value={infosPromotionAnnouncements.totalPerUser}
                      onBlur={validationQuantity}
                      // onWheel={(e) => e.target.blur()}
                      required
                    />
                  </div>
                </div>
              </>
            )}

            <div style={{ textAlign: 'right', marginTop: '20px' }}>
              <button
                type="submit"
                style={{ marginBottom: '1rem' }}
                className="btn-adc-item"
                disabled={productsSelected.length === 0}
              >
                <span>+</span>Adicionar produtos
              </button>
            </div>
          </form>

          <ul className="container-list-products">
            {listProducts.length > 0 &&
              listProducts.map((product) => (
                <CardPromotionAnnouncements
                  key={product.id}
                  promotion={product}
                  handleRemoveProduct={handleRemoveProduct}
                />
              ))}
          </ul>
        </div>

        <form onSubmit={(ev) => handleSendPromotion(ev)}>
          <div className="modal-products-container">
            <label>Nome da promoção</label>
            <input
              onChange={handleChangeGeneralPromotionInfos}
              name="name"
              placeholder="Qual nome da promoção?"
              type="text"
              required
            />
          </div>

          {typePromotion === '' && (
            <div className="modal-products-container">
              <label>Tipo de desconto: {typePromotion !== '' && 'X'}</label>
              <div className="btns-options-type-promotion">
                <button
                  onClick={() => setTypePromotion('absolute')}
                  className="btn-adc-item"
                >
                  Valor
                </button>
                <button
                  onClick={() => setTypePromotion('percentage')}
                  className="btn-adc-item"
                >
                  Porcentagem
                </button>
              </div>
            </div>
          )}
          {typePromotion !== '' && (
            <div className="modal-products-container">
              <label>
                Valor do desconto:
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => setTypePromotion('')}
                >
                  {' '}
                  {typePromotion !== '' && (
                    <strong style={{ color: 'var(--red)' }}>
                      Trocar opção
                    </strong>
                  )}
                </span>
              </label>
              {typePromotion === 'percentage' ? (
                <input
                  name="value"
                  placeholder="Qual o valor em porcentagem?"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  onChange={handleChangePercentagePromotionInfos}
                  value={percentage}
                  required
                />
              ) : (
                <IntlCurrencyInput
                  currency="BRL"
                  config={currencyConfig}
                  onChange={handleChangeCurrency}
                />
              )}
            </div>
          )}

          <div style={{ textAlign: 'right', marginTop: '20px' }}>
            <button
              type="submit"
              style={{ marginBottom: '1rem' }}
              className="btn-adc-item"
              disabled={Object.keys(generalPromotionInfos).length < 2}
            >
              <span>+</span>Adicionar promoção
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContentModalPromotion;
