import { React} from 'react'
import 'react-toastify/dist/ReactToastify.css';


import LogoBranca from '../../assets/logo-branca.png'
import { history } from '../../history'

import { Formik } from 'formik'

import '../../pages/Cadastro/styles.css'

import api from '../../utils/services'

// import api from '../../utils/services'

const CadastroDocumentos = ({ step, setStep }) => {


  async function onSubmit(values, actions) {

    let data = new FormData();

    data.append('frontIdentity', values.frontIdentity)
    data.append('backIdentity', values.backIdentity)
    data.append('selfie', values.selfie)

   /* for (let pair of data.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
    } */

    api.post('/bank-accounts/documents', data)
      .then((res) => {
        history.push('/dashboard')
      }).catch(error => {
        alert('Algo deu errado, tente novamente')
        console.log('Erro', error)
      }) 
  }



  return (
    <div className="container">
      <div className="content">
        <img style={{ marginTop: 30 }} id="logo" onClick={() => history.goBack()} src={LogoBranca} alt="" />

        <Formik
          onSubmit={onSubmit}
          initialValues={{
            frontIdentity: "",
            backIdentity: "",
            selfie: "",
          }
          }
          render={({ values, handleSubmit, handleChange, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="box-form">

              <div className="box">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                  <h1>Agora, precisamos comprovar seus dados :)</h1>
                  <h4 style={{ color: '#38a2e9' }} >Etapa 4 de 4</h4>
                </div>
                <p>Para poder abrir sua conta na Logo Ali e ter todos os benefícios de realizar vendas no app, precisamos comprovar sua pessoa</p>

                <h3 style={{ marginTop: 30, color: 'var(--primary-cor)' }} >Envie uma foto da frente do seu RG e uma foto do verso da sua RG</h3>

                <div className="input-column" >

                  <div className="input-container">
                    <label>Frente da RG*</label>

                    <input 
                      type="file"
                      // style={{ width: '100%', }}
                      required
                      id="frontIdentity"
                      name="frontIdentity"
                      onChange={(ev) => setFieldValue('frontIdentity', ev.target.files[0])}
                     // onChange={(ev) => onChangeImg(ev, setFieldValue)}
                    />
                    <span style={values.frontIdentity === '' ? {borderBottom: '7px solid red'} : {borderBottom: '7px solid var(--green)'}} ></span>
                  </div>

                  <div className="input-container">
                    <label>Verso RG*</label>

                    <input 
                      required
                      type="file"
                      id="backIdentity "
                      name="backIdentity "
                      onChange={(ev) => setFieldValue('backIdentity', ev.target.files[0])}
                    />
                    <span style={values.backIdentity === '' ? {borderBottom: '7px solid red'} : {borderBottom: '7px solid var(--green)'}} ></span>
                  </div>

                </div>

                <h3 style={{ marginTop: 60, color: 'var(--primary-cor)' }}>Agora envie uma self sua</h3>

                <div className="input-column" >
                  <div className="input-container">
                    <label>Self mostrando seu rosto*</label>

                    <input 
                      required
                      type="file"
                      id="selfie"
                      name="selfie"
                      onChange={(ev) => setFieldValue('selfie', ev.target.files[0])}
                     // onChange={(ev) => onChangeImg(ev, setFieldValue)}
                    />
                    <span style={values.selfie === '' ? {borderBottom: '7px solid red'} : {borderBottom: '7px solid var(--green)'}} ></span>
                  </div>
                </div>


                {/* <button type="submit" className="btn-submit">Criar Conta</button> */}
                <button type="button" onClick={() => onSubmit(values)} className="btn-submit">Cadastrar</button>
                <button type="button" onClick={() => console.log(values)} >Test</button>
              </div>
              
            </form>
          )}
        />
        
      </div>
    </div>
  )
}

export default CadastroDocumentos
