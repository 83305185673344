import axios from 'axios';
import { getId, getLogged, getTokenFirebase } from './auth';
import { env } from '../env';
import { requestForToken } from './firebase';

// dotenv.config();
// const apiUrl = process.env.REACT_APP_API_URL;

if (!getId() && getLogged() === 'true') {
  const token = localStorage.getItem('token')?.slice(1, -1);
  axios
    .get('/establishments/self', {
      baseURL: env().REACT_APP_API_URL,
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => localStorage.setItem('id', res.data.id))
    .catch((_) => alert('Faça o login novamente!'));
}

const api = axios.create({
  baseURL: env().REACT_APP_API_URL,
});
api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('token')?.slice(1, -1);
  const tokenFirebase = getTokenFirebase();

  if (token && !tokenFirebase) {
    try {
      const newTokenFirebase = await requestForToken();
      await api.post('/devices', { deviceId: newTokenFirebase });
    } catch (error) {
      console.error('Erro ao obter token do Firebase:', error);
    }
  }

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
export default api;
