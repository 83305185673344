import { useState } from 'react';
import cancelarIcon from '../../../../assets/x.png';
import obsIcon from '../../../../assets/escramacao.png';
import finalizarIcon from '../../../../assets/v.png';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import api from '../../../../utils/services';

function getModalStyle() {
  const top = 50;
  const left = 49;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paperModal: {
    position: 'absolute',
    width: '90%',
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    // borderRadius: 10,
    boxShadow: theme.shadows[5],
    overflow: 'auto',
  },
  paddingModal: {
    padding: theme.spacing(2, 4, 3),
  },
  headerModal: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4, 3),
    backgroundColor: 'var(--red)',
  },
  flex: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  tagRed: {
    backgroundColor: 'var(--red)',
    color: '#FFF !important',
    padding: 4,
    borderRadius: '2px',
    fontSize: '16px',
    fontWeight: '700',
    width: '150px',
    textAlign: 'center',
  },
  tagOrange: {
    backgroundColor: 'var(--orange)',
    color: '#FFF !important',
    padding: 4,
    borderRadius: '2px',
    fontSize: '16px',
    fontWeight: '700',
    width: '250px',
    textAlign: 'center',
  },
  textTag: {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: '700',
  },
  input: {
    padding: 4,
    border: 'none',
    outline: 'none',
    borderBottom: '1px solid #333',
  },
}));

const handleSubmit = (data, status) => {
  //let listStatus = ["prepared", "inDelivery", "delivered"];
  //let indexStatus =  listStatus.findIndex(item => item === "delivered")

  api
    .patch(`sales/update-status/${data.id}`, {
      status: status,
    })
    .then((_) => {
      Swal.fire('Status do pedido atualizado com sucesso!').then((_) =>
        window.location.reload()
      );
    })
    .catch((err) => console.log(err));
};

const handleConfirmationAction = (data) => {
  let status = '';
  let legenda = '';
  switch (data.status) {
    case 'pending':
      status = 'prepared';
      legenda = 'Deseja enviar o pedido para a preparação?';
      break;
    case 'prepared':
      status = 'inDelivery';
      legenda = 'Deseja enviar o pedido para o seu cliente';
      break;
    case 'inDelivery':
      status = 'delivered';
      legenda = 'Deseja finalizar o pedido como entregue?';
      break;
    default:
      alert('Não podemos concluir essa operação');
  }

  Swal.fire({
    title: `${legenda}`,
    showDenyButton: true,
    confirmButtonText: 'Sim',
    confirmButtonColor: '#3085d6',
    denyButtonColor: '#d33',
    denyButtonText: `Não`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      handleSubmit(data, status);
    } else if (result.isDenied) {
      Swal.fire('Ok! o pedido não foi para preparação', '', 'warning');
    }
  });
};

const FeedbackModal = ({ open, handleClose, data, handleActionSale }) => {
  const [modalStyle] = useState(getModalStyle);

  const [totalPrince, setTotalPrince] = useState(0);
  const [princes, setPrinces] = useState([]);
  //const [dataTemp, setDataTempo] = useState({item: "", prince: 0.0})

  const classes = useStyles();

  useEffect(() => {
    let value = 0.0;
    let dataTemp = [];
    data.items.map((item) => {
      value = value + parseFloat(item.value * item.quantity);
      dataTemp.push({
        name: item.nome,
        amount: item.quantity,
        prince: item.value,
        total: parseFloat(item.value * item.quantity),
      });
    });
    setTotalPrince(value.toFixed(2));
    setPrinces(dataTemp);
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paperModal}>
        <div className={classes.headerModal}>
          <h2 style={{ color: 'var(--white)' }}>ATENÇÃO - ÁREA DE DEVOLUÇÃO</h2>
          <button onClick={handleClose} className="btn-closed-modal">
            X
          </button>
        </div>

        <div className={classes.paddingModal}>
          <div className="first-details-pedido-container">
            <div className="details-pedido-content">
              <h3 className={classes.tagRed}>PEDIDO nº {data.orderNumber}</h3>
              <h4 style={{ fontSize: '18px' }}>
                Se o estabelecimento não buscar o produto em ate 2 dias úteis
                será cancelada a compra e o valor estornado para o cliente e do
                empreendedor será cobrado a taxa de 6% + 99 centavos.
              </h4>
              {/* <h4>Data: {data.hours}</h4> */}
            </div>

            <div className="status-pedido">
              <span>{data.status}</span>
            </div>
          </div>

          <div className="first-details-pedido-container">
            <div
              style={{ marginTop: '20px' }}
              className="details-pedido-content"
            >
              <h3 className={classes.tagOrange}>RETIRADA COM O CLIENTE</h3>
              <h4 style={{ fontSize: '18px' }}>
                Como o pedido foi efetuado por delivery, a busca do pedido
                também será por delivery. Informe os dados corretamento a
                seguir.
              </h4>
            </div>
          </div>

          <div className="footer-modal">
            <div className="details-pedido-container">
              <div className="details-pedido-content">
                <h3>Informações sobre a busca do pedido</h3>

                <div className={classes.flex}>
                  <h4>Dia: </h4>
                  <input placeholder="Insira o dia" className={classes.input} />
                </div>
                <div className={classes.flex}>
                  <h4>Horário: </h4>
                  <input
                    placeholder="Insira o horário"
                    className={classes.input}
                  />
                </div>
                <div className={classes.flex}>
                  <h4>Observação: </h4>
                  <input
                    placeholder="Insira alguma observação"
                    className={classes.input}
                  />
                </div>
              </div>
            </div>

            <div className="btn-pedidos">
              <button
                onClick={handleClose}
                style={{ backgroundColor: 'var(--red)' }}
              >
                Cancelar
                <img src={cancelarIcon} alt="Cancelar" />
              </button>
              <button
                onClick={() => {
                  //handleActionSale(1);
                  //handleClose();
                  handleConfirmationAction(data);
                }}
                style={{ backgroundColor: 'var(--primary-cor)' }}
              >
                Começar
                <img src={finalizarIcon} alt="Cancelar" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
