export default function CashPlusSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="14"
      viewBox="0 0 20 14"
      {...props}
      >
       <path d="M12 9V11H15V14H17V11H20V9H17V6H15V9M11.97 5.61C11.85 4.28 10.59 2.97 9 3C7.3 3.03 6 4.3 6 6C6 7.7 7.3 8.94 9 9C9.38 9 9.77 8.92 10.14 8.77C10.41 7.67 10.86 6.63 11.97 5.61ZM10 10H4C4 9.46957 3.78929 8.96086 3.41421 8.58579C3.03914 8.21071 2.53043 8 2 8V4C3.11 4 4 3.11 4 2H14C14 2.53043 14.2107 3.03914 14.5858 3.41421C14.9609 3.78929 15.4696 4 16 4V4.06C16.67 4.06 17.34 4.18 18 4.4V0H0V12H10.32C10.1075 11.3546 9.99949 10.6795 10 10Z"  />
    </svg>
  )
}