import { React, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import LogoBranca from '../../assets/logo-branca.png';
import { history } from '../../history';

import { Formik, Field } from 'formik';

import './styles.css';
import { Box, Modal } from '@material-ui/core';
import Swal from 'sweetalert2';
import api from '../../utils/services';
import { env } from '../../env';

const styleModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CadastroEndereco = () => {
  const [inputDisabled, setInputDisabled] = useState(true);

  // Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onBlurCep = async (event, setFieldValue) => {
    const { value } = event.target;

    const cep = value.replace(/[^0-9]/g, '');

    if (cep?.length !== 8 || cep === '') {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Preencha o campo corretamente!',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }

    // await fetch(`http://viacep.com.br/ws/${cep}/json`)
    await fetch(
      `${env().REACT_APP_API_URL}/addresses/verify-location-cep/${cep}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          return Swal.fire({
            title: 'Atenção!',
            text: 'o CEP não é valido, tente novamente!',
            icon: 'warning',
            confirmButtonText: 'OK',
          });
        }
        // setFieldValue('city', data.localidade)
        // setFieldValue('state', data.uf)
        setFieldValue('city', data.city);
        setFieldValue('state', data.uf);
        setInputDisabled(false);
      });
  };

  async function onSubmit(values, actions) {
    const getToken = localStorage.getItem('token');
    const token = getToken.slice(1, -1);

    let data = values;
    data.number = data.number.toString();

    await api
      .post('/addresses', data)
      .then((_) => {
        // alert('Bora para próxima etapa :)')
        Swal.fire({
          title: 'Sucesso!',
          text: 'Endereço cadastrado, bora para próxima etapa',
          icon: 'success',
          confirmButtonText: 'Prosseguir',
        });
        history.push('/cadastro-bancario');
        // history.push('/dashboard')
      })
      .catch((response) => {
        Swal.fire({
          title: 'Erro!',
          text: 'Algo deu errado, tente novamente!',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        console.log(response.message);
      });
  }

  return (
    <>
      <div className="container">
        <div className="content">
          <img
            id="logo"
            onClick={() => history.goBack()}
            src={LogoBranca}
            alt=""
          />

          <Formik
            onSubmit={onSubmit}
            initialValues={{
              state: '',
              city: '',
              street: '',
              zipCode: '',
              number: 0,
              default: true,
              complement: '',
              neighborhood: '',
            }}
            render={({ values, handleSubmit, handleChange, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="box-form">
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className="modalConfimation" sx={styleModal}>
                    <div>
                      <h2>Confirme seus dados</h2>

                      <div className="modalConfirmationContent">
                        <h3>Endereço do seu empreendimento</h3>
                        <ul>
                          <li>
                            CEP: <span>{values.zipCode}</span>
                          </li>
                          <li>
                            Endereço: <span>{values.street}</span>
                          </li>
                          <li>
                            Número: <span>{values.number}</span>
                          </li>
                          <li>
                            Complemento: <span>{values.complement}</span>
                          </li>
                          <li>
                            Bairro: <span>{values.neighborhood}</span>
                          </li>
                          <li>
                            Munícipio: <span>{values.city}</span>
                          </li>
                          <li>
                            Estado: <span>{values.state}</span>
                          </li>
                          {/* <li>Senha: <span>{values.name}</span></li> */}
                        </ul>
                      </div>

                      <div id="btn-modal-confirmation">
                        <button
                          onClick={handleClose}
                          style={{
                            marginRight: 10,
                            backgroundColor: 'var(--red)',
                          }}
                        >
                          Cancelar
                        </button>
                        <button
                          onClick={() => {
                            handleClose();
                            onSubmit(values);
                          }}
                          style={{ backgroundColor: 'var(--primary-cor)' }}
                        >
                          Confirmo
                        </button>
                      </div>
                    </div>
                  </Box>
                </Modal>

                <div className="box">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h1>Informe o endereço do seu empreendimento</h1>
                    <h4 style={{ color: '#38a2e9' }}>Etapa 2 de 4</h4>
                  </div>
                  <div className="input-column">
                    <div className="input-container">
                      <label>
                        CEP* <span>(apenas números)</span>
                      </label>
                      <Field
                        required
                        onBlur={(ev) => onBlurCep(ev, setFieldValue)}
                        maxLength={8}
                        className="c2"
                        type="text"
                        name="zipCode"
                      />
                    </div>
                  </div>

                  <div className="input-column">
                    <div className="input-container">
                      <label>Endereço*</label>
                      <Field
                        required
                        disabled={inputDisabled}
                        className="c1"
                        type="text"
                        name="street"
                      />
                    </div>

                    <div className="input-row">
                      <div className="input-container">
                        <label>Número*</label>
                        <Field
                          required
                          disabled={inputDisabled}
                          type="number"
                          className="c2"
                          name="number"
                        />
                      </div>

                      <div className="input-container">
                        <label>Complemento</label>
                        <Field
                          required
                          disabled={inputDisabled}
                          type="text"
                          className="c2"
                          name="complement"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="input-column">
                    <div className="input-container">
                      <label>Bairro*</label>
                      <Field
                        required
                        disabled={inputDisabled}
                        type="text"
                        className="c1"
                        name="neighborhood"
                      />
                    </div>

                    <div className="input-row">
                      <div className="input-container">
                        <label>Munícipio*</label>
                        <Field
                          required
                          disabled={true}
                          type="text"
                          className="c2"
                          name="city"
                        />
                      </div>
                      <div className="input-container">
                        <label>UF*</label>
                        <Field
                          required
                          disabled={true}
                          maxlength="2"
                          type="text"
                          className="c2"
                          name="state"
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={() => handleOpen()}
                    className="btn-submit"
                  >
                    Criar Conta
                  </button>
                </div>
              </form>
            )}
          />
          {/* <button onClick={() => handleOpen()}>Próximo</button> */}
        </div>
      </div>
    </>
  );
};

export default CadastroEndereco;
