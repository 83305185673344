export const AddPromotionModal = ({ products, handleClose }) => {
  return (
    <div className="container-modal-promotion">
      <div className="modal-promotion">
        <h1>Nova promoção</h1>

        <div className="container-image">
          {products.length > 0 &&
            products.map((product) => {
              return (
                <div className="content-image">
                  <img
                    key={product.id}
                    src={product.images[0].url}
                    alt={product.name}
                  />
                  <span>{product.name}</span>
                </div>
              );
            })}
        </div>

        <div className="modal-products-container">
          <h4>Porcentagem</h4>
          <input
            name="percentage"
            placeholder="Quantos porcento esse produto vai ter de desconto?"
            type="number"
            onWheel={(e) => e.target.blur()}
          />
        </div>

        <div className="modal-products-container">
          <h4>Status</h4>
          <select name="active" type="text">
            <option>Escolha uma opção</option>
            <option value={true}>Ativa</option>
            <option value={false}>Inativa</option>
          </select>
        </div>

        <div className="modal-products-container">
          <h4>Essa promoção é somente para novos usuários?</h4>
          <select name="active" type="text">
            <option>Escolha uma opção</option>
            <option value={true}>Sim</option>
            <option value={false}>Não</option>
          </select>
        </div>
      </div>
    </div>
  );
};
