import './styles.css'

export default function TopSellingProducts({bestSellingProductsList}){


  const formatCurrency = (number) => {
    const roundedNumber = parseFloat(number).toFixed(2);
    const formattedNumber = roundedNumber.toString().replace(".", ",");
    return `R$ ${formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  }

  return(
    <div className='top-selling-products-container'>
      <h4>Lista de produtos mais vendidos</h4>
      <table className='table-top-sellings'>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Preço</th>
            <th>Quantidade</th>
          </tr>
        </thead>
        <tbody>

          {
          bestSellingProductsList.length > 0 &&
          bestSellingProductsList.map((product, index) => {
            return(
              <tr key={index} >
                <td> {product.name}</td>
                <td> {formatCurrency(product.price)}</td>
                <td> {product.quantity}</td>
              </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
  )
}