import { useEffect, useState } from 'react';
import api from '../../../../utils/services';
import MaterialTable from 'material-table';
import { Modal } from '@material-ui/core';
import ContentModalAddAnnouncements from './ContentModalAddAnnouncements';
import IntlCurrencyInput from 'react-intl-currency-input';
import formatedCurrencyBr from '../../../../common/formatCurrency';
import Swal from 'sweetalert2';

const currencyConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const ViewPromotionModal = ({
  handleClose,
  promotion,
  setLoading,
  loadPromotions,
}) => {
  const [announcements, setAnnouncements] = useState([]);

  const [generalPromotionInfos, setGeneralPromotionInfos] = useState({
    name: promotion.name,
    value: promotion.value,
    type: promotion.type,
  });
  const [typePromotion, setTypePromotion] = useState('');
  const [percentage, setPercentage] = useState(0);

  const [isEdit, setIsEdit] = useState(false);
  const [hasChange, setHasChanges] = useState(false);

  const handleStatus = (promotion) => {
    switch (promotion) {
      case true:
        return 'Ativo';
      case false:
        return 'Inativo';
      case 'true':
        return 'Ativo';
      case 'false':
        return 'Inativo';
      default:
        return 'N/A';
    }
  };

  const [columns] = useState([
    { title: 'Name', field: 'name', editable: 'never' },
    {
      title: 'Status',
      field: 'active',
      render: (rowData) =>
        String(rowData.active) === 'true' ? 'Ativo' : 'Inativo',
      lookup: { true: 'Ativo', false: 'Inativo' },
      type: 'string',
    },
    { title: 'Total de promoções', field: 'limit', type: 'numeric' },
    {
      title: 'Promoções já resgatada',
      field: 'totalUsed',
      type: 'numeric',
      editable: 'never',
    },
    { title: 'Cupom por usuário', field: 'totalPerUser', type: 'numeric' },
    {
      title: 'Valida somente para novos usuários',
      field: 'onlyNewClients',
      render: (rowData) =>
        String(rowData.onlyNewClients) === 'true' ? 'Sim' : 'Não',
      type: 'boolean',

      lookup: { true: 'Sim', false: 'Não' },
    },
  ]);

  const [openModalAddAnnouncement, setOpenModalAddAnnouncement] =
    useState(false);

  const handleOpenModalAddAnnouncement = () =>
    setOpenModalAddAnnouncement(true);
  const handleCloseModalAddAnnouncement = () =>
    setOpenModalAddAnnouncement(false);

  const loadAnnouncements = () => {
    api.get(`/promotions/${promotion.id}/announcements`).then((response) => {
      setAnnouncements(response.data.announcements);
    });
  };

  const removeAnnouncements = (data) => {
    setLoading(true);
    api
      .delete(`/promotions/${promotion.id}/announcements/${data.id}`)
      .then(() => {
        const dataDelete = [...announcements];
        const index = data.tableData.id;
        dataDelete.splice(index, 1);
        setAnnouncements([...dataDelete]);
        setLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          title: 'Erro',
          text: 'Aconteceu algum erro, tente novamente',
          icon: 'error',
        });
        console.log('erro ao deletar anúncio', err);
        setLoading(false);
      });
  };

  const handleChangeGeneralPromotionInfos = (event) => {
    const { name, value, type } = event.target;
    if (type === 'number') {
      setGeneralPromotionInfos({
        ...generalPromotionInfos,
        [name]: Number(value),
      });
    } else {
      setGeneralPromotionInfos({ ...generalPromotionInfos, [name]: value });
    }
  };

  const handleChangePercentagePromotionInfos = (event) => {
    // const value = Math.max(1, Math.min(100, Number(event.target.value)));
    const { value } = event.target;
    const validation = value > 100 ? 100 : value < 0 ? 1 : value;
    setPercentage(validation);
    setGeneralPromotionInfos({
      ...generalPromotionInfos,
      value: Number(validation),
    });
  };

  const handleChangeCurrency = (event, value) => {
    event.preventDefault();
    setGeneralPromotionInfos({
      ...generalPromotionInfos,
      value,
    });
  };

  const handleCancelEditPromotion = (e) => {
    e.preventDefault();
    setIsEdit(!isEdit);

    setGeneralPromotionInfos({
      value: promotion.value,
      name: promotion.name,
      type: promotion.type,
    });
  };

  const handleEditPromotion = (e) => {
    e.preventDefault();
    setIsEdit(!isEdit);
  };

  function removerUndefined(obj) {
    const keys = Object.keys(obj);
    for (const key of keys) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  }

  const handleSendPromotion = (ev) => {
    ev.preventDefault();
    setLoading(true);
    setHasChanges((prevState) => !prevState);

    let productsToSend = announcements.map((product) => {
      return {
        name: product.name,
        id: product.id,
        active:
          product.active === 'true' || product.active === true ? true : false,
        limit: product.limit,
        totalPerUser: product.totalPerUser,
        onlyNewClients:
          product.onlyNewClients === 'true' || product.onlyNewClients === true
            ? true
            : false,
      };
    });

    // Tirar validação, pois o valor ta sendo passado por default
    let data = {
      name:
        generalPromotionInfos.name === ''
          ? promotion.name
          : generalPromotionInfos.name,
      value:
        generalPromotionInfos.value === ''
          ? promotion.value
          : generalPromotionInfos.value,
      type: typePromotion === '' ? promotion.type : typePromotion,
      promotionAnnouncements: productsToSend,
    };
    data = removerUndefined(data);

    api
      .patch(`/promotions/${promotion.id}`, data)
      .then((res) => {
        loadPromotions();
        setLoading(false);
        Swal.fire({
          title: 'Tudo certo',
          text: 'Promoção atualizada com sucesso!',
          icon: 'success',
          confirmButtonText: 'Continuar',
        }).then(() => {
          handleClose();
        });
      })

      .catch((err) => {
        setLoading(false);
        console.log('erro ao enviar promoção', err);
      });
  };

  const handleEditRowPromotion = (dataUpdate) => {
    // setLoading(true);
    let data = {
      promotionAnnouncements: dataUpdate,
    };

    api
      .patch(`/promotions/${promotion.id}`, data)
      .then((_) => {
        Swal.fire({
          title: 'Tudo certo',
          text: 'Anúncio atualizado com sucesso!',
          icon: 'success',
          confirmButtonText: 'Continuar',
        });
        loadPromotions();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: 'Ocorreu um erro',
          text: 'Não foi possivel editar o anúncio, tente novamente mais tarde.',
          icon: 'error',
          confirmButtonText: 'Tentar novamente',
        });
        console.log('erro ao enviar promoção', err);
      });
  };

  useEffect(() => {
    loadAnnouncements();
  }, []);

  const validationOnClose = () => {
    if (hasChange) {
      Swal.fire({
        title: 'Atenção',
        text: 'Você tem alterações não salvas, deseja sair mesmo assim?',
        icon: 'warning',
        showCancelButton: true,

        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        confirmButtonColor: 'var(--description)',
        cancelButtonColor: 'var(--primary-cor)',
      }).then((result) => {
        if (result.isConfirmed) {
          handleClose();
        }
      });
    } else {
      handleClose();
    }
  };

  return (
    <div className="container-modal-promotion">
      <div className="modal-promotion">
        <Modal
          open={openModalAddAnnouncement}
          onClose={handleCloseModalAddAnnouncement}
        >
          <ContentModalAddAnnouncements
            handleClose={handleCloseModalAddAnnouncement}
            announcements={announcements}
            setAnnouncements={setAnnouncements}
            setHasChanges={setHasChanges}
          />
        </Modal>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
            marginBottom: '1rem',
          }}
        >
          <h2>Promoção: {promotion.name}</h2>
          <h2 style={{ cursor: 'pointer' }} onClick={validationOnClose}>
            X
          </h2>
        </div>

        <div
          style={{
            backgroundColor: '#e8f4ff',
            padding: '12px 16px',
            borderRadius: '8px',
          }}
        >
          <MaterialTable
            title=""
            columns={columns}
            data={announcements}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  const dataUpdate = [...announcements];
                  const index = oldData.tableData.id;

                  dataUpdate[index] = {
                    id: newData.id,
                    active:
                      newData.active === 'true' || newData.active === true
                        ? true
                        : false,
                    onlyNewClients:
                      newData.onlyNewClients === 'true' ||
                      newData.onlyNewClients === true
                        ? true
                        : false,
                    limit: newData.limit,
                    totalPerUser: newData.totalPerUser,
                  };
                  handleEditRowPromotion(dataUpdate);
                  setAnnouncements([...dataUpdate]);
                  resolve();
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  removeAnnouncements(oldData);
                  resolve();
                }),
            }}
            options={{
              actionsColumnIndex: -1,
              paging: false,
              search: false,
            }}
            localization={{
              header: {
                actions: 'Ações',
              },
              body: {
                editRow: {
                  deleteText: 'Tem certeza que deseja deletar esse anúncio?',
                  cancelTooltip: 'Cancelar',
                  saveTooltip: 'Salvar',
                },
                editTooltip: 'Editar',
                deleteTooltip: 'Deletar',
              },
            }}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <button
              onClick={handleOpenModalAddAnnouncement}
              className="btn-adc-item"
            >
              <span>+</span>Adicionar anúncio na promoção
            </button>
          </div>
        </div>

        <form
          style={{
            backgroundColor: '#e8f4ff',
            marginTop: '40px',
            padding: '12px 16px',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: '1rem',
            }}
          >
            <h2>Informações da promoção</h2>
            <div style={{ display: 'flex', gap: '1rem' }}>
              {isEdit && (
                <button
                  onClick={handleCancelEditPromotion}
                  style={{
                    background: 'var(--red)',
                  }}
                  className="btn-adc-item"
                >
                  Cancelar
                </button>
              )}
              {!isEdit && (
                <button
                  onClick={handleEditPromotion}
                  style={{
                    background: 'var(--orange)',
                  }}
                  className="btn-adc-item"
                >
                  Editar Informações da promoção
                </button>
              )}

              {isEdit && (
                <button
                  type="button"
                  onClick={() => {
                    setIsEdit(false);
                  }}
                  className="btn-adc-item"
                  disabled={!isEdit}
                >
                  Salvar informações
                </button>
              )}
            </div>
          </div>
          <div className="modal-products-container">
            <label>Nome da promoção</label>
            <input
              onChange={handleChangeGeneralPromotionInfos}
              name="name"
              placeholder="Qual nome da promoção?"
              onBlur={() => setHasChanges(true)}
              type="text"
              defaultValue={promotion.name}
              disabled={!isEdit}
              required
            />
          </div>

          <div className="modal-products-container">
            <label>Valor da promoção </label>
            <input
              onChange={handleChangeGeneralPromotionInfos}
              name="name"
              type="text"
              value={
                generalPromotionInfos.type === 'percentage'
                  ? `${generalPromotionInfos.value} %`
                  : formatedCurrencyBr(generalPromotionInfos.value)
              }
              disabled
              required
            />
          </div>

          {isEdit && (
            <>
              <div className="modal-products-container">
                <label>
                  Editar tipo de desconto:{' '}
                  {typePromotion && (
                    <span
                      onClick={() => {
                        setTypePromotion('');
                        setGeneralPromotionInfos({
                          ...generalPromotionInfos,
                          type: promotion.type,
                        });
                      }}
                      style={{ cursor: 'pointer' }}
                      o
                    >
                      <strong style={{ color: 'var(--red)' }}>
                        Trocar opção
                      </strong>
                    </span>
                  )}
                </label>
                {typePromotion === '' && (
                  <div className="btns-options-type-promotion">
                    <button
                      onClick={() => {
                        setTypePromotion('absolute');
                        setGeneralPromotionInfos({
                          ...generalPromotionInfos,
                          type: 'absolute',
                        });
                      }}
                      className="btn-adc-item"
                      type="button"
                    >
                      Valor
                    </button>
                    <button
                      onClick={() => {
                        setTypePromotion('percentage');
                        setGeneralPromotionInfos({
                          ...generalPromotionInfos,
                          type: 'percentage',
                        });
                      }}
                      className="btn-adc-item"
                      type="button"
                    >
                      Porcentagem
                    </button>
                  </div>
                )}
              </div>

              <div className="modal-products-container">
                {typePromotion === 'percentage' && (
                  <>
                    <label>Nova Porcentagem:</label>
                    <input
                      name="value"
                      placeholder="Qual o valor em porcentagem?"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      onChange={handleChangePercentagePromotionInfos}
                      onBlur={() => setHasChanges(true)}
                      value={percentage}
                      required
                    />
                  </>
                )}

                {typePromotion === 'absolute' && (
                  <>
                    <label>Novo Valor:</label>
                    <IntlCurrencyInput
                      currency="BRL"
                      config={currencyConfig}
                      onChange={handleChangeCurrency}
                      onBlur={() => setHasChanges(true)}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </form>

        <div style={{ textAlign: 'right', marginTop: '20px' }}>
          <button
            onClick={handleSendPromotion}
            style={{ marginBottom: '1rem' }}
            className="btn-adc-item"
            disabled={isEdit}
          >
            Salvar mudanças
          </button>
        </div>
      </div>
    </div>
  );
};

export default ViewPromotionModal;
