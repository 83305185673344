import DeleteIcon from '@material-ui/icons/Delete';

const CardPromotionAnnouncements = ({ promotion, handleRemoveProduct }) => {
  return (
    <li key={promotion.name} className="product-promotions-card-container">
      <div className="btn-remove-product-promotion">
        <DeleteIcon
          onClick={() => handleRemoveProduct(promotion.id)}
          fontSize="medium"
        />
      </div>
      {promotion?.images.length > 0 && (
        <img src={promotion.images[0].url} alt={promotion.name} />
      )}
      <div className="product-promotions-card-infos">
        <h4>{promotion.name}</h4>
        <ul>
          <li>
            <span>Status:</span>{' '}
            {promotion?.active === 'true' ? 'ativo' : 'inativo'}
          </li>
          <li>
            <span>Somente novos usuários:</span>{' '}
            {promotion?.onlyNewClients === 'true' ? 'Sim' : 'Não'}
          </li>
          <li>
            <span>Quantidade de cupons:</span> {promotion?.limit}
          </li>
          <li>
            <span>Total por usuário:</span> {promotion?.totalPerUser}
          </li>
        </ul>
      </div>
    </li>
  );
};

export default CardPromotionAnnouncements;
