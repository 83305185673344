import { useState } from "react";
import { makeStyles, Modal } from "@material-ui/core";

import './styles.css'
import api from "../../../../utils/services";
import Swal from "sweetalert2";

function getModalStyle() {
  const top = 50;
  const left = 49;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({

  paperModal: {
    position: 'absolute',
    width: '90%',
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    // borderRadius: 10,
    boxShadow: theme.shadows[5],
    overflow: 'auto',
  },
  paddingModal:{
    padding: theme.spacing(2, 4, 3),
  },
  headerModal:{
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4, 3),
    backgroundColor: 'var(--red)'
  }


}));


const ModalCancel = ({open, handleCloseModalCancel, handleCloseContetModal, id, salesTable, setSalesTable}) =>{
  
  const [modalStyle] = useState(getModalStyle);
  const [reason, setReason] = useState("")

  const classes = useStyles();

  const handleCancel = async () =>{
    Swal.fire({
      title: "Tem certeza que deseja cancelar esse pedido?",
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: "#3085d6",
      denyButtonColor: "#d33",
      denyButtonText: `Não`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
          api.patch(`/sales/cancel/establishment/${id}`, {
            reason: reason
          }).then( _ =>{
           Swal.fire('Ok! o pedido foi cancelado', '', 'success')
           
           let newArray = salesTable;
           newArray.map((item, index) => {
             if (item.id === id) {
              newArray.splice(index, 1)
              setSalesTable(newArray)
              
             }
           })

           handleCloseModalCancel();
           handleCloseContetModal();

          }).catch( _=>{
           Swal.fire('Algo deu errado, tente novamente', '', 'error')
          })

      } else if (result.isDenied) {
        Swal.fire('Ok! o pedido não foi cancelado', '', 'warning')
        handleCloseModalCancel();
        handleCloseContetModal();
      }
    })
    
  }

  return(
    <Modal
    open={open}
    onClose={handleCloseModalCancel}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    >
    <div style={modalStyle} className={classes.paperModal}>
    <div className={classes.headerModal}>
          {/* <h2 style={{color: 'var(--white)'}} >PEDIDO: {data.orderNumber}</h2> */}
        <h2 style={{color: 'var(--white)'}} >CANCELAR PEDIDO:</h2>
        <button onClick={handleCloseModalCancel} className="btn-closed-modal" >
            X
        </button>
      </div>
      <div className="container-modal-cancel" >
        <h3>Indique o motivo do cancelamento</h3>
        <input 
          type="text" 
          placeholder="Motivo"
          onChange={ (ev) => setReason(ev.target.value)}
          />
        <div className="modal-cancel-actions" >
          <button onClick={() => handleCancel()} >Cancelar produto</button>
          <button onClick={handleCloseModalCancel} style={{background: "var(--red)", color: "#FFF"}} >Não cancelar</button> 
        </div>
      </div>
    </div>
  </Modal>
  )
}

export default ModalCancel;