import { forwardRef, useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Slide
} from '@material-ui/core'

import './modal.css'

import ImagemModal from '../../assets/important.png'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = ({ onSubmit = () => { }, onClose = () => { }, Title, SubTitle }) => {

  const [open, setOpen] = useState(true);

  /*
    const handleClose = () => {
      setOpen(false);
    };
  */

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className='container-modal'>
          <img src={ImagemModal} alt="" />
          <div className='content-modal'>
            <h2>{Title}</h2>
            {/* <p>{SubTitle}</p> */}
          </div >
          <DialogActions>
            <Button id="btn-primary-modal" onClick={onClose}>Não</Button>
            <Button id="btn-secundary-modal"  onClick={() => { onSubmit(); onClose() }}>Sim</Button>
            {/* <Button onClick={onSubmit}>Agree</Button> */}
          </DialogActions>
        </div>
      </Dialog>
    </div>
  )
}

export default Alert
