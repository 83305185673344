import { Modal, Box } from '@material-ui/core';
import './styles.css';
import { useState } from 'react';

import axios from 'axios';
import api from '../../utils/services';
import Swal from 'sweetalert2';
import { env } from '../../env';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function ModalCard({ open, setOpen }) {
  const [number, setNumber] = useState('');
  const [hodlerName, setHodlerName] = useState('');
  const [expiration, setExpiration] = useState('');
  const [cvv, setCvv] = useState('');

  const onChangeNumber = (str) => {
    const formattedValue = str
      .replace(/\D/g, '')
      .replace(/(\d{4})(?=\d)/g, '$1 ');
    setNumber(formattedValue);
    // setNumber(str.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})$/, '$1-$2-$3-$4'))
  };

  const onChangeExpiration = (str) => {
    const formattedValue = str
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{2})?$/, '$1/$2');
    setExpiration(formattedValue);
  };

  const allFieldsFilled = () => {
    return (
      number.trim() !== '' &&
      hodlerName.trim() !== '' &&
      expiration.trim() !== '' &&
      cvv.trim() !== ''
    );
  };
  const allFieldsSetEmpty = () => {
    setNumber('');
    setHodlerName('');
    setExpiration('');
    setCvv('');
  };
  const handleSubmitCard = () => {
    let data = {
      card: {
        number: number.replace(/\s+/g, ''),
        holder_name: hodlerName,
        exp_month: Number(expiration.substring(0, 2)),
        exp_year: Number(expiration.substring(3, 5)),
        cvv: cvv,
      },
      type: 'card',
    };

    axios
      .post(
        `${env().PAGAR_ME_API_URL}/tokens?appId=${env().PAGAR_ME_APP_ID}`,
        data
      )
      .then(async (res) => {
        const { id, card } = res.data;
        const body = {
          creditCardHash: id,
          isDefault: true,
          flag: card.brand,
        };
        await api.post('cards/establishment', body);
        Swal.fire({
          title: 'Tudo certo',
          text: 'Agora você pode cadastrar serviços!',
          icon: 'success',
          confirmButtonText: 'Cadastrar um serviço!',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.pathname = 'catalogo';
          }
        });
      })
      .catch((error) => {
        console.log(
          'ERRO!',
          error.response?.data?.message || 'Falhou ao cadastrar o cartão',
          'OK!'
        );
        Swal.fire(
          'Falhou ao cadastrar o cartão, tente novamente',
          '',
          'warning'
        );
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (allFieldsFilled()) {
      Swal.fire({
        title: 'Certeza que deseja cadastrar esse cartão?',
        showDenyButton: true,
        confirmButtonText: 'Cadastrar',
        confirmButtonColor: 'var(--primary-cor)',
        denyButtonColor: '#444',
        denyButtonText: `Cancelar`,
        icon: 'question',
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmitCard();
        } else if (result.isDenied) {
          Swal.fire('Ok! cartão não cadastrado', '', 'warning');
          allFieldsSetEmpty();
        }
      });
    } else {
      Swal.fire('Preencha todos os campos', '', 'warning');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="adicionar-cartão-de-credito"
    >
      <Box sx={style}>
        <form onSubmit={onSubmit}>
          <div className="credit-card-container">
            <section id="credit-card">
              <div className="credit-card-front"></div>
              <div className="credit-card-back"></div>
            </section>
            <section className="credit-card-inputs">
              <div className="cc-wrapper">
                <label htmlFor="cc-number">Número do cartão</label>
                <input
                  id="cc-number"
                  name="number"
                  value={number}
                  onChange={(ev) => onChangeNumber(ev.target.value)}
                  type="text"
                  maxLength={19}
                  placeholder="**** **** **** ****"
                />
              </div>
              <div className="cc-wrapper">
                <label htmlFor="cc-holder">Nome do titular</label>
                <input
                  id="cc-holder"
                  type="text"
                  name="hodlerName"
                  value={hodlerName}
                  onChange={(ev) => setHodlerName(ev.target.value)}
                  placeholder="Nome como está no cartão"
                />
              </div>
            </section>
            <div style={{ display: 'flex' }} className="cc-col-2">
              <div className="cc-wrapper">
                <label htmlFor="cc-validity">Validade</label>
                <input
                  name="expiration"
                  value={expiration}
                  maxLength={5}
                  onChange={(ev) => onChangeExpiration(ev.target.value)}
                  id="cc-validity"
                  type="text"
                  placeholder="mm/aa"
                />
              </div>

              <div className="cc-wrapper">
                <label className="cc-help" htmlFor="cc-cvv ">
                  CVV
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM8 12C7.85167 12 7.70666 11.956 7.58333 11.8736C7.45999 11.7912 7.36386 11.6741 7.30709 11.537C7.25033 11.4 7.23548 11.2492 7.26441 11.1037C7.29335 10.9582 7.36478 10.8246 7.46967 10.7197C7.57456 10.6148 7.7082 10.5434 7.85368 10.5144C7.99917 10.4855 8.14997 10.5003 8.28701 10.5571C8.42406 10.6139 8.54119 10.71 8.6236 10.8333C8.70602 10.9567 8.75 11.1017 8.75 11.25C8.75 11.4489 8.67098 11.6397 8.53033 11.7803C8.38968 11.921 8.19892 12 8 12ZM8.5 8.955V9C8.5 9.13261 8.44732 9.25979 8.35356 9.35355C8.25979 9.44732 8.13261 9.5 8 9.5C7.86739 9.5 7.74022 9.44732 7.64645 9.35355C7.55268 9.25979 7.5 9.13261 7.5 9V8.5C7.5 8.36739 7.55268 8.24021 7.64645 8.14645C7.74022 8.05268 7.86739 8 8 8C8.82688 8 9.5 7.4375 9.5 6.75C9.5 6.0625 8.82688 5.5 8 5.5C7.17313 5.5 6.5 6.0625 6.5 6.75V7C6.5 7.13261 6.44732 7.25979 6.35356 7.35355C6.25979 7.44732 6.13261 7.5 6 7.5C5.86739 7.5 5.74022 7.44732 5.64645 7.35355C5.55268 7.25979 5.5 7.13261 5.5 7V6.75C5.5 5.50938 6.62125 4.5 8 4.5C9.37875 4.5 10.5 5.50938 10.5 6.75C10.5 7.83625 9.64 8.74563 8.5 8.955Z"
                      fill="var(--primary-cor)"
                    />
                  </svg>
                </label>
                <input
                  id="cc-cvv"
                  name="cvv"
                  value={cvv}
                  onChange={(ev) => setCvv(ev.target.value)}
                  type="text"
                  maxLength={3}
                  placeholder="***"
                />
              </div>
            </div>
            <button type="submit">Cadastrar</button>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
