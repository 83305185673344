import React, { useState, useEffect, useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import Alert from '../modal/Alert';

import api from '../../utils/services';
import { getId } from '../../utils/auth';

import Swal from 'sweetalert2';
import './styles.css';

import DefaultBanner from '../../assets/banner-default.png';

import CpfInputMask from '../MaskedInput';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    marginTop: 15,
    // flexDirection: 'column',
  },
}));

const EstablishmentInfo = ({ setLoading }) => {
  const classes = useStyles();

  const [file, setFile] = useState('');
  const preview = useMemo(() => {
    return file ? URL.createObjectURL(file) : null;
  }, [file]);

  const [generalInfo, setGeneralInfo] = useState({});
  const [newGeneralInfo, setNewGeneralInfo] = useState({});
  // const [establishment, setEstablishment] = useState({})
  const [info, setInfo] = useState(false);

  const [action, setAction] = useState(false);
  const [submit, setSubmit] = useState(false);

  const handleChangeGeneralInfo = (event) => {
    const { name, value } = event.target;
    setNewGeneralInfo({ ...newGeneralInfo, [name]: value });
  };

  const handleModal = () => {
    setAction(true);
  };

  function onChangeImg(ev) {
    setFile(ev.target.files[0]);
  }

  useEffect(() => {
    async function loadUser() {
      if (Object.keys(generalInfo).length === 0) {
        api
          .get('establishments/self')
          .then((res) => {
            setGeneralInfo(res.data);
          })
          .catch((_) => {
            alert('Sua sessão expirou, faça o Login novamente');
            window.location.reload();
          });
      }
    }
    loadUser();
  }, []);

  useEffect(() => {
    async function handlesubmit() {
      if (submit === true && (Object.keys(newGeneralInfo).length > 0 || file)) {
        setLoading(true);

        let formData = new FormData();

        Object.entries(newGeneralInfo).map(([key, value]) => {
          if (key === 'whatsAppNumber') {
            return formData.append(key, `+${value}`);
          }
          return formData.append(key, value);
        });

        formData.append('backgroundImage', file);

        api
          .put(`establishments/${getId()}`, formData)
          .then((res) => {
            Swal.fire('Informações do estabelecimento cadastrada com sucesso');
            setLoading(false);
            setSubmit(false);
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Algo deu errado, tente novamente',
            });
            setSubmit(false);
            setLoading(false);
          });
      }
    }
    handlesubmit();
  }, [submit]);

  return (
    <>
      {Object.keys(generalInfo).length === 0 ? (
        <h2>Carregando...</h2>
      ) : (
        <>
          {/* Actions Button  */}

          <Paper elevation={0} className={classes.paper}>
            {action === true ? (
              <Alert
                Title="Tem certeza que deseja alterar essas informações?"
                SubTitle="Clicando em Sim, os dados enviados irão alterar. Para corrigir é so repetir o processo"
                onClose={() => setAction(false)}
                onSubmit={() => setSubmit(true)}
              />
            ) : null}

            <div className="form-edit">
              <div
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                className="form-btn-edit-row"
              >
                <h2>Editar Informações</h2>
                {info === false ? (
                  <button
                    onClick={() => setInfo(true)}
                    style={{ backgroundColor: '#4A78FF' }}
                    className="btn-action-edit"
                  >
                    Editar
                  </button>
                ) : (
                  <div className="btns-edit-infos">
                    <button
                      onClick={() => setInfo(false)}
                      style={{ backgroundColor: '#E8505B' }}
                      className="btn-action-edit"
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={() => {
                        setInfo(false);
                        handleModal();
                      }}
                      style={{ backgroundColor: '#31DD61' }}
                      className="btn-action-edit"
                    >
                      Salvar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Paper>

          {/* Info Establishment */}
          <Paper elevation={0} className={classes.paper}>
            <div className="form-edit">
              <h3 style={{ marginBottom: '20px' }}>
                Informações do Estabelecimento
              </h3>

              <div className="form-edit-row">
                <label>Nome: </label>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="establishmentName"
                  onChange={handleChangeGeneralInfo}
                  defaultValue={generalInfo.name}
                />
              </div>

              <div className="form-edit-row">
                <label>Descrição: </label>
                <textarea
                  style={{ backgroundColor: 'transparent' }}
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="description"
                  onChange={handleChangeGeneralInfo}
                  defaultValue={generalInfo.description}
                />
              </div>

              <div className="form-edit-row">
                <label>Telefone para contato: </label>
                <CpfInputMask
                  name="whatsAppNumber"
                  mask="+55 (99) 9 9999-9999"
                  defaultValue={generalInfo.whatsAppNumber}
                  disabled={!info}
                  placeholder="Insira seu numero com +55"
                  onChange={handleChangeGeneralInfo}
                />
              </div>

              <div className="form-edit-row">
                <label>Tipo da Companhia: </label>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="text"
                  name="companyType"
                  onChange={handleChangeGeneralInfo}
                  defaultValue={generalInfo.companyType}
                />
              </div>

              <div className="form-edit-row">
                <label>Tipo de entrega: </label>
                <select
                  disabled={!info}
                  defaultValue={generalInfo.delivery}
                  onChange={handleChangeGeneralInfo}
                  name="delivery"
                  type="text"
                >
                  <option>Escolha uma opção</option>
                  <option value={false}>Retirada em loja</option>
                  <option value={true}>Delivery</option>
                </select>
              </div>

              <div className="form-edit-row">
                <label>Taxa de Entrega: </label>
                {/* <InputMask 
                                    id="input-focus-width"
                                    disabled={!info}
                                    type="number"
                                    onChange={handleChangeGeneralInfo}
                                    name="deliveryFee"
                                    mask="99.00"
                                    // defaultValue={generalInfo.deliveryFee}
                                    // defaultValue={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(generalInfo.deliveryFee)}
                                    placeholder="Digite seu CPF"
                                    onBlur={() => console.log(newGeneralInfo.deliveryFee)}
                                /> */}
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="number"
                  name="deliveryFee"
                  onChange={handleChangeGeneralInfo}
                  // defaultValue={new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(generalInfo.deliveryFee)}
                  defaultValue={generalInfo.deliveryFee}
                />
              </div>

              <div className="form-edit-row">
                <label>Raio de área abrangente por km: </label>
                <input
                  id="input-focus-width"
                  disabled={!info}
                  type="number"
                  name="serviceRadius"
                  onChange={handleChangeGeneralInfo}
                  defaultValue={generalInfo.serviceRadius}
                />
              </div>

              <div className="form-edit-row">
                <label>Status do estabelecimento: </label>
                <select
                  disabled={!info}
                  defaultValue={generalInfo.closed}
                  onChange={handleChangeGeneralInfo}
                  name="closed"
                  type="text"
                >
                  <option>Escolha uma opção</option>
                  <option value={false}>Aberto</option>
                  <option value={true}>Fechado</option>
                </select>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <label>Imagem do Estabelicimento: </label> */}
                {info === false ? (
                  <img
                    style={{ width: '360px' }}
                    src={
                      generalInfo.backgroundImage === null
                        ? DefaultBanner
                        : generalInfo.backgroundImage
                    }
                  />
                ) : (
                  <>
                    <label>Editar banner do catálogo</label>
                    {!preview ? (
                      <>
                        <label htmlFor="backgroundImage">
                          <img
                            htmlFor
                            style={{
                              width: '360px',
                              cursor: 'pointer',
                            }}
                            src={
                              generalInfo.backgroundImage === null
                                ? DefaultBanner
                                : generalInfo.backgroundImage
                            }
                            alt="Default imagem Profile"
                          />
                        </label>
                      </>
                    ) : (
                      <img
                        src={preview}
                        alt="Foto do produto"
                        style={{ cursor: 'pointer' }}
                        width="360px"
                        samesite="none"
                      />
                    )}
                    <input
                      type="file"
                      style={{ width: '360px', opacity: 0, fontSize: 1 }}
                      id="backgroundImage"
                      name="backgroundImage"
                      onChange={onChangeImg}
                    />
                  </>
                )}
              </div>
            </div>
          </Paper>
        </>
      )}
    </>
  );
};

export default EstablishmentInfo;
