import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Swal from 'sweetalert2';
import iconBell3d from '../assets/bell-3d.png';
import saleMp3 from '../assets/sale.mp3';

const firebaseConfig = {
  apiKey: 'AIzaSyDTi4hjtdewF-FGJlwfhprX8-Sfa6HK0KY',
  authDomain: 'logoaliapp-a0202.firebaseapp.com',
  projectId: 'logoaliapp-a0202',
  storageBucket: 'logoaliapp-a0202.appspot.com',
  messagingSenderId: '695098146591',
  appId: '1:695098146591:web:d9e15bc1742e5e3b507a2a',
  measurementId: 'G-7LTL753G1D',
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

let amount = 0;

export async function requestForToken() {
  try {
    const permission = await Notification.requestPermission();
    if (permission !== 'granted') return;
    const token = await getToken(messaging, {
      vapidKey:
        'BAquQqAp_beE0fpXRDNKdHRvdTB6r-rHyDKC7FoO122ikgWKLaELYfWoAnzxeEqvf_CRARaEtK_nFolYw6O1XhM',
    });
    localStorage.setItem('tn', token);
    return token;
  } catch (error) {
    console.log(error);
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);

      let audio = new Audio(saleMp3);
      audio.play();
      amount = amount + 1;
      Swal.fire({
        title: 'Um novo pedido',
        text: `${amount} pedido(s)`,
        iconHtml: `<img src=${iconBell3d}>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Visualizar ',
        cancelButtonText: 'Ignorar',
        customClass: {
          icon: 'bell-notification',
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.pathname = '/pedidos';
        }
      });
    });
  });

const setupNotifications = (callback) => {
  try {
    requestForToken();
    onMessage(messaging, (payload) => {
      callback(payload);
    });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};
export { messaging, setupNotifications };
