import "./styles.css"

export default function CardInsight({icon, title, indicator}){
  return(
    <div className="card-insight-dash-container" >
      <div className="card-insight-dash-content" >
        <div className="card-insight-dash-icon" >
          {
            icon
          }
        </div>
        <div className="card-insight-dash-info">
          <h4>{title}</h4>
          <h5>{indicator}</h5>
        </div>
      </div>
    </div>
  )
}