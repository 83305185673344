import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChartIcon from '@material-ui/icons/BarChart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Storefront from '@material-ui/icons/Storefront';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import MoodBad from '@material-ui/icons/MoodBad';
import Discount from '@material-ui/icons/MonetizationOn';


const url = window.location.href;

export default function MainListItems() {

  // const select = parseInt(localStorage.getItem('select'))

  return (
    <div>
      <ListItem button component="a" href="/dashboard" >
        <ListItemIcon>
          <DashboardIcon color="primary" style={{ color: url === 'httpss://dashboard.logoaliapp.com.br/dashboard' ? '#1976d2' : '#747474' }} />
        </ListItemIcon>
        <ListItemText primary="Inicio" style={{ color: url === 'https://dashboard.logoaliapp.com.br/dashboard' ? '#1976d2' : '#747474' }} />
      </ListItem>

      <ListItem button component="a" href="/perfil" >
        <ListItemIcon>
          <Storefront color="primary" style={{ color: url === 'https://dashboard.logoaliapp.com.br/perfil' ? '#1976d2' : '#747474' }} />
        </ListItemIcon>
        <ListItemText primary="Perfil" style={{ color: url === 'https://dashboard.logoaliapp.com.br/perfil' ? '#1976d2' : '#747474' }} />
      </ListItem>

      {/* Pedidos */}
      <ListItem button component="a" href="/pedidos">
        <ListItemIcon>
          <AssignmentIcon style={{ color: url === 'https://dashboard.logoaliapp.com.br/pedidos' ? '#1976d2' : '#747474' }} />
        </ListItemIcon>
        <ListItemText primary="Pedidos" style={{ color: url === 'https://dashboard.logoaliapp.com.br/pedidos' ? '#1976d2' : '#747474' }} />
      </ListItem> 

      {/* Devoluções e Cancelados */}
      <ListItem button component="a" href="/devolucao&cancelados">
        <ListItemIcon>
          <MoodBad style={{ color: url === 'https://dashboard.logoaliapp.com.br/devolucao&cancelados' ? '#1976d2' : '#747474' }} />
        </ListItemIcon>
       
        <ListItemText primary="Devoluções e" secondary="Cancelamentos"
        style={{ 
          color: url === 'https://dashboard.logoaliapp.com.br/devolucao&cancelados' ? '#1976d2' : '#747474', 
          }} />

      </ListItem> 


      {/* Produtos */}
      {/* <ListItem button component="a" href="/produtos" >
        <ListItemIcon>
          <ShoppingCartIcon style={{ color: url === 'https://dashboard.logoaliapp.com.br/produtos' ? '#1976d2' : '#747474' }} />
        </ListItemIcon >
        <ListItemText primary="Produtos" style={{ color: url === 'https://dashboard.logoaliapp.com.br/produtos' ? '#1976d2' : '#747474' }} />
      </ListItem> */}

      <ListItem button component="a" href="/catalogo" >
        <ListItemIcon>
          <LibraryBooksIcon style={{ color: url === 'https://dashboard.logoaliapp.com.br/catalogo' ? '#1976d2' : '#747474' }} />
        </ListItemIcon >
        <ListItemText primary="Catálogo" style={{ color: url === 'https://dashboard.logoaliapp.com.br/catalogo' ? '#1976d2' : '#747474' }} />
      </ListItem>

       {/* Pagamentos */}
      {/* <ListItem button component="a" href="/pagamentos" >
        <ListItemIcon>
          <CreditCard style={{ color: url === 'https://dashboard.logoaliapp.com.br/pagamentos' ? '#1976d2' : '#747474' }} />
        </ListItemIcon >
        <ListItemText primary="Pagamentos" style={{ color: url === 'https://dashboard.logoaliapp.com.br/pagamentos' ? '#1976d2' : '#747474' }} />
      </ListItem> */}

      <ListItem button component="a" href="/story" >
        <ListItemIcon>
          <BarChartIcon style={{ color: url === 'https://dashboard.logoaliapp.com.br/story' ? '#1976d2' : '#747474' }} />
        </ListItemIcon>
        <ListItemText primary="Story" style={{ color: url === 'https://dashboard.logoaliapp.com.br/story' ? '#1976d2' : '#747474' }} />
      </ListItem>

      <ListItem button component="a" href="/promocoes" >
        <ListItemIcon>
          <Discount style={{ color: url === 'https://dashboard.logoaliapp.com.br/promocoes' ? '#1976d2' : '#747474' }} />
        </ListItemIcon>
        <ListItemText primary="Promoções" style={{ color: url === 'https://dashboard.logoaliapp.com.br/promocoes' ? '#1976d2' : '#747474' }} />
      </ListItem>

       {/* Clientes */}
      {/* <ListItem button component="a" href="/clientes" >
        <ListItemIcon>
          <PeopleIcon style={{ color: url === 'https://dashboard.logoaliapp.com.br/clientes' ? '#1976d2' : '#747474' }} />
        </ListItemIcon>
        <ListItemText primary="Clientes" style={{ color: url === 'https://dashboard.logoaliapp.com.br/clientes' ? '#1976d2' : '#747474' }} />
      </ListItem> */}

    </div>
  )
}

