import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Modal, Paper } from '@material-ui/core';
import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import { makeStyles } from '@material-ui/core/styles';

import ObsSection from '../../../components/obsSection';

import PhotoCamera from '@material-ui/icons/PhotoCamera';

import ContentModalPromotion from './components/ContentModalPromotion';

import MaterialTable from 'material-table';
import api from '../../../utils/services';
import ViewPromotionModal from './components/ViewPromotionModal';
import loadingGif from '../../../assets/loading.gif';
import formatedCurrencyBr from '../../../common/formatCurrency';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 0,
    height: '100vh',
    overflow: 'auto',
    padding: '0',
    width: '100vw',
    backgroundColor: '#f5f4f6',
  },
  titleContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const fakeData = [
  {
    id: '0c5fa98c-ad90-4b2f-9541-b5e10eae453c',
    createdAt: '2024-02-02T01:05:23.706Z',
    updatedAt: '2024-02-02T01:41:05.067Z',
    value: 5,
    type: 'absolute',
  },
  {
    id: '79457113-fc85-4bdb-a703-6f4deeea9927',
    createdAt: '2024-01-26T00:14:48.303Z',
    updatedAt: '2024-01-26T00:14:48.303Z',
    value: 5,
    type: 'percentage',
  },
  {
    id: '8f9f103f-7614-4b71-b2d5-2f99e102a3b6',
    createdAt: '2024-01-26T00:13:34.337Z',
    updatedAt: '2024-01-26T00:13:34.337Z',
    value: 25,
    type: 'percentage',
  },
  {
    id: '3132a5ea-1ca3-4a5f-b6a3-fa30ff91b684',
    createdAt: '2023-12-09T22:06:38.174Z',
    updatedAt: '2023-12-09T22:06:38.174Z',
    value: 1,
    type: 'absolute',
  },
  {
    id: '6cf68acb-d568-490e-b8df-6d6a8f1d8cc7',
    createdAt: '2023-11-23T00:05:17.166Z',
    updatedAt: '2023-11-23T00:05:17.166Z',
    value: 10,
    type: 'percentage',
  },
];

export default function Promotions() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [openModalPromotion, setOpenModalPromotion] = useState(false);
  const [openModalPromotionView, setOpenModalPromotionView] = useState(false);

  const [promotions, setPromotions] = useState([]);
  const [promotionSelected, setPromotionSelected] = useState({});

  const handleOpenModalPromotion = () => {
    setOpenModalPromotion(true);
  };
  const handleCloseModalPromotion = () => {
    setOpenModalPromotion(false);
  };

  const handleOpenModalPromotionView = () => {
    setOpenModalPromotionView(true);
  };
  const handleCloseModalPromotionView = () => {
    setOpenModalPromotionView(false);
  };

  const loadPromotions = () => {
    setLoading(true);
    api
      .get('/promotions')
      .then((response) => {
        setPromotions(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log('Erro load promotions', error);
      });
  };

  useEffect(() => {
    loadPromotions();
  }, []);

  return (
    <div className={classes.root}>
      {loading && (
        <div className="loading-container">
          <img src={loadingGif} alt="Gif de carregamento" />
        </div>
      )}

      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <ObsSection
            icon={<PhotoCamera />}
            title="Promoções"
            message="Crie promoções para seus anúncios, adicionando um novo valor promocional"
          />

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: '20px 5px' }}>
                <div style={{ textAlign: 'right', marginTop: '20px' }}>
                  <button
                    onClick={handleOpenModalPromotion}
                    style={{ marginBottom: '1rem' }}
                    className="btn-adc-item"
                  >
                    <span>+</span>Adicionar promoção
                  </button>

                  <Modal
                    open={openModalPromotion}
                    onClose={handleCloseModalPromotion}
                  >
                    <ContentModalPromotion
                      handleClose={handleCloseModalPromotion}
                      promotions={promotions}
                      setLoading={setLoading}
                    />
                  </Modal>
                </div>
                <MaterialTable
                  elevation={0}
                  columns={[
                    { title: 'Name', field: 'name' },
                    {
                      title: 'Tipo da promoção',
                      field: 'type',
                      render: (rowData) =>
                        rowData.type === 'percentage' ? 'Porcentagem' : 'Valor',
                    },
                    {
                      title: 'Valor',
                      field: 'value',
                      render: (rowData) =>
                        rowData.type === 'percentage'
                          ? `${rowData.value}%`
                          : formatedCurrencyBr(rowData.value),
                    },
                  ]}
                  // data={fakeData}
                  data={promotions.promotions}
                  // data={promotions}
                  title="Promoções"
                  localization={{
                    pagination: {
                      labelDisplayedRows: '{from}-{to} de {count}',
                      labelRowsSelect: 'Linhas',
                    },
                    toolbar: {
                      searchPlaceholder: 'Pesquisar',
                      nRowsSelected: '{0} linha(s) selecionada(s)',
                    },
                    header: {
                      actions: 'Ações',
                    },
                    body: {
                      emptyDataSourceMessage:
                        'Não há registros a serem exibidos',
                    },
                  }}
                  actions={[
                    {
                      icon: 'visibility',
                      tooltip: 'Visualizar pedido',
                      onClick: (_, rowData) => {
                        setPromotionSelected(rowData);
                        handleOpenModalPromotionView();
                        // handleOpen()
                      },
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    paging: false,
                  }}
                />
                <Modal
                  open={openModalPromotionView}
                  onClose={handleCloseModalPromotionView}
                  disableBackdropClick
                >
                  <ViewPromotionModal
                    handleClose={handleCloseModalPromotionView}
                    promotion={promotionSelected}
                    setLoading={setLoading}
                    loadPromotions={loadPromotions}
                  />
                </Modal>
                {/* <div className="pagination-container">
                  <div className="pagination-content">
                    <button
                      disabled={pageSelected - 1 >= 1 ? false : true}
                      onClick={() => {
                        setPageSelected(pageSelected - 1);
                        handlePagination('prev');
                      }}
                    >
                      Anterior
                    </button>
                    <input
                      style={{ textAlign: 'center' }}
                      type="text"
                      value={pageSelected}
                    />
                    <input
                      style={{ textAlign: 'center', backgroundColor: '#DDD' }}
                      disabled
                      type="text"
                      value={totalPages}
                    />
                    <button
                      disabled={pageSelected + 1 <= totalPages ? false : true}
                      onClick={() => {
                        setPageSelected(pageSelected + 1);
                        handlePagination('add');
                      }}
                    >
                      Próximo
                    </button>
                  </div>
                </div> */}
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}
