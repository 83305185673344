import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import MenuAdmin from '../../../components/menu-admin'
import Footer from '../../../components/footer-admin'
import ObsSection from '../../../components/obsSection'

import CreditCard from '@material-ui/icons/CreditCard';

import './styles.css'



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#f5f4f6',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    marginTop: 15,
    // flexDirection: 'column',
  },
  status:{
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'calc(em + 1vw)',
  },
  larger:{
    maxWidth: '13em',
    maxHeight: '13em',
    width: '12em',
    height: '12em',
  },
  infoContainer:{
    marginLeft: '2rem',
  },
  name:{
    fontSize: 'calc(1em + 2vw)',
    lineHeight: '2rem',
  },
  description:{
    fontSize: 'calc(1em + 1vw)',
    lineHeight: '2rem',
    marginTop: '1rem',
  },
}));

export default function Pagamento() {

  const classes = useStyles();


  return (
    
    <div className={classes.root}>

      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

        <ObsSection 
          icon={<CreditCard />}
          title="Receba seu pagamento"
          message="Informe seus dados monetário para receber pagamentos"
        />

          <Grid container spacing={3}>
            <Grid item xs={12}>
 
            </Grid>

          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}