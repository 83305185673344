import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { login, logout } from '../../utils/auth';
import api from '../../utils/services';
import { history } from '../../history';

import Logo from '../../assets/logo-azul.png';
import IconeMail from '../../assets/icon-mail.png';
import IconePassword from '../../assets/icon-password.png';
import EyeIcon from '../../assets/eye-icon.png';

import Swal from 'sweetalert2';
import { requestForToken } from '../../utils/firebase';

import './styles.css';

const Login = () => {
  const initialValue = {
    email: '',
    password: '',
  };

  const [values, SetValues] = useState(initialValue);
  const [eye, setEye] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    SetValues({ ...values, [name]: value });
  };

  //TODO: a chamada do firebase deve ser feita somente durante o login 1 unica vez .
  const handleDataUser = async (token) => {
    const tokenFirebase = await requestForToken();
    api
      .get('/establishments/self')
      .then((res) => {
        localStorage.setItem('id', res.data.id);
        if (tokenFirebase) {
          api
            .post('/devices', { deviceId: tokenFirebase })
            .then((res) => {
              history.push('/dashboard');
            })
            .catch((err) => {
              console.log('não foi possivel pegar as notificaçãos');
            });
        } else {
          localStorage.setItem('notification', false);
          history.push('/dashboard');
        }
      })
      .catch((error) => {
        alert('Não foi possivel conectar');
        logout();
        window.location.reload();
      });
  };

  const handleSubmit = () => {
    if (values.email !== '' && values.password !== '') {
      api
        .post('auth', {
          email: values.email,
          password: values.password,
        })
        .then((res) => {
          login(res.data.accessToken);
          handleDataUser(res.data.accessToken);
          //history.push('/dashboard')
        })
        .catch((err) => {
          Swal.fire({
            title: 'Erro!',
            text: 'Email e senha não correspondem',
            icon: 'error',
            confirmButtonText: 'OK',
          });

          SetValues({ email: '', password: '' });
          console.log('Aconteceu um Erro' + err);
        });
    } else {
      Swal.fire({
        title: 'Informação!',
        text: 'Digite um email e uma senha para proseguir',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }
  };

  // console.log(authenticated)

  return (
    <div className="container">
      <div className="left">
        <h1>Olá, Seja bem vindo!</h1>
        <h2>Faça o Login na sua conta</h2>
      </div>
      <div className="right">
        <div className="right-box">
          <img id="logo-login" src={Logo} alt="Logo logo ali" />
          <form className="form-login" action="">
            <div className="container-input">
              <img src={IconeMail} alt="icone de email" />
              <input
                onChange={handleChange}
                type="email"
                value={values.email}
                placeholder="Entre com seu e-mail"
                name="email"
                id="email"
              />
            </div>

            <div className="container-input">
              <img src={IconePassword} alt="icone de email" />
              <input
                onChange={handleChange}
                value={values.password}
                type={eye === false ? 'password' : 'text'}
                placeholder="Digite sua senha"
                name="password"
                id="password"
              />
              <img
                onClick={() => (eye === true ? setEye(false) : setEye(true))}
                src={EyeIcon}
                alt="icone de email"
              />
            </div>

            <div className="remember-password">
              <input type="checkbox" name="remember" id="remember" />
              <p>Relembrar senha?</p>
            </div>

            <div className="btn-container">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary"
              >
                Login
              </button>
              <button
                type="button"
                onClick={() => history.push('/cadastro')}
                className="btn btn-secondary"
              >
                Criar uma Conta
              </button>
            </div>
          </form>

          <button className="forgot-password">
            <Link to="/recuperar-senha" className="text-blue">
              Esqueceu sua senha? Clique aqui para recuperar
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
