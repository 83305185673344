
import { Grid } from '@material-ui/core';

import './styles.css'

const handleNavigation = (navigation) =>{
  window.location.pathname = navigation;
}



export default function CardInfo({title, description, icon, grid, bgIcon, navigation}){
  return(
    <Grid onClick={() => handleNavigation(navigation)} item xs={12} sm={2} md={3} className="cardinfo-container" >
      <div  className="cardinfo-title" >
        <h2>{title}</h2>
        <div style={{backgroundColor: bgIcon}}  className='cardinfo-icon'>
         {icon}
        </div>
      </div>
      <p>{description}</p>
    </Grid>
  )
}