export default function CashSyncSvg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_6_71)">
        <path d="M2 4V16H12.32C12.1075 15.3546 11.9995 14.6795 12 14H6C6 13.4696 5.78929 12.9609 5.41421 12.5858C5.03914 12.2107 4.53043 12 4 12V8C5.11 8 6 7.11 6 6H16C16 6.53043 16.2107 7.03914 16.5858 7.41421C16.9609 7.78929 17.4696 8 18 8V8.06C18.67 8.06 19.34 8.18 20 8.4V4H2ZM11 7C9.3 7.03 8 8.3 8 10C8 11.7 9.3 12.94 11 13C11.38 13 11.77 12.92 12.14 12.77C12.41 11.67 12.86 10.63 13.97 9.61C13.85 8.28 12.59 6.97 11 7ZM18 9L20.25 11.25L18 13.5V12C16.15 12 14.94 13.96 15.76 15.62L14.67 16.71C12.91 14.05 14.81 10.5 18 10.5V9ZM18 20L15.75 17.75L18 15.5V17C19.85 17 21.06 15.04 20.24 13.38L21.33 12.29C23.09 14.95 21.19 18.5 18 18.5V20Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_6_71">
          <rect width="22" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
