import React from 'react'
import { Router, Switch} from 'react-router'
import { history } from '../history'

import Login from '../pages/Login';
import Cadastro from '../pages/Cadastro';
import CadastroEndereco from '../pages/Cadastro/index-endereco';
import CadastroBancario from '../pages/Cadastro/index-bancario';
import CadastroDocumentos from '../pages/Cadastro/index-documents';

import ForgotStepOne from '../pages/forgotPassword/forgotStepOne'
import ForgotStepSecond from '../pages/forgotPassword/forgotStepSecond'
import ForgotStepThird from '../pages/forgotPassword/forgotStepThird'
import ForgotStepFourth from '../pages/forgotPassword/forgotStepFourth'

import Dashboard from '../pages/admin/dashboard'
import Perfil from '../pages/admin/Perfil'
import Cliente from '../pages/admin/Clientes'
import Pedidos from '../pages/admin/Pedidos'
import DevolutionAndCanceled from '../pages/admin/DevolutionAndCanceled'
import TodosPedidos from '../pages/admin/Pedidos/pedidos'
import Produtos from '../pages/admin/Produtos'
import Pagamento from '../pages/admin/Pagamento'
import Story from '../pages/admin/Story'
import ListStory from '../pages/admin/Story/ListStory'
import CreateStory from '../pages/admin/Story/CreateStory'
import Catalogo from '../pages/admin/Catalogo'
import Notificacao from '../pages/admin/Notification'
import Termo from '../pages/Termo'
import ServicePlans from '../pages/admin/ServicePlans'
import BankAccountEdit from '../pages/admin/BankAccountEdit'
import Promotions from '../pages/admin/Promotions'

import ActionsProduct from '../pages/admin/ActionsProduct'

import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'

import '../styles/global.css'

const Root = () => (
  <Router history={history}>
    <Switch>
      <PublicRoute exact path="/" component={Login} />
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute exact path="/cadastro" component={Cadastro} />
      <PublicRoute exact path="/termo" component={Termo} />
      {/* <PrivateRoute exact path="/cadastro-endereco" component={CadastroEndereco} /> */}
      <PrivateRoute exact path="/cadastro-endereco" component={CadastroEndereco} />
      <PrivateRoute exact path="/cadastro-bancario" component={CadastroBancario} />
      <PrivateRoute exact path="/cadastro-documentos" component={CadastroDocumentos} />

      <PublicRoute exact path="/recuperar-senha" component={ForgotStepOne} />
      <PublicRoute exact path="/recuperar-senha2" component={ForgotStepSecond} />
      <PublicRoute exact path="/recuperar-senha3" component={ForgotStepThird} />
      <PublicRoute exact path="/recuperar-senha4" component={ForgotStepFourth} />

      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/perfil" component={Perfil} />
      <PrivateRoute exact path="/pedidos" component={Pedidos} />
      <PrivateRoute exact path="/devolucao&cancelados" component={DevolutionAndCanceled} />
      <PrivateRoute exact path="/todos-pedidos" component={TodosPedidos} />
      <PrivateRoute exact path="/produtos" component={Produtos} />
      <PrivateRoute exact path="/promocoes" component={Promotions} />
      <PrivateRoute exact path="/notificacao" component={Notificacao} />
     
      <PrivateRoute exact path="/planos" component={ServicePlans} />

      <PrivateRoute exact path="/editar-conta-bancaria" component={BankAccountEdit}  />

      <PrivateRoute exact path="/actions-produtos/:id" component={ActionsProduct} />
      
      <PrivateRoute exact path="/catalogo" component={Catalogo} />
      <PrivateRoute exact path="/pagamentos" component={Pagamento} />
      <PrivateRoute exact path="/clientes" component={Cliente} />
      <PrivateRoute exact path="/story" component={Story} />
      <PrivateRoute exact path="/story/listar-storys" component={ListStory} />
      <PrivateRoute exact path="/story/postar-storys" component={CreateStory} />
      {/* <Route exact path="/public" component={Public}/>
            <Route component={NotFound}/> */}
    </Switch>
  </Router>
)

export default Root
