import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import api from '../../../../utils/services';

import pendingIMG from '../../../../assets/pendente.svg';
import preparedIMG from '../../../../assets/preparando.svg';
import inDeliveryIMG from '../../../../assets/saiu-para-entrega.png';
import awaitPaymentIMG from '../../../../assets/aguardando-pagamento.png';
import { format } from 'date-fns';

import ModalCancel from './ModalCancel';
import formatedCurrencyBr from '../../../../common/formatCurrency';

function getModalStyle() {
  const top = 50;
  const left = 49;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paperModal: {
    position: 'absolute',
    width: '90%',
    maxHeight: '90vh',
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    // borderRadius: 10,
    boxShadow: theme.shadows[5],
    overflow: 'auto',
  },
  paddingModal: {
    padding: theme.spacing(2, 4, 3),
  },
  headerModal: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4, 3),
    backgroundColor: 'var(--primary-cor)',
  },
}));

const ContentModal = ({
  open,
  handleClose,
  data,
  handleActionSale,
  salesTable,
  setSalesTable,
}) => {
  const [modalStyle] = useState(getModalStyle);

  const [totalPrince, setTotalPrince] = useState(0);
  const [princes, setPrinces] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles();

  const handleSubmit = (data, status) => {
    api
      .patch(`sales/update-status/${data.id}`, {
        status: status,
      })
      .then((_) => {
        Swal.fire('Status do pedido atualizado com sucesso!').then((_) => {
          let newArray = salesTable;
          newArray.map((item, index) => {
            if (item.id === data.id) {
              if (status === 'delivered') {
                newArray.splice(index, 1);
                handleClose();
              } else {
                newArray[index].status = status;
                setSalesTable(newArray);
                handleClose();
              }
            }
          });
        });
      })
      .catch((err) => {
        Swal.fire('Algo deu errado, tente novamente!').then((_) =>
          handleClose()
        );
        console.log(err);
      });
  };

  const handleConfirmationAction = (data) => {
    let status = '';
    let legenda = '';
    switch (data.status) {
      case 'pending':
        status = 'prepared';
        legenda = 'Deseja enviar o pedido para a preparação?';
        break;
      case 'prepared':
        status = 'inDelivery';
        legenda = 'Deseja enviar o pedido para o seu cliente';
        break;
      case 'inDelivery':
        status = 'delivered';
        legenda = 'Deseja finalizar o pedido como entregue?';
        break;
      default:
        alert('Não podemos concluir essa operação');
    }

    Swal.fire({
      title: `${legenda}`,
      showDenyButton: true,
      confirmButtonText: 'Sim',
      icon: 'success',
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      denyButtonText: `Não`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleSubmit(data, status);
      } else if (result.isDenied) {
        Swal.fire('Ok! o pedido não foi para preparação', '', 'warning');
        handleClose();
      }
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    let value = 0.0;
    let dataTemp = [];
    data.items.map((item) => {
      value = value + parseFloat(item.value * item.quantity);
      dataTemp.push({
        name: item.nome,
        amount: item.quantity,
        prince: item.value,
        total: parseFloat(item.value * item.quantity),
      });
    });
    console.log(data);
    setTotalPrince(value.toFixed(2));
    setPrinces(dataTemp);
  }, []);

  return (
    <>
      {openModal && (
        <ModalCancel
          open={openModal}
          handleCloseContetModal={handleClose}
          handleCloseModalCancel={handleCloseModal}
          id={data.id}
          salesTable={salesTable}
          setSalesTable={setSalesTable}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paperModal}>
          <div className={classes.headerModal}>
            <h2 style={{ color: 'var(--white)' }}>
              PEDIDO: {data.orderNumber}
            </h2>
            <button onClick={handleClose} className="btn-closed-modal">
              X
            </button>
          </div>

          <div className={classes.paddingModal}>
            <div className="first-details-pedido-container">
              <div className="details-pedido-content">
                <h3>Detalhes do cliente</h3>
                <h4>Nome: {data.clientName} </h4>
                <h4>Telefone: {data.clientPhone} </h4>
                {/* <h4>Data: {data.hours}</h4> */}
              </div>

              <div className="status-pedido">
                {data.status === 'pending' && (
                  <>
                    <img
                      style={{ width: '50px' }}
                      src={pendingIMG}
                      alt="Icone do status do pedido"
                    />
                    <span>Pendente</span>
                  </>
                )}
                {data.status === 'prepared' && (
                  <>
                    <img
                      style={{ width: '50px' }}
                      src={preparedIMG}
                      alt="Icone do status do pedido"
                    />
                    <span>Em preparo</span>
                  </>
                )}
                {data.status === 'inDelivery' && (
                  <>
                    <img
                      style={{ width: '50px' }}
                      src={inDeliveryIMG}
                      alt="Icone do status do pedido"
                    />
                    <span>Saiu para Entrega</span>
                  </>
                )}
                {data.status === 'awaitingPayment' && (
                  <>
                    <img
                      style={{ width: '50px' }}
                      src={awaitPaymentIMG}
                      alt="Icone do status do pedido"
                    />
                    <span>Aguardando Pagamento</span>
                  </>
                )}
              </div>
            </div>

            <div className="details-pedido-container">
              {data.address === null ? null : (
                <div className="details-pedido-content">
                  <h3>Detalhes do endereço</h3>
                  <h4>CEP: {data.address.zipCode}</h4>
                  <h4>Cidade: {data.address.city}</h4>
                  <h4>Bairro: {data.address.neighborhood}</h4>
                  <h4>
                    Rua: {data.address.street} - Nº {data.address.number}
                  </h4>
                  <h4>Complemento: {data.address.complement}</h4>
                </div>
              )}
            </div>

            <div className="details-pedido-container">
              <div className="details-pedido-content">
                <h3>Detalhes do pedido</h3>
                <h4 style={{ marginBottom: '10px' }}>
                  <b>
                    Data do pedido: {format(data.date, 'dd/MM/yyyy - HH:mm')}h
                  </b>
                </h4>

                <div className="table-pedidos" width="100%">
                  {data.items.map((item) => {
                    return (
                      <div key={item.id}>
                        <div className="coluna-pedidos">
                          <div>
                            <h3>Item</h3>
                            <h4>{item.name}</h4>
                          </div>

                          <div>
                            <h3>Qtde</h3>
                            <h4>{item.quantity}</h4>
                          </div>

                          <div>
                            <h3>Valor unitário</h3>
                            <h4>R$ {item.value.toFixed(2)}</h4>
                          </div>

                          <div>
                            <h3>Subtotal</h3>
                            <h4>
                              R${' '}
                              {parseFloat(item.value * item.quantity).toFixed(
                                2
                              )}
                            </h4>
                          </div>
                        </div>

                        <div>
                          <h3 style={{ marginTop: '16px' }}>OBSERVAÇÃO: </h3>
                          <p>
                            {item.observation === ''
                              ? 'Sem observação'
                              : item.observation}
                          </p>
                          <span className="obs-pedido"></span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="footer-modal">
              <div className="details-pedido-container">
                <div className="details-pedido-content">
                  <h3>Conclusão do pedido</h3>

                  <div style={{ display: 'flex', gap: '6px' }}>
                    <h4>Tipo de pagamento: </h4>
                    <h4>
                      {data.paymentType === 'creditCard'
                        ? 'Cartão de Credito'
                        : 'PIX'}
                    </h4>
                  </div>

                  {/* <h4>Quantidade: {data.items.length}</h4> */}

                  <h4>
                    Valor do frete: {formatedCurrencyBr(data.deliveryFee)}
                  </h4>

                  {data.discount > 0 && (
                    <div style={{ display: 'flex', gap: '6px' }}>
                      <h4>Desconto: </h4>
                      <h4>{formatedCurrencyBr(data.discount)}</h4>
                    </div>
                  )}

                  <div style={{ display: 'flex', gap: '6px' }}>
                    <h4>Valor total do pedido: </h4>
                    <h4>{formatedCurrencyBr(data.totalValue)}</h4>
                  </div>

                  <h1>
                    Valor a receber: {formatedCurrencyBr(data.amountToReceive)}
                  </h1>
                  {/* <h1>Quantidade a receber: {data.totalValue}</h1> */}
                  {/* <h1>Preço: {totalPrince}</h1>    */}
                </div>
              </div>

              <div className="btn-pedidos">
                <button
                  onClick={() => setOpenModal(true)}
                  style={{ backgroundColor: 'var(--red)' }}
                >
                  Cancelar
                  {/* <img src={cancelarIcon} alt="Cancelar" /> */}
                </button>
                <button
                  disabled={data.status === 'awaitingPayment' ? true : false}
                  onClick={() => {
                    handleConfirmationAction(data);
                  }}
                  style={{ backgroundColor: 'var(--primary-cor)' }}
                >
                  Prosseguir
                  {/* <img src={finalizarIcon} alt="Cancelar" /> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ContentModal;
