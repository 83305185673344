import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuAdmin from '../../../components/menu-admin'
import Footer from '../../../components/footer-admin'

import { Paper, Box, Container, Grid} from '@material-ui/core';

import ObsSection from '../../../components/obsSection';

import Storefront from '@material-ui/icons/Storefront';

import GeneralInfo from '../../../components/forms-profile/GeneralInfo';
import EstablishmentInfo from '../../../components/forms-profile/EstablishmentInfo';
import Address from '../../../components/forms-profile/Address';
import HoursOfOperation from '../../../components/forms-profile/HoursOfOperation';

import './styles.css'

import loadingGif from '../../../assets/loading.gif'
import CreditCards from '../../../components/forms-profile/CreditCards';
import BankAccount from '../../../components/forms-profile/BankAccount';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#f5f4f6',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    marginTop: 15,
    // flexDirection: 'column',
  },
  status:{
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    fontSize: 'calc(em + 1vw)',
  },
  larger:{
    maxWidth: '13em',
    maxHeight: '13em',
    width: '12em',
    height: '12em',
  },
  infoContainer:{
    marginLeft: '2rem',
  },
  name:{
    fontSize: 'calc(1em + 2vw)',
    lineHeight: '2rem',
  },
  description:{
    fontSize: 'calc(1em + 1vw)',
    lineHeight: '2rem',
    marginTop: '1rem',
  },
}));


export default function Perfil() {

  const classes = useStyles();
  const [selected, setSelected] = useState(2)
  const [loading, setLoading] = useState(false)

  const handleLoadFormEdit = (num) =>{
    setSelected(num)

  }
  return (
    
    <div className={classes.root}>

      {
        loading ?
          <div className='loading-container'>
            <img src={loadingGif} alt="Gif de carregamento" />
          </div>
          :
          null
      } 

      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

        <ObsSection 
          icon={<Storefront />}
          title="Perfil do Empreendedor"
          message="Aqui é o espaço para você definir informações que vão aparecer no aplicativo, 
          clique no icone para editar os campos."
        />


          <Grid container spacing={3}>
            <Grid item xs={12}>

              <Paper elevation={0} className={classes.paper}>

             

                <div className="nav-edit-container">
                  <h2>Escolhe uma das opções para editar</h2>
                  <nav className="nav-edit-content" >
                    <ul>
                      <li><button onClick={() => handleLoadFormEdit(1)} style={{backgroundColor: `${selected === 1 ? '#1976d2' : '#B3B3B3' }`}}>Informações do Administrador</button></li>
                      <li><button onClick={() => handleLoadFormEdit(2)} style={{backgroundColor: `${selected === 2 ? '#1976d2' : '#B3B3B3' }`}}>Informações do Estabelecimento</button></li>
                      <li><button onClick={() => handleLoadFormEdit(3)} style={{backgroundColor: `${selected === 3 ? '#1976d2' : '#B3B3B3' }`}}>Endereço</button></li>
                      <li><button onClick={() => handleLoadFormEdit(4)} style={{backgroundColor: `${selected === 4 ? '#1976d2' : '#B3B3B3' }`}}>Cartões</button></li>
                      <li><button onClick={() => handleLoadFormEdit(5)} style={{backgroundColor: `${selected === 5 ? '#1976d2' : '#B3B3B3' }`}}>Conta bancaria</button></li>
                      <li><button onClick={() => handleLoadFormEdit(6)} style={{backgroundColor: `${selected === 6 ? '#1976d2' : '#B3B3B3' }`}}>Horário de Funcionamento</button></li>
                      {/* <li><button onClick={() => handleLoadFormEdit(5)} style={{backgroundColor: `${selected === 5 ? '#1976d2' : '#B3B3B3' }`}}>Tempo de entrega</button></li> */}
                    </ul>
                  </nav>
                </div>
              </Paper>

              {/* {
                selected === 1 &&  <GeneralInfo />
              } */}

              {
                (()=>{
                  switch(selected){
                    case 1:
                      return <GeneralInfo setLoading={setLoading} />
                    case 2:
                      return <EstablishmentInfo setLoading={setLoading} /> 
                    case 3:
                      return <Address setLoading={setLoading} />
                    case 4:
                      return <CreditCards setLoading={setLoading} />
                    case 5:
                      return <BankAccount setLoading={setLoading} />
                    case 6:
                      return <HoursOfOperation setLoading={setLoading} />
                    default:
                      return null;
                  }
                })()
              }

            </Grid>

          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}