import React from 'react';
import InputMask from 'react-input-mask';

const onlyNumbers = (str) => str.replace(/[^0-9]/g, '');

const MaskedInput = ({ disabled, value, onChange, name, mask, placeholder, defaultValue }) => {

  function handleChange(event) {
    onChange({
      ...event,
      target: {
        ...event.target,
        name,
        value: onlyNumbers(event.target.value)
      }
    });
  }


  function validCpf(value) {
    if(value.length === 11){
      if ( !value || value.length !== 11
        || value === "00000000000"
        || value === "11111111111"
        || value === "22222222222" 
        || value === "33333333333" 
        || value === "44444444444" 
        || value === "55555555555" 
        || value === "66666666666"
        || value === "77777777777"
        || value === "88888888888" 
        || value === "99999999999" )
      return alert('Inválido 1')
    let soma = 0
      let resto
    for (let i = 1; i <= 9; i++) 
      soma = soma + parseInt(value.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11
      if ((resto === 10) || (resto === 11))  resto = 0
      if (resto !== parseInt(value.substring(9, 10)) ) return alert('Invalido')
    soma = 0
      for (let i = 1; i <= 10; i++) 
        soma = soma + parseInt(value.substring(i-1, i)) * (12 - i)
      resto = (soma * 10) % 11
      if ((resto === 10) || (resto === 11))  resto = 0
      if (resto !== parseInt(value.substring(10, 11) ) ) return alert('Invalido')
      return true
    }else if(value.length === 14){
      let cnpj = value;
      cnpj = cnpj.replace(/[^\d]+/g,'');
 
      if(cnpj == '') return alert("invalido");
       
      if (cnpj.length != 14)
          return alert("invalido");
   
      // Elimina CNPJs invalidos conhecidos
      if (cnpj == "00000000000000" || 
          cnpj == "11111111111111" || 
          cnpj == "22222222222222" || 
          cnpj == "33333333333333" || 
          cnpj == "44444444444444" || 
          cnpj == "55555555555555" || 
          cnpj == "66666666666666" || 
          cnpj == "77777777777777" || 
          cnpj == "88888888888888" || 
          cnpj == "99999999999999")
          return alert("invalido");
           
      // Valida DVs
      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0,tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0))
          return alert("invalido");
           
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1))
            return alert("invalido");
             
      return true;

    }else if(value.length === 0){
      return null;
    }else{
      alert('Preencha o campo corretamente');
    }
}


  return (
    <InputMask
      disabled={ disabled}
      // defaultValue={ defaultValue > '' ? defaultValue : '999.999.999-99'}
      defaultValue={defaultValue}
      className="c2"
      onBlur={() => (name === 'phone' || name === 'whatsAppNumber' ) ? null : validCpf(value)}
      name={name}
      mask={mask}
      value={value}
      onChange={handleChange}
      placeholder={ placeholder > '' ? placeholder : 'Digite aqui'}
    />
  );
};

export default MaskedInput;

/*
function validCnpj(cnpj) {
    
}
*/