import { forwardRef, useState } from 'react';
import { Button, Dialog, DialogActions, Slide } from '@material-ui/core';

import './modal.css';
import api from '../../utils/services';
import axios from 'axios';
import CpfInputMask from '../MaskedInput';
import Swal from 'sweetalert2';
// import { getId } from '../../utils/auth'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateAddress = ({
  addressDefault,
  onSubmit = () => {},
  onClose = () => {},
  allAddress,
  setAllAddress,
}) => {
  const [open, setOpen] = useState(true);
  const [address, setAddress] = useState(addressDefault);
  const [number, setNumber] = useState(0);
  const handleChangeAddress = (event) => {
    const { name, value } = event.target;

    if (name === 'number') {
      setAddress({ ...address, [name]: parseInt(value) });
    } else {
      setAddress({ ...address, [name]: value });
    }
  };

  const handleSubmit = () => {
    let obj = {
      zipCode: address.zipCode,
      state: address.state,
      city: address.city,
      street: address.street,
      number: address.number.toString(),
      default: address.default === 'false' ? false : true,
      complement: address.complement,
      neighborhood: address.neighborhood,
    };

    if (obj?.default === 'true') obj.default = true;
    if (obj?.default === 'false') obj.default = false;

    api
      .put(`addresses/${addressDefault.id}`, obj)
      .then((res) => {
        Swal.fire({
          title: 'Sucesso',
          text: 'Endereço atualizado com sucesso!',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((_) => {
          if (allAddress.length === 1) {
            // setAllAddress([address])
            onClose();
            setAllAddress((_) => {
              return [
                {
                  id: address.id,
                  zipCode: obj.zipCode,
                  state: obj.state,
                  city: obj.city,
                  street: obj.street,
                  number: obj.number,
                  default: obj.default,
                  complement: obj.complement,
                  neighborhood: obj.neighborhood,
                },
              ];
            });
          } else {
            onClose();
            setAllAddress(
              allAddress.map((item, index) =>
                item.id === address.id
                  ? {
                      ...allAddress,
                      zipCode: item.zipCode,
                      state: item.state,
                      city: item.city,
                      street: item.street,
                      number: item.number,
                      default: item.default,
                      complement: item.complement,
                      neighborhood: item.neighborhood,
                      id: item.id,
                    }
                  : item
              )
            );
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          title: 'Erro',
          text: 'Algo deu errado, tente novamente',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then((_) => onClose());
        // console.log(error.response)
      });
  };

  const handleModalSubmit = () => {
    Swal.fire({
      title: 'Certeza que deseja atualizar esse endereço?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      denyButtonText: `Não`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleSubmit();
      } else if (result.isDenied) {
        Swal.fire('Ok! o endereço não foi atualizado', '', 'warning');
        handleClose();
      }
    });
  };

  const handleDelete = () => {
    api
      .delete(`addresses/${addressDefault.id}`)
      .then((res) => {
        Swal.fire({
          title: 'Sucesso',
          text: 'Endereço deletado com sucesso!',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((_) => window.location.reload());
        //onClose();
      })
      .catch((error) => {
        Swal.fire({
          title: 'Erro',
          text: 'Algo deu errado, tente novamente',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then((_) => onClose());
        console.log(error.response);
      });
  };

  const handleModalDelete = () => {
    Swal.fire({
      title: 'Certeza que deseja deletar esse endereço?',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      denyButtonText: `Não`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDelete();
      } else if (result.isDenied) {
        Swal.fire('Ok! o endereço não foi deletado', '', 'warning');
        handleClose();
      }
    });
  };

  const onBLurCep = async (cep) => {
    const cepValidation = cep.replace(/[^0-9]/g, '');

    if (cep?.length !== 8 || cep === '') {
      return Swal.fire({
        title: 'Atenção!',
        text: 'Preencha o campo corretamente!',
        icon: 'warning',
        confirmButtonText: 'OK',
      });
    }

    await axios
      .get(`http://viacep.com.br/ws/${cep}/json`)
      .then((res) => {
        setAddress((prevState) => {
          return {
            ...prevState,
            city: res.data.localidade,
            state: res.data.uf,
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
          };
        });
      })
      .catch((err) => console.log('Erro cep', err));
  };

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="container-modal">
          {/* <img src={ImagemModal} alt="" /> */}
          <div className="content-modal">
            <div className="form-edit-row">
              <h3>Cep: (apenas Números)</h3>
              {/* <CpfInputMask
                name="cpfCnpj"
                mask="99999-999"
               
                onChange={handleChangeAddress}
                onBlur={(e) => onBLurCep(e.target.value)}
                defaultValue={addressDefault.zipCode}
                />
                 */}
              <input
                id="input-focus-width"
                maxLength={8}
                type="text"
                name="zipCode"
                onChange={handleChangeAddress}
                onBlur={(e) => onBLurCep(e.target.value)}
                defaultValue={address.zipCode}
              />
            </div>

            <div className="form-edit-row">
              <h3>Estado: </h3>
              <input
                defaultValue={address.state}
                value={address.state}
                id="input-focus-width"
                type="text"
                name="state"
                onChange={handleChangeAddress}
              />
            </div>

            <div className="form-edit-row">
              <h3>Cidade: </h3>
              <input
                defaultValue={address.city}
                value={address.city}
                id="input-focus-width"
                type="text"
                name="city"
                onChange={handleChangeAddress}
              />
            </div>

            <div className="form-edit-row">
              <h3>Rua: </h3>
              <input
                id="input-focus-width"
                type="text"
                name="street"
                onChange={handleChangeAddress}
                defaultValue={address.street}
                value={address.street}
              />
            </div>

            <div className="form-edit-row">
              <h3>Número: </h3>
              <input
                id="input-focus-width"
                type="text"
                name="number"
                onChange={handleChangeAddress}
                defaultValue={address.number}
                value={address.number}
              />
            </div>

            <div className="form-edit-row">
              <h3>Complemento: </h3>
              <input
                id="input-focus-width"
                defaultValue={address.complement}
                value={address.complement}
                type="text"
                name="complement"
                onChange={handleChangeAddress}
              />
            </div>

            <div className="form-edit-row">
              <h3>Bairro: </h3>
              <input
                id="input-focus-width"
                type="text"
                name="neighborhood"
                onChange={handleChangeAddress}
                defaultValue={address.neighborhood}
                value={address.neighborhood}
              />
            </div>

            <div className="form-edit-row">
              <h3>Endereço padrão?</h3>
              <select
                style={{
                  border: 'none',
                  borderBottom: '1px solid #ddd ',
                  outline: 'none',
                }}
                defaultValue={address.default}
                value={address.default}
                onChange={handleChangeAddress}
                name="default"
              >
                <option>Escolha uma opção</option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </select>
            </div>
          </div>
          <DialogActions style={{ width: '100%' }}>
            <Button
              id="btn-secundary-modal"
              style={{
                marginLeft: 'auto',
                backgroundColor: 'var(--red)',
                color: '#FFF',
                borderColor: 'none !important',
              }}
              onClick={() => handleModalDelete()}
            >
              Excluir
            </Button>
            {/* <Button id="btn-primary-modal" onClick={() => { handleSubmit(); onClose(); setAddress() }}>Atualizar</Button> */}
            <Button id="btn-primary-modal" onClick={handleModalSubmit}>
              Atualizar
            </Button>
            <Button id="btn-secundary-modal" onClick={onClose}>
              Cancelar
            </Button>

            {/* <Button onClick={onSubmit}>Agree</Button> */}
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default UpdateAddress;
