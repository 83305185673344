import { Box, Container, Grid, Paper } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import { makeStyles } from '@material-ui/core/styles';

import ObsSection from '../../../components/obsSection';

import PhotoCamera from '@material-ui/icons/PhotoCamera';
import api from '../../../utils/services';
import Swal from 'sweetalert2';

import ArrowBack from '@material-ui/icons/ArrowBack';
import { getId } from '../../../utils/auth';

import { history } from '../../../history'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 0,
    height: '100vh',
    overflow: 'auto',
    padding: '0',
    width: '100vw',
    backgroundColor: '#f5f4f6',
    // backgroundColor: '#F4F5F7',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  titleContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: 'row',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  containerStory: {
    display: 'flex',
    flexWrap: "wrap"
  },
  imgStory: {
    width: "300px",
    marginRight: "6px",
  },
  btnDelete: {
    position: 'absolute',
    cursor: 'pointer',
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'var(--red)',
    color: '#FFF',
    right: "0",
    top: "-10px"
  },
  btnDeleteAllContainer:{
    textAlign: 'right'
  },
  btnDeleteAll:{
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    backgroundColor: 'var(--red)',
    color: '#FFF',
    padding: '1rem',
    marginBottom: '1rem',
    cursor: 'pointer'
  }
}));

const ListStory = () => {

  const classes = useStyles();

  const [storys, setStorys] = useState([])

  const deleteAllStory = () => {
    api.delete(`/stories/all/${getId()}`).then(_ => {
      Swal.fire('Todos os story foram deletados com sucesso!', '', 'success')
      .then(_ => history.push('/story') )
      
    }).catch(err => {
      console.log("erro ao deletar story", err)
      Swal.fire('O Story não foi deletado', '', 'error')
    })
  }

  const deleteStory = (id) => {
    api.delete(`/stories/${id}`).then(_ => {
      Swal.fire('Deletado com sucesso!', '', 'success')
      const novaLista = storys.filter(item => item.files[0].id !== id);
      setStorys(novaLista);
    }).catch(err => {
      console.log("erro ao deletar story", err)
      Swal.fire('O Story não foi deletado', '', 'error')
    })
  }

  const handleDeleteStory = (id, action) => {
    Swal.fire({
      title: 'Certeza que ira excluir esse story?',
      showDenyButton: true,
      confirmButtonText: 'Deletar',
      confirmButtonColor: "#d33",
      denyButtonColor: "#444",
      denyButtonText: `Não deletar`,
    }).then((result) => {
      if (result.isConfirmed) {
        if(action === 'all'){
          deleteAllStory()
        }else{
          deleteStory(id)
        }
      } else if (result.isDenied) {
        Swal.fire('O Story não foi deletado', '', 'warning')
      }
    })
  }

  useEffect(() => {
    api.get("/stories/establishment").then(res => {
      setStorys(res.data.stories)
    }).catch(err => console.log("Erro ao listar os storys", err))
  }, [])

  return (
    <div className={classes.root}>

      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} >
            <ObsSection
              icon={<PhotoCamera />}
              title="Story"
              message="Adicione story no perfil da sua loja para aumentar a conversão de clientes"
            />
            <Paper className={classes.titleContainer} elevation={0}  >
              <h1>Veja todos os seus story</h1>
              <ArrowBack width="40px" style={{ cursor: "pointer" }} onClick={() => window.location.pathname = "story"} />
            </Paper>
            <Paper elevation={0} className={classes.paper} style={{ marginTop: 30 }} >
              {/* <div className={classes.btnDeleteAllContainer} >
                <button onClick={() => handleDeleteStory(0, 'all')} className={classes.btnDeleteAll} >Excluir todos os storys</button>
              </div> */}
              <div className={classes.containerStory}  >
                {
                  storys.length > 0 ?
                    storys.map((item, index) => {
                      return (
                        <div key={index} style={{ position: "relative" }} >
                          <button
                            onClick={() => handleDeleteStory(item.files[0].id, 'unic')}
                            className={classes.btnDelete} >X</button>
                          <img
                            className={classes.imgStory}
                            key={index}
                            src={item.files[0].url}
                            alt="Story" />
                        </div>
                      )
                    })
                    :
                    null
                }
              </div>


            </Paper>

          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default ListStory;