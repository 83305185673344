function formatedCurrencyBr(value) {
  value = parseFloat(value).toFixed(2);

  value = value.toString();

  const parts = value.split('.');
  let partWhole = parts[0];
  const partDecimal = parts.length > 1 ? ',' + parts[1] : '';

  partWhole = partWhole.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return 'R$ ' + partWhole + partDecimal;
}

export default formatedCurrencyBr;
