import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
// import Card from "react-credit-cards";

import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

// import { history } from '../../history'

import api from '../../utils/services';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    marginTop: 15,
    // flexDirection: 'column',
  },
  forceColumn: {
    display: 'flex',
    gap: '20px',
    flexDirection: 'row !important',
    alignItems: 'center',
  },
}));

const BankAccount = ({ setLoading }) => {
  const history = useHistory();

  const classes = useStyles();
  const [isBankInfo, setIsBankInfo] = useState(true);

  const [action, setAction] = useState(false);

  const [bankAccout, setBankAccout] = useState({});

  //   axios.get('https://api.sdx.logoaliapp.com.br/establishments/self',
  //   {
  //     headers: {
  //       "Authorization": `Bearer ${myToken()}`,
  //     }
  //   }
  // ).then(res => {
  //   console.log(res.data)
  //   if (res.data.user.bankAccount === null) {
  //     setIsBankInfo(false)
  //   }

  const handleBankAccoutInfo = (event) => {
    const { name, value } = event.target;
    setBankAccout({ ...bankAccout, [name]: value });
  };

  const handleEditBankAccount = () => {
    Swal.fire({
      title: 'Atenção',
      text: 'Você realmente deseja trocar sua conta bancaria?',
      icon: 'warning',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        // history.push('/editar-conta-bancaria')
        history.push({
          pathname: '/editar-conta-bancaria',
          state: { bankAccout: bankAccout },
        });
      }
    });
  };

  useEffect(() => {
    async function loadCard() {
      api
        .get('/bank-accounts')
        .then((res) => {
          setBankAccout(res.data);
          setIsBankInfo(true);
        })
        .catch((err) => {
          alert('não tem conta');
          console.log('Error', err);
        });
    }
    loadCard();
  }, []);

  const handleModal = () => {
    setAction(true);
  };

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <div className="form-edit">
          <div
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
            className="form-btn-edit-row"
          >
            <h2>Editar Informações</h2>
            <button
              onClick={handleEditBankAccount}
              style={{ backgroundColor: '#4A78FF' }}
              className="btn-action-edit"
            >
              Editar
            </button>
            {/* {
              info === false ?
                :
                <div className='btns-edit-infos' >
                  <button onClick={() => setInfo(false)} style={{ backgroundColor: '#E8505B' }} className="btn-action-edit" >Cancelar</button>
                  <button onClick={() => { setInfo(false); handleModal() }} style={{ backgroundColor: '#31DD61', }} className="btn-action-edit" >Salvar</button>
                </div>

            } */}
          </div>
        </div>
      </Paper>

      <Paper elevation={0} className={classes.paper}>
        <div className="form-edit">
          {isBankInfo ? (
            <div>
              <h3>Editar sua conta bancaria</h3>

              <div className="form-edit-row">
                <label>Nome: </label>
                <input
                  id="input-focus-width"
                  defaultValue={bankAccout.holderName}
                  disabled={true}
                  type="text"
                  name="holderName"
                  onChange={handleBankAccoutInfo}
                />
              </div>

              <div className="form-edit-row">
                <label>CPF/CNPJ: </label>
                <input
                  id="input-focus-width"
                  defaultValue={bankAccout.holderDocument}
                  disabled={true}
                  type="text"
                  name="holderDocument"
                  onChange={handleBankAccoutInfo}
                />
              </div>

              <div className="form-edit-row">
                <label>Tipo da conta: </label>
                <input
                  id="input-focus-width"
                  defaultValue={
                    bankAccout.holderType === 'individual'
                      ? 'Conta pessoa física'
                      : 'Conta pessoa jurídica'
                  }
                  disabled={true}
                  type="text"
                  name="holderDocument"
                  onChange={handleBankAccoutInfo}
                />
              </div>

              <div className="form-edit-row">
                <label>Número do Banco: </label>
                <input
                  id="input-focus-width"
                  defaultValue={bankAccout.bankNumber}
                  disabled={true}
                  type="text"
                  name="bankNumber"
                  onChange={handleBankAccoutInfo}
                />
              </div>

              <div
                style={{ marginTop: '30px' }}
                className={`form-edit-row ${classes.forceColumn}`}
              >
                <div style={{ marginTop: '0' }}>
                  <label>Número da agência: </label>
                  <input
                    id="input-focus-width"
                    defaultValue={bankAccout.agencyNumber}
                    disabled={true}
                    type="text"
                    name="agencyNumber"
                    onChange={handleBankAccoutInfo}
                  />
                </div>
                <div style={{ marginTop: '0' }}>
                  <label>Digito: </label>
                  <input
                    id="input-focus-width"
                    defaultValue={bankAccout.agencyDigitNumber}
                    disabled={true}
                    type="text"
                    name="agencyDigitNumber"
                    onChange={handleBankAccoutInfo}
                  />
                </div>
              </div>

              <div
                style={{ marginTop: '30px' }}
                className={`form-edit-row ${classes.forceColumn}`}
              >
                <div style={{ marginTop: '0' }}>
                  <label>Número do banco: </label>
                  <input
                    id="input-focus-width"
                    disabled={true}
                    type="text"
                    name="accountNumber"
                    defaultValue={bankAccout.accountNumber}
                    onChange={handleBankAccoutInfo}
                  />
                </div>
                <div style={{ marginTop: '0' }}>
                  <label>Digito: </label>
                  <input
                    id="input-focus-width"
                    disabled={true}
                    type="text"
                    name="agencyDigitNumber"
                    defaultValue={bankAccout.agencyDigitNumber}
                    onChange={handleBankAccoutInfo}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h2>Você ainda não tem conta bancaria</h2>
              <button>Cadastrar uma conta bancaria</button>
            </div>
          )}
        </div>
      </Paper>
    </>
    // <>
    //   {
    //       !card
    //       ?
    //       <h2>Carregando...</h2>
    //       :
    //       <>
    //         <Paper elevation={0} className={classes.paper} >
    //           <div className="form-edit">
    //           <h1>Nada para mostrar aqui</h1>

    //           </div>
    //         </Paper>
    //       </>
    //   }
    // </>
  );
};

export default BankAccount;
