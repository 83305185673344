import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Modal,
  TextField,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Select,
  useTheme,
} from '@material-ui/core';
import api from '../../utils/services';
import MaterialTable from 'material-table';

import Alert from '../modal/Alert';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    marginTop: 15,
  },
  paperModal: {
    position: 'absolute',
    width: 'auto',
    margin: '0 auto',
    maxHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 49;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  {
    name: 'sunday',
    value: 'Domingo',
  },
  {
    name: 'monday',
    value: 'Segunda-Feira',
  },
  {
    name: 'tuesday',
    value: 'Terça-Feira',
  },
  {
    name: 'wednesday',
    value: 'Quarta-Feira',
  },
  {
    name: 'thursday',
    value: 'Quinta-Feira',
  },
  {
    name: 'friday',
    value: 'Sexta-Feira',
  },
  {
    name: 'saturday',
    value: 'Sábado',
  },
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const HoursOfOperation = () => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState(false);

  const [action, setAction] = useState(false);
  const [submit, setSubmit] = useState(false);

  const [typeModal, setTypeModal] = useState('');
  const [amout, setAmout] = useState([0]);
  const theme = useTheme();
  const [shifts, setShifts] = useState([]);
  const [data, setData] = useState([]);
  const [dataSelect, setDataSelect] = useState({});
  const [personName, setPersonName] = useState([]);
  const [active, setActive] = useState(true);
  const [time, setTime] = useState({
    start: '',
    end: '',
  });

  const [horario, setHorario] = useState('00:00');
  const date = new Date();

  function FormatMTH(totalMinutes) {
    // const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    return `-${padTo2Digits(hours).slice(1)}`;
  }

  // Converter minutos para horas
  function HoursToMinutes(totalMinutes, firstNumber) {
    let minutes = totalMinutes % 60;
    let hours = Math.floor(totalMinutes / 60);
    //onsole.log('antes', firstNumber, hours)
    if (hours < 0) {
      if (hours === -3) hours = 21;
      if (hours === -2) hours = 22;
      if (hours === -1) hours = 23;
      if (hours > -1 && hours < 1) hours = 0;
    }
    if (minutes < 0) {
      if (minutes === -3) minutes = 21;
      if (minutes === -2) minutes = 22;
      if (minutes === -1) minutes = 23;
      if (minutes > -1 && minutes < 1) minutes = 0;
    }
    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  // Converter Horas para minutos e retorna de acordo com timezone
  function convertHTMTimezone(timeInHour) {
    var timeParts = timeInHour.split(':');
    let result;
    result = Number(timeParts[0]) * 60 + Number(timeParts[1]);
    result = result + date.getTimezoneOffset();
    return HoursToMinutes(result);
  }

  function getConvertHTMTimezone(timeInHour) {
    let timeParts = timeInHour.split(':');
    let result;
    const firstNumber = Number(timeParts[0]);
    result = Number(timeParts[0]) * 60 + Number(timeParts[1]);
    result = result - date.getTimezoneOffset();
    return HoursToMinutes(result, firstNumber);
  }

  // Converter Horas para minutos
  function convertHTM(timeInHour) {
    var timeParts = timeInHour.split(':');
    let result;
    result = Number(timeParts[0]) * 60 + Number(timeParts[1]);
    return HoursToMinutes(result);
  }

  const handleChangeTime = (event) => {
    const { name, value } = event.target;
    setTime({ ...time, [name]: value });
  };

  const handleChangeTimeSelected = (event) => {
    const { name, value } = event.target;
    setDataSelect({ ...dataSelect, [name]: value });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
  };

  const handleChangeActive = (event) => {
    const { name, value } = event.target;
    setActive(value);
  };

  const handleDelete = () => {
    Swal.fire({
      title: 'Certeza que excluir esse(s) horário(s)',
      showDenyButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      denyButtonText: `Não`,
    }).then((result) => {
      if (result.isConfirmed) {
      } else if (result.isDenied) {
        Swal.fire('Ok! o horario não foi atualizado', '', 'warning');
        handleCloseModal();
      }
    });
  };

  const handleAdd = () => {
    let temp = {
      start: time.start,
      end: time.end,
      weekdays: personName,
      active: active,
      timezone: Number(FormatMTH(date.getTimezoneOffset())),
    };

    setPersonName([]);
    setData([...data, temp]);

    handleCloseModal();

    api
      .post('/work-shifts/', temp)
      .then((res) => {
        setShifts([...shifts, res.data]);
        Swal.fire({
          title: 'Sucesso',
          text: 'Horário cadastrado com sucesso!',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      })
      .catch((res) => {
        Swal.fire({
          title: 'Erro',
          text: 'Algo deu errado! Tente novamente',
          icon: 'error',
          confirmButtonText: 'OK',
        }).then((_) => window.location.reload());
        console.log('Erro horário', res);
      });
  };

  const handleEdit = () => {
    let temp = {};
    if (personName.length === 0) {
      temp = {
        start: dataSelect.start,
        end: dataSelect.end,
        weekdays: dataSelect.weekdays,
        active: dataSelect.active,
        timezone: Number(FormatMTH(date.getTimezoneOffset())),
      };
    } else {
      temp = {
        start: dataSelect.start,
        end: dataSelect.end,
        weekdays: personName,
        active: dataSelect.active,
        timezone: Number(FormatMTH(date.getTimezoneOffset())),
      };
    }

    setPersonName([]);
    setData([...data, temp]);
    handleCloseModal();

    api
      .put(`/work-shifts/${dataSelect.id}`, temp)
      .then((res) => {
        setShifts(
          shifts.map((item, index) =>
            item.id === dataSelect.id
              ? {
                  ...shifts,
                  start: res.data.start,
                  end: res.data.end,
                  weekdays: res.data.weekdays,
                  active: res.data.active,
                  id: res.data.id,
                }
              : item
          )
        );

        Swal.fire({
          title: 'Sucesso',
          text: 'Horário atualizado com sucesso!',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      })
      .catch((res) => {
        alert('Ocorreu um erro');
        console.log('Erro horário', res);
      });
  };

  useEffect(() => {
    function loadShifts() {
      api.get('/work-shifts').then((res) => {
        setShifts(res.data);
      });
    }
    loadShifts();
  }, [amout]);

  const dayPtBr = (day) => {
    switch (day) {
      case 'sunday':
        return 'Domingo';
      case 'sunday':
        return 'Domingo';
      case 'monday':
        return 'Segunda-Feira';
      case 'tuesday':
        return 'Terça-Feira';
      case 'wednesday':
        return 'Quarta-Feira';
      case 'thursday':
        return 'Quinta-Feira';
      case 'friday':
        return 'Sexta-Feira';
      case 'saturday':
        return 'Sábado';
      default:
        break;
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEdit(false);
    setDataSelect({});
    setTypeModal('');
    setTime({
      start: '',
      end: '',
    });
    setPersonName([]);
  };

  useEffect(() => {
    function handleDelete() {
      if (!submit === false) {
        api
          .delete(`/work-shifts/${dataSelect.id}`)
          .then((_) => {
            Swal.fire({
              title: 'Sucesso',
              text: 'Horário excluido com sucesso!',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((_) => {
              setShifts((shifts) =>
                shifts.filter((item) => item.id !== dataSelect.id)
              );
            });
          })
          .catch((err) => {
            Swal.fire({
              title: 'Erro',
              text: 'Algo deu errado, tente novamente!',
              icon: 'error',
              confirmButtonText: 'OK',
            });
            console.log('Erro delete', err);
          });
      }
    }
    handleDelete();
  }, [submit]);

  const contentModalItem = (
    <div style={modalStyle} className={classes.paperModal}>
      {typeModal === 'create' ? (
        <>
          <div className="form-edit" style={{ marginTop: '40px' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h3>Edite o horário de funcionamento do seu estabelecimento</h3>
              <button
                onClick={handleCloseModal}
                style={{
                  marginLeft: '20px',
                  backgroundColor: '#ccc',
                  border: 'none',
                  borderRadius: '15px',
                  height: '30px',
                  width: '30px',
                  fontSize: '15px',
                  color: '#FFF',
                  cursor: 'pointer',
                }}
              >
                X
              </button>
            </div>
            <div className="form-edit-row-time">
              <div>
                <label>De: </label>
                <TextField
                  onChange={handleChangeTime}
                  defaultValue="00:00"
                  name="start"
                  type="time"
                />

                <label>Até: </label>
                <TextField
                  onChange={handleChangeTime}
                  defaultValue="00:00"
                  name="end"
                  type="time"
                />
              </div>
            </div>

            <div style={{ margin: 0, width: '100%' }}>
              <InputLabel style={{ marginTop: '20px' }}>
                Dia(as) da semana
              </InputLabel>

              <Select
                defaultValue="Escolha os dias da semana"
                multiple
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Name" />}
                displayEmpty="teste"
                style={{ margin: '0 !important', width: '100%' }}
              >
                {names.map((item, index2) => (
                  <MenuItem
                    key={index2}
                    value={item.name}
                    name={item.value}
                    style={getStyles(item.value, personName, theme)}
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Select>

              <div className="form-edit-row">
                <label>Definir status como? </label>
                <select
                  defaultValue={true}
                  onChange={handleChangeActive}
                  name="active"
                  type="text"
                >
                  <option>Escolha uma opção</option>
                  <option value={true}>Ativo</option>
                  <option value={false}>Inativo</option>
                </select>
              </div>
            </div>
          </div>
          <div style={{ textAlign: 'right', marginTop: '30px' }}>
            <button
              className="btn-adc-item"
              onClick={() => {
                Swal.fire({
                  title: 'Certeza que deseja cadastrar esse(s) horário(s)',
                  showDenyButton: true,
                  confirmButtonText: 'Sim',
                  confirmButtonColor: '#3085d6',
                  denyButtonColor: '#d33',
                  denyButtonText: `Não`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    if (time.start && time.end && personName.length > 0) {
                      handleAdd();
                    } else {
                      Swal.fire('Preencha todos os campos!', '', 'warning');
                    }
                  } else if (result.isDenied) {
                    Swal.fire(
                      'Ok! o horario não foi cadastrado',
                      '',
                      'warning'
                    );
                    handleCloseModal();
                  }
                });
              }}
            >
              Adicionar
            </button>
          </div>
        </>
      ) : (
        // ----------------- EDIT -------------------------------------
        <>
          <div className="form-edit">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h3>Edite o horário de funcionamento do seu estabelecimento</h3>
              <button
                onClick={handleCloseModal}
                style={{
                  marginLeft: '20px',
                  backgroundColor: '#ccc',
                  border: 'none',
                  borderRadius: '15px',
                  height: '30px',
                  width: '30px',
                  fontSize: '15px',
                  color: '#FFF',
                  cursor: 'pointer',
                }}
              >
                X
              </button>
            </div>
            <div
              className="form-edit-row-time"
              style={{ justifyContent: 'space-between' }}
            >
              <div>
                <label>De: </label>
                <TextField
                  onChange={handleChangeTimeSelected}
                  disabled={edit === false ? true : false}
                  value={dataSelect.start}
                  name="start"
                  type="time"
                />

                <label>Até: </label>
                <TextField
                  onChange={handleChangeTimeSelected}
                  disabled={edit === false ? true : false}
                  value={dataSelect.end}
                  name="end"
                  type="time"
                />
              </div>
              {edit === false ? (
                <button
                  onClick={() => setEdit(true)}
                  style={{
                    marginLeft: '10px',
                    backgroundColor: 'var(--red)',
                    color: '#FFF',
                    padding: 8,
                    cursor: 'pointer',
                    border: 'none',
                  }}
                >
                  Editar
                </button>
              ) : (
                <button
                  onClick={handleCloseModal}
                  style={{
                    marginLeft: '10px',
                    backgroundColor: 'var(--red)',
                    color: '#FFF',
                    padding: 8,
                    cursor: 'pointer',
                    border: 'none',
                  }}
                >
                  Cancelar
                </button>
              )}
            </div>

            <div style={{ margin: 0, width: '100%' }}>
              <div
                className="form-edit-row-time"
                style={{ marginTop: '20px', justifyContent: 'space-between' }}
              >
                <InputLabel>Dia(as) da semana</InputLabel>
              </div>

              {edit === false ? (
                <ul
                  style={{
                    listStyle: 'none',
                    display: 'flex',
                    padding: 8,
                    backgroundColor: '#EEE',
                    borderRadius: 8,
                    width: '100%',
                  }}
                >
                  {typeModal === 'edit'
                    ? dataSelect.weekdays.map((item, index) => (
                        <li key={index} style={{ marginLeft: '10px' }}>
                          {dayPtBr(item)}
                        </li>
                      ))
                    : null}
                </ul>
              ) : (
                <>
                  <ul style={{ listStyle: 'none' }}>
                    {typeModal === 'edit'
                      ? dataSelect.weekdays.map((item, index) => (
                          <li key={index}>{dayPtBr(item)}</li>
                        ))
                      : null}
                  </ul>
                  <Select
                    defaultValue="Escolha os dias da semana"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    defaultChecked={true}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={MenuProps}
                    style={{ marginTop: '10px', width: '100%' }}
                  >
                    {names.map((item, index2) => (
                      <MenuItem
                        key={index2}
                        value={item.name}
                        name={item.value}
                        style={getStyles(item.value, personName, theme)}
                      >
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </div>

            <div className="form-edit-row">
              <label onClick={() => console.log(dataSelect)}>
                Definir status como?{' '}
              </label>
              <select
                disabled={edit === false ? true : false}
                defaultValue={dataSelect.active}
                onChange={handleChangeTimeSelected}
                name="active"
                type="text"
              >
                <option>Escolha uma opção</option>
                <option value={true}>Ativo</option>
                <option value={false}>Inativo</option>
              </select>
            </div>
          </div>
          <div style={{ textAlign: 'right', marginTop: '30px' }}>
            {edit === false ? null : (
              <button
                className="btn-adc-item"
                style={{
                  backgroundColor: 'var(--orange)',
                  border: 'none',
                  color: '#FFF',
                }}
                onClick={handleEdit}
              >
                Atualizar
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'left',
            flexDirection: 'column',
          }}
        >
          <div style={{ textAlign: 'right' }}>
            <button
              onClick={() => {
                setTypeModal('create');
                handleOpenModal();
              }}
              style={{ marginTop: '1rem' }}
              className="btn-adc-item"
            >
              <span>+</span>Adicionar Horário
            </button>
          </div>
          <MaterialTable
            elevation={0}
            columns={[
              {
                title: 'Dia da semana',
                render: (rowData) =>
                  rowData.weekdays.map((item) => `${dayPtBr(item)} \n `),
              },
              { title: 'Início', field: 'start' },
              { title: 'Termino', field: 'end' },
              {
                title: 'Ativo?',
                render: (rowData) =>
                  rowData.active === true ? 'Ativo' : 'Inativo',
              },
            ]}
            data={shifts}
            title="Hórarios de Funcionamento"
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}',
                labelRowsSelect: 'Linhas',
              },
              toolbar: {
                searchPlaceholder: 'Pesquisar',
                nRowsSelected: '{0} linha(s) selecionada(s)',
              },
              header: {
                actions: 'Ações',
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
              },
            }}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Visualizar horário',
                onClick: (_, rowData) => {
                  setDataSelect({
                    id: rowData.id,
                    start: rowData.start,
                    end: rowData.end,
                    weekdays: rowData.weekdays,
                    active: rowData.active,
                  });
                  setTypeModal('edit');
                  handleOpenModal();
                  //setPersonName();
                },
              },
              {
                icon: 'delete',
                tooltip: 'Deletar horário',
                onClick: (_, rowData) => {
                  setDataSelect(rowData);
                  setAction(true);
                },
              },
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />

          {action === true ? (
            <Alert
              Title="Tem certeza que deseja excluir esse horário?"
              SubTitle="Clicando em Sim, os dados enviados irão alterar. Para corrigir é so repetir o processo"
              onClose={() => setAction(false)}
              onSubmit={() => setSubmit(true)}
            />
          ) : null}
        </div>

        <Modal open={openModal} onClose={handleCloseModal}>
          {contentModalItem}
        </Modal>
      </Paper>
    </>
  );
};

export default HoursOfOperation;
