import React from 'react'
import './style.css'

export default function CardPedido({ title, message, icon }) {
  return (
    <div className="title-section">
      <div className="title-section-icon">
        <div>
          {icon}
        </div>
      </div>
      <div>
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
    </div>
  )
}