import React, { useState } from 'react'
import './styles.css'

import { history } from '../../history'
import api from '../../utils/services';

export default function ForgotSecond() {

  const TEMPO_TOTAL = 60;

  let [tempoRestante, setTempoRestante] = useState(TEMPO_TOTAL)
  let email = window.localStorage.getItem('email');


  const startTimer = () => {
    setTimeout(() => {
      if (tempoRestante > 0) {
        setTempoRestante(tempoRestante -= 1);
      }
    }, 1000)
  }

  const formataTempo = (time) => {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  }

  startTimer()

  const [codRecovery, setCodRecovery] = useState('')


  const handleResend = () => {
    let data = {
      email: email
    }
    api.post('/mails/send-recovery-password-code', data)
      .then(_ => {
        setTempoRestante(TEMPO_TOTAL)
        alert('Código reenviado!')
      })
      .catch(_ => {
        alert('Algo deu errado, tente novamente mais tarde');
      })
  }

  const handleValidate = () => {

    if(codRecovery === ""){
      return alert("Preencha o campo!")
    }

    let data = {
      code: String(codRecovery),
      email: email
    }

    api.post('/auth/validate-recovery-password-code', data)
      .then(res => {
        alert('Ok, vamos para o próximo passo :)')
        window.localStorage.setItem('id', res.data.id);
        history.push('/recuperar-senha3')
      })
      .catch(err => {
        alert('Algo deu errado, tente novamente mais tarde');
        console.log('Erro ao validar o código', err)
      })
  }

  return (
    <div className="container-forgot">
      <div className='content-forgot' >
        <div className="title-forgot-password">
          <h1>Escolha uma opção</h1>
          <p>Forneça o e-mail da sua conta para a <br></br> qual você montou para redefinir sua senha</p>
        </div>


        <input className='input-cod-recovery' name='codrecovery' onChange={(ev) => setCodRecovery(ev.target.value)} />


        <div className='container-cronometro' >
          <span>{formataTempo(tempoRestante)}</span>
          {
            tempoRestante === 0
              ? <p onClick={handleResend} style={{ color: 'var(--primary-cor)', cursor: 'pointer' }} >Reenviar código</p>
              : <p style={{ color: '#2E2E2E' }} >Reenviar código</p>
          }
        </div>

        <button onClick={handleValidate} className="btn-forgot-next-step">Próximo</button>
      </div>
    </div>
  )
}