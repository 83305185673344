const formatStatus = (status) =>{
    const statusFormated = {
      awaitingPayment: 'Aguardando pagamento',
      pending: 'Pendente',
      prepared: 'Em preparo',
      inDelivery: 'Saiu para entrega',
      delivered: 'Entregue',
      canceled: 'Cancelado',
      inReturned: 'Em retorno',
      returned: 'Devolvido',
    }
    if(statusFormated[status]){
      return statusFormated[status];
    }
  }

  export default formatStatus;