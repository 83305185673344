import React,{useState} from 'react'
import './styles.css'

import IconStepOne from '../../assets/forgot-step-one.png'
import MailOutline from '@material-ui/icons/MailOutline';

import { history } from '../../history'
import api from '../../utils/services';

export default function ForgotOne(){

  const [email, setEmail] = useState('')

  const handleSubmit = () =>{
    
    let data = {
      email: email
    }
    api.post('/mails/send-recovery-password-code', data)
    .then( _ => {
      window.localStorage.setItem('email', email);
      history.push('/recuperar-senha2')
    })
    .catch( _ => {
      alert('Algo deu errado, tente novamente mais tarde');
      setEmail('')
    })
  }

  return(
    <div className="container-forgot">

      <div className='content-forgot' >
      <div className="icon-box">
        <img src={IconStepOne} alt="" />
      </div>

      <div className="title-forgot-password">
        <h1>Esqueceu sua senha?</h1>
        <p>Digite o e-mail da sua conta de cadastro <br></br> para redefinir sua senha</p>
      </div>
     
      <div className="input-forgot-password">
        <label htmlFor="">E-mail</label>
        <div>
          <MailOutline style={{color: '#414141'}} />
          <input onChange={ (ev) => setEmail(ev.target.value)}  type="email" placeholder="Digite seu email" />
        </div>
      </div>

      <button onClick={handleSubmit} className="btn-forgot-next-step">Próximo</button>
      </div>
      
    </div>
  )
}