import React, { useCallback, useRef, useState } from 'react';

import './styles.css';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { Paper } from '@material-ui/core';

import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
// import CardPedido from '../../../components/cardPedido';
import ObsSection from '../../../components/obsSection';

import { history } from '../../../history';
import { useEffect } from 'react';

// import ModalAlert from '../../../components/modal/Alert'
import ContentModal from './components/ContentModal.js';
import api from '../../../utils/services';

import { io } from 'socket.io-client';
import { getId, myToken } from '../../../utils/auth';
import { format } from 'date-fns';
import { env } from '../../../env';
import formatStatus from '../../../common/formatstatus';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 0,
    height: '100vh',
    overflow: 'auto',
    padding: '0',
    width: '100vw',
    backgroundColor: '#f5f4f6',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paperModal: {
    position: 'absolute',
    width: 800,
    maxHeight: '100vh',
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    // borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow: 'auto',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function Pedidos() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [pedidos, setPedidos] = useState();
  const [pedidoSelect, setPedidoSelect] = useState([]);

  const [pageSelected, setPageSelected] = useState(1);
  const limitePage = 10;
  const [totalPages, setTotalPages] = useState(0);

  const handlePagination = (action) => {
    if (action === 'add' && pageSelected + 1 <= totalPages) {
      api
        .get(
          `/sales/requests?page=${
            pageSelected + 1
          }&limit=${limitePage}&isHistoryRequests=false`
        )
        .then((res) => {
          let newArray = res.data.requests;
          newArray.map((item) => (item.date = new Date(item.date)));
          setPedidos(newArray);
        })
        .catch((error) => {
          console.log('error', error.response);
          if (error.response.status === 401) {
            alert('Sua sessão expirou, faça o Login novamente');
            //logout();
            window.location.reload();
          }
        });
    } else if (action === 'prev' && pageSelected + 1 >= 1) {
      api
        .get(
          `/sales/requests?page=${
            pageSelected - 1
          }&limit=${limitePage}&isHistoryRequests=false`
        )
        .then((res) => {
          let newArray = res.data.requests;
          newArray.map((item) => (item.date = new Date(item.date)));
          setPedidos(newArray);
        })
        .catch((error) => {
          console.log('error', error.response);
          if (error.response.status === 401) {
            alert('Sua sessão expirou, faça o Login novamente');
            //logout();
            window.location.reload();
          }
        });
    } else {
      alert('Não existe');
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPedidoSelect({});
  };

  const socketRef = useRef();

  /*
  const userId = "036d2d38-746b-4dcb-bbdf-dfe73a378022";
  
  // socket.emit("msgToServer", "Mensagem teste socket client");
  socket.on(`client@${userId}`, (data) => console.log(data));
*/

  const initSocket = useCallback(() => {
    const id = getId();
    const token = myToken();
    if (id && token) {
      socketRef.current = io(`${env().REACT_APP_API_URL}/socket`, {
        transports: ['polling'],
        reconnectionDelayMax: 10000,
        auth: {
          token: `Bearer ${token}`,
        },
      });
      socketRef.current.on(`update-list-request@${id}`, (data) => {
        data.date = new Date(data.date);
        setPedidos([data, ...pedidos]);
      });
    }
  }, [setPedidos, pedidos]);

  useEffect(() => {
    initSocket();
    return () => {
      if (socketRef.current) socketRef.current.disconnect();
    };
  }, [initSocket]);

  useEffect(() => {
    function loadProducts() {
      api
        .get(
          `/sales/requests?page=1&limit=${limitePage}&isHistoryRequests=false`
        )
        .then((res) => {
          setTotalPages(Math.ceil(res.data.count / limitePage));
          let newArray = res.data.requests;
          newArray.map((item) => (item.date = new Date(item.date)));
          setPedidos(newArray);
        })
        .catch((error) => console.log('Erro', error));
    }
    loadProducts();
  }, []);

  return (
    <div className={classes.root}>
      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <ObsSection
            icon={<LibraryBooksIcon />}
            title="Responda seus pedidos!"
            message="Nessa seção, você pode visualizar todos os pedidos que chegaram, ou listar todos pedidos já feito."
          />

          <div style={{ textAlign: 'right' }}>
            <button
              onClick={() => history.push('./todos-pedidos')}
              style={{ marginBottom: '1rem' }}
              className="btn-adc-item"
            >
              Histórico de pedidos
            </button>
          </div>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: '20px 5px' }}>
                <MaterialTable
                  elevation={0}
                  columns={[
                    { title: 'Código', field: 'orderNumber' },
                    { title: 'Cliente', field: 'clientName' },
                    {
                      title: 'Data',
                      field: 'date',
                      render: (rowData) =>
                        format(rowData.date, 'dd/MM/yyyy - HH:mm'),
                    },
                    {
                      title: 'Retirada',
                      field: 'delivery',
                      render: (rowData) =>
                        rowData.delivery ? 'Delivery' : 'Retirada em loja',
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      render: (rowData) => formatStatus(rowData.status),
                    },
                  ]}
                  data={pedidos}
                  title="Pedidos"
                  localization={{
                    pagination: {
                      labelDisplayedRows: '{from}-{to} de {count}',
                      labelRowsSelect: 'Linhas',
                    },
                    toolbar: {
                      searchPlaceholder: 'Pesquisar',
                      // nRowsSelected: '{0} linha(s) selecionada(s)',
                    },
                    header: {
                      actions: 'Ações',
                    },
                    body: {
                      emptyDataSourceMessage:
                        'Não há registros a serem exibidos ',
                    },
                  }}
                  actions={[
                    {
                      icon: 'visibility',
                      tooltip: 'Visualizar pedido',
                      onClick: (event, rowData) => {
                        setPedidoSelect(rowData);
                        handleOpen();
                      },
                    },
                  ]}
                  options={{
                    hideFilterIcons: true,
                    actionsColumnIndex: -1,
                    paging: false,
                  }}
                />
                <div className="pagination-container">
                  <div className="pagination-content">
                    <button
                      disabled={pageSelected - 1 >= 1 ? false : true}
                      onClick={() => {
                        setPageSelected(pageSelected - 1);
                        handlePagination('prev');
                      }}
                    >
                      Anterior
                    </button>
                    <input
                      style={{ textAlign: 'center' }}
                      type="text"
                      value={pageSelected}
                    />
                    <input
                      style={{ textAlign: 'center', backgroundColor: '#DDD' }}
                      disabled
                      type="text"
                      value={totalPages}
                    />
                    <button
                      disabled={pageSelected + 1 <= totalPages ? false : true}
                      onClick={() => {
                        setPageSelected(pageSelected + 1);
                        handlePagination('add');
                      }}
                    >
                      Próximo
                    </button>
                  </div>
                </div>
              </Paper>
              {open && (
                <ContentModal
                  open={open}
                  handleClose={handleClose}
                  data={pedidoSelect}
                  salesTable={pedidos}
                  setSalesTable={setPedidos}
                />
              )}
            </Grid>
          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}
