import React from 'react'
import './styles.css'

import Finished from '../../assets/uil_comment-verify.png';


import { history } from '../../history'

export default function ForgotSecond() {

 const handleLogin = () =>{
  window.localStorage.removeItem('email')
  window.localStorage.removeItem('id')
  history.push('/login')
 }


  return (
    <div className="container-forgot">
      <div className='content-forgot'>

        <div>
        <div className="icon-box">
          <img src={Finished} alt="" />
        </div>

        <div className="title-forgot-password">
          <h1>Senha Atualizada</h1>
          {/* <p style={{width: '50%', marginLeft: 'auto', marginRight: 'auto'}} >Forneça o e-mail da sua conta para a qual você montou para redefinir sua senha</p> */}
          <p >sua senha foi atualizada com sucesso!</p>
        </div>
        </div>


        <button onClick={handleLogin} className="btn-forgot-next-step">Login</button>
      </div>
    </div>
  )
}