const isLogged = () => !!localStorage.getItem('token');
const getToken = () => !!localStorage.getItem('token');
const myToken = () => {
  const token = localStorage.getItem('token');
  const newToken = token?.slice(1, -1);
  return newToken;
};

const getId = () => localStorage.getItem('id');
const getLogged = () => localStorage.getItem('isLogged');

const login = (token) => {
  localStorage.setItem('token', JSON.stringify(token));
  localStorage.setItem('isLogged', true);
};

const getTokenFirebase = () => {
  const token = localStorage.getItem('tn');
  return token;
};

const logout = () => {
  localStorage.clear(`token`);
  localStorage.removeItem(`id`);
  localStorage.setItem('isLogged', false);
};

export {
  isLogged,
  login,
  logout,
  getToken,
  getId,
  myToken,
  getLogged,
  getTokenFirebase,
};
