import React, {useState} from 'react'
import './styles.css'

import SmileWink from '../../assets/fa-regular_smile-wink.png';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { history } from '../../history'
import api from '../../utils/services';

export default function ForgotSecond(){
 
  const [password, setPassword] = useState('')
  const [confirmationPassword, setConfimationPassword] = useState('')

  const [isVisibility, setIsVisibility] = useState(true)

  let id = window.localStorage.getItem('id');

  const handleSubmit = () =>{

    if(password === confirmationPassword){
      let data = {
        password: password,
        id: id,
      }
  
      api.post('/auth/reset-password', data)
      .then( _ => {
        alert('Tudo certo, senha renovada!')
        history.push('/recuperar-senha4')
      })
      .catch( err => {
        alert('Algo deu errado, tente novamente mais tarde');
        console.log('Erro ao recuperar a senha', err)
      })
    }else{
      alert('As senhas devem ser iguais')
    }

  }

  return(
    <div className="container-forgot">

      <div className='content-forgot'>

      <div className="icon-box">
        <img src={SmileWink} alt="" />
      </div>

      <div className="title-forgot-password">
        <h1>Nova Senha</h1>
        <p >Forneça o e-mail da sua conta<br></br>para a qual você montou para<br></br>redefinir sua senha</p>
      </div>
     
      <div style={{marginTop: '30px'}} className="input-forgot-password">
        <label htmlFor="">Senha</label>
        <div>
        {
            isVisibility 
            ?
            <Visibility onClick={() => setIsVisibility(false)} style={{color: '#414141'}} />
            :
            <VisibilityOff onClick={() => setIsVisibility(true)} style={{color: '#414141'}} />
          }
          <input  onChange={(ev) => setPassword(ev.target.value)} type={isVisibility ? "password" : "text"} placeholder="Digite sua senha" />
        </div>
      </div>

      <div style={{marginTop: '30px'}}  className="input-forgot-password">
        <label htmlFor="">Confirme sua senha</label>
        <div>
          {
            isVisibility 
            ?
            <Visibility onClick={() => setIsVisibility(false)} style={{color: '#414141'}} />
            :
            <VisibilityOff onClick={() => setIsVisibility(true)} style={{color: '#414141'}} />
          }
          <input onChange={(ev) => setConfimationPassword(ev.target.value)} type={isVisibility ? "password" : "text"} placeholder="Digite sua senha" />
        </div>
      </div>

      <button onClick={handleSubmit} className="btn-forgot-next-step">Próximo</button>


      </div>


     
    </div>
  )
}