import React from 'react'
import { Box, Container, Grid } from '@material-ui/core';
import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import { makeStyles } from '@material-ui/core/styles';

import ObsSection from '../../../components/obsSection';

import PhotoCamera from '@material-ui/icons/PhotoCamera';

import CardInfo from '../../../components/card-info';
import { CameraAlt, Collections } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 0,
    height: '100vh',
    overflow: 'auto',
    padding: '0',
    width: '100vw',
    backgroundColor: '#f5f4f6',
    // backgroundColor: '#F4F5F7',
  },
  titleContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: 'row',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

}));

const Story = () => {

  const classes = useStyles();


  return (
    <div className={classes.root}>

      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

          <ObsSection
            icon={<PhotoCamera />}
            title="Story"
            message="Adicione story no perfil da sua loja para aumentar a conversão de clientes"
          />


          <Grid container spacing={3}>
            <Grid item xs={12}>

            <Container maxWidth="lg"  style={{marginTop: '60px'}} >
            <Grid spacing={2}  container style={{gap: '10px'}} >
              <CardInfo 
              grid="3"
              title="Postar um story"
              description="Adicione um novo story no seu estabelecimento"
              bgIcon="#EA5B92"
              navigation="/story/postar-storys"
              icon={<CameraAlt style={{color: "#FFF", width: "20px"}} />}
              />
              <CardInfo
               grid="3" 
               title="Listar story"
               description="Veja qual story está no ar e exclua se quiser"
               bgIcon="#16C2A4"
               navigation="/story/listar-storys"
               icon={<Collections style={{color: "#FFF",  width: "20px"}} />}
               />
            </Grid>
            </Container>
            </Grid>

          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}

export default Story;




