import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Paper } from '@material-ui/core';
import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import { makeStyles } from '@material-ui/core/styles';

import Modal from '@material-ui/core/Modal';

import MaterialTable from 'material-table';

import ProductsJSON from './Produtos.json';
import DefaultProfile from '../../../assets/default-profile.png';

import './styles.css';

// Modal --------

function getModalStyle() {
  const top = 50;
  const left = 49;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

// End Modal --------

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    height: '100vh',
    overflow: 'auto',
    width: '100vw',
    backgroundColor: '#f5f4f6',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    minWidth: 650,
  },
  btnRegister: {
    border: 'none',
    outline: 'none',
    backgroundColor: '#1976d2',
    padding: '8px',
    fontWeight: 600,
    color: '#FFF',
    cursor: 'pointer',
  },
  paperModal: {
    position: 'absolute',
    width: '40%',
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  btnActions: {
    marginRight: 5,
    border: 'none',
    outline: 'none',
    padding: 5,
    color: '#FFF',
    cursor: 'pointer',
  },
}));

const Produtos = () => {
  const [products, setProducts] = useState([]);
  const [productSelected, setProductSelected] = useState({});

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [openVisualizer, setVisualizer] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenVisualizer = () => {
    setVisualizer(true);
  };
  const handleCloseVisualizer = () => setVisualizer(false);

  useEffect(() => {
    async function loadProducts() {
      // const response = await ProductsJSON.length;
      setProducts(ProductsJSON);
    }
    loadProducts();
  }, []);

  const classes = useStyles();

  const contentModal = (
    <div style={modalStyle} className={classes.paperModal}>
      <h2 id="simple-modal-title">Text in a modal</h2>
      <p id="simple-modal-description">
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </p>
    </div>
  );

  const contentModalVisualizer = (
    <div style={modalStyle} className={classes.paperModal}>
      {/* nome preço descrição imagem(3) */}
      {productSelected && (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <h2 id="simple-modal-title">Produto</h2>
            <button
              onClick={handleCloseVisualizer}
              className="button-modal-closed"
            >
              X
            </button>
          </div>
          <p id="simple-modal-description">
            Visualizar informação do seu produto
          </p>

          <div className="img-product-container">
            <img src={DefaultProfile} alt="Imagem default" />
            <img src={DefaultProfile} alt="Imagem default" />
            <img src={DefaultProfile} alt="Imagem default" />
          </div>

          <div className="info-product-container">
            <h3>
              <strong>Nome:</strong> {productSelected.name}
            </h3>
            <h3>
              <strong>Descrição:</strong> {productSelected.type}
            </h3>
            <h3>
              <strong>Preço:</strong> {productSelected.prince}
            </h3>
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className={classes.root}>
      <MenuAdmin />

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Grid item sm={12}>
              <Paper elevation={0} className={classes.paper}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <h2 style={{ marginBottom: 20 }}>Listagem de Produtos</h2>
                  <button className={classes.btnRegister} onClick={handleOpen}>
                    Cadastrar um novo produto
                  </button>
                </div>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {contentModal}
                </Modal>
                <Modal
                  open={openVisualizer}
                  onClose={handleCloseVisualizer}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {contentModalVisualizer}
                </Modal>
                <MaterialTable
                  columns={[
                    { title: 'Nome', field: 'name' },
                    { title: 'Preço', field: 'prince' },
                    { title: 'Tipo', field: 'type' },
                  ]}
                  data={products}
                  title="Produtos"
                  actions={[
                    {
                      icon: 'visibility',
                      tooltip: 'Visualizar produto',
                      onClick: (event, rowData) => {
                        handleOpenVisualizer();
                        setProductSelected(rowData);
                      },
                    },
                    {
                      icon: 'edit',
                      tooltip: 'Editar produto',
                      onClick: (event, rowData) =>
                        alert('You saved ' + rowData.name),
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Excluir produto',
                      onClick: (event, rowData) =>
                        alert('You saved ' + rowData.name),
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                  }}
                />
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default Produtos;
