
import React, { useState } from 'react'
import { Box, Container, Grid } from '@material-ui/core';
import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import { makeStyles } from '@material-ui/core/styles';

import './styles.css'

import planIcon from '../../../assets/plan-icon.png'
import ModalCard from '../../../components/modalCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 0,
    height: '100vh',
    overflow: 'auto',
    padding: '0',
    width: '100vw',
    backgroundColor: '#f5f4f6',
    // backgroundColor: '#F4F5F7',
  },
  titleContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: 'row',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),

  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

}));


const ServicePlans = () => {

  const classes = useStyles();
  const [open, setOpen] = useState(false)

  return (
    <div className={classes.root}>

      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Container maxWidth="lg" style={{ marginTop: '60px' }} >
                  <div className="service-plans-container">
                    <div className="service-plans-content">
                      <div className="service-plan-header-card">
                        <img src={planIcon} alt="icone sobre o plano" />
                        <h1>Nosso diferencial</h1>
                      </div>

                      <p>Disponibilizar os comércios e prestadores de serviços locais a poucos cliques dos consumidores. Dessa forma, ter a oportunidade de estar presente onde todos estão. A partir disso, levar uma nova experiência de
                      <span> divulgação, vendas, compras, solicitação e gerenciamento das vendas</span>
                      </p>

                      <div className="service-plans-prince-container">
                      <div className='service-plans-prince'>
                        <span>R$</span>
                        <h1>11<span>,99</span></h1>
                      </div>
                      <span>mensal</span>
                      </div>
                      

                      <button onClick={() => setOpen(true)} className='serice-plans-btn'>Contratar</button>
                      <ModalCard open={open} setOpen={setOpen} />
                    </div>
                  </div>
              </Container>
            </Grid>

          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  )
}

export default ServicePlans