import {useEffect, useState } from 'react'

import { Box, Container, Grid, Paper } from '@material-ui/core';
import React from 'react'
import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import { makeStyles } from '@material-ui/core/styles';
import api from '../../../utils/services';

import { useParams } from 'react-router-dom/';

import { getId} from '../../../utils/auth'

import './styles.css'
import { history } from '../../../history';
import Alert from '../../../components/modal/Alert';
import IntlCurrencyInput from "react-intl-currency-input";
import loadingGif from '../../../assets/loading.gif'

import Swal from 'sweetalert2';



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 0,
        height: '100vh',
        overflow: 'auto',
        padding: '0',
        width: '100vw',
        backgroundColor: '#f5f4f6',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),

    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        width: '100%',
        overflow: 'auto',
        flexDirection: 'column',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },

}));

const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      }
    }
  };


const ActionsProduct = () => {

  const [loading, setLoading] = useState(false)


    const MAX_LENGTH = 4;

    let { id } = useParams();
    const classes = useStyles();

    const [info, setInfo] = useState(false);
    const [action, setAction] = useState(false);
    const [submit, setSubmit] = useState(false)

    const [categorias, setCategorias] = useState([])
    const [categoryResponse, setCategoryResponse] = useState("")

    const [valuesProduct, setValuesProduct] = useState();
    const [photosProduct, setPhotosProduct] = useState([])

    const [dataProduct, setDataProduct] = useState([])

    const [currency, setCurrency] = useState(0);
    const handleChangeCurrency = (event, value, maskedValue) => {
      event.preventDefault();
      
      setCurrency(value)
      
      setValuesProduct({ ...valuesProduct, 'price': value.toString() })
     // console.log(value); // value without mask (ex: 1234.56)
      // console.log(maskedValue); // masked value (ex: R$1234,56)
    };

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [images, setImages] = useState([])
    const [imagesUpload, setImagesUpload] = useState([])

    const [imagesSalved, setImagesSalved] = useState([])

    const handleChangeProduct = (event) => {
        const { name, value } = event.target;
        setValuesProduct({ ...valuesProduct, [name]: value })
    };

    const handleCategory = (type) =>{

        if(type === "Escolha uma opção"){
            Swal.fire({
                title: 'Atenção!',
                text: 'Você não pode escolher essa opção. Escolha uma válida',
                icon: 'warning',
                confirmButtonText: 'OK'
         })
        }
    }

  //------------- Previw de images -----------
  const handleImageChange = (e) => {

    if (Array.from(e.target.files).length > (MAX_LENGTH - photosProduct.length)) {
      e.preventDefault();
      alert(`Não pode ter mais de ${MAX_LENGTH} imagens`);
      return;
    }
      let arr = imagesUpload
     
      const filesArrayUpload = Array.from(e.target.files).map( item => arr.push(item))
      setImagesUpload(arr)

		if (e.target.files) {
			const filesArray = Array.from(e.target.files).map((file) => URL.createObjectURL(file));


			setSelectedFiles((prevImages) => prevImages.concat(filesArray));
			Array.from(e.target.files).map(
				(file) => {
          setImages(file)
          
          URL.revokeObjectURL(file)} // avoid memory leak
			);
		}
   
	};

	const deletePhotos = (index) =>{

        let newArray = selectedFiles.filter((item, Nindex) => Nindex !== index)
		setSelectedFiles(newArray)
	}
	const deletePhotosProduct = (index) =>{
        let newArray = photosProduct.filter((item, Nindex) => Nindex !== index)

        setPhotosProduct(newArray)   
	}

	const renderPhotos = (source) => {
		return source.map((photo, index) => {
			return(
				<div className='img-previw-container' key={index}>
					<img src={photo} alt="" key={photo} />;
					<button onClick={() => deletePhotos(index) } >X</button>
				</div>
			)
		})}
	const renderPhotosBefore = (source) => {
		return source.map((photo, index) => {
			return(
				<div className='img-previw-container' key={photo.id}>
					<img src={photo.url} alt=""  />;
					<button onClick={() => deletePhotosProduct(index) } >X</button>
				</div>
			)
		})}
  // ---------- End Previw de images ---------

    const handleSubmitFiles = () =>{
            let formData = new FormData();

            Object.entries(imagesUpload).map(([key, value]) => {
                return formData.append('files', value)
            })
            
            api.post('/files', formData).then( res =>{
              

                let responseArray = [];
                for(let i = 0; i<res.data.length; i++){
                    responseArray[i] = res.data[i].url;
                }
               
                
                setImagesSalved(res.data.url)
                 // let imgBefore = dataProduct.images.map(item => item.url)
                 
                 let imgBefore = photosProduct.map(item => item.url)
                 let newArray = imgBefore.concat(responseArray)
         
                 let objImages = {
                     images: newArray
                 }
         
                 let objValues = valuesProduct;
         
                 const newObj = { ...objImages, ...objValues };
         
               
               
                 api.put(`/announcements/${dataProduct.id}`, newObj).then( res => {
                 //  history.push('/catalogo')
                    setLoading(false)
                    Swal.fire('Pronto! Anúncio atualizado com sucesso!').then( _ =>  window.location.pathname = "/catalogo" )  
                 }).catch( err => {
                    setLoading(false)
                    Swal.fire({
                        title: 'Erro',
                        text: 'Não foi possível publicar uma nova imagem, tente novamente!',
                        icon: 'error',
                        confirmButtonText: 'OK'
                      })
                })
                  setSubmit(false)
               
                
              })
              .catch( error =>{
                setLoading(false)
                alert('Imagem não cadastrado')
                setSubmit(false)
              })  
    }

    // ************ Atualizar Produto ********

    const handleSubmit = () =>{
    setLoading(true)


    // Caso houver upload de imagem
    if(imagesUpload.length > 0){

        // Validar quantidade de imagem
        if(photosProduct.length + imagesUpload.length > 4){
            Swal.fire({
                title: 'Atenção',
                text: 'Seu produto não pode ter mais de 4 imagens no total',
                icon: 'warning',
                confirmButtonText: 'OK'
              })
              setLoading(false)
        }else{
            handleSubmitFiles();
        }
        // Caso não ouver upload de imagem nem imagem no produto
      }else if(imagesUpload.length === 0 && photosProduct.length ===0){
        //let imgBefore = photosProduct.map(item => item.url)

 
        let objImages = {
            images: ["https://logoali-bucket.s3.sa-east-1.amazonaws.com/1672097992097-default-produto.png"]
        }
        const newObj = { ...objImages, ...valuesProduct };

        
        api.put(`/announcements/${dataProduct.id}`, newObj).then( res => {
            // history.push('/catalogo')
            setLoading(false)
            Swal.fire('Pronto! Anúncio atualizado com sucesso!').then( _ =>  window.location.pathname = "/catalogo" )  
        }).catch( err => {
            Swal.fire({
                title: 'Erro',
                text: 'Não foi possível publicar uma nova imagem, tente novamente!',
                icon: 'error',
                confirmButtonText: 'OK'
              })
            setLoading(false)
        })
         setSubmit(false)
    
      }else{
        // Caso não ter interação com imagens

        let imgBefore = photosProduct.map(item => item.url)

        let objImages = {
            images: imgBefore
        }
        const newObj = { ...objImages, ...valuesProduct };

        
        api.put(`/announcements/${dataProduct.id}`, newObj).then( res => {
            // history.push('/catalogo')
            setLoading(false)
            Swal.fire('Pronto! Anúncio atualizado com sucesso!').then( _ =>  window.location.pathname = "/catalogo" )  
        }).catch( err => {
            Swal.fire({
                title: 'Erro',
                text: 'Não foi possível publicar uma nova imagem, tente novamente!',
                icon: 'error',
                confirmButtonText: 'OK'
              })
            setLoading(false)
        })
         setSubmit(false)

      }

 
    }


    /*
    const handleSubmitImages = () =>{
        let formData = new FormData();

        Object.entries(imagesUpload).map(([key, value]) => {
            return formData.append('file', value)
        })
    }
    const handleSubmitProducts = () =>{
        if(imagesUpload.length >0){
            handleSubmitImages();
        }
    }
    */

    useEffect(() => {
        function loadCategorias(){
            api.get(`/category-products-services/establishment/${getId()}`).then(res => {setCategorias(res.data)
            })
        //    .catch(err => console.log('Erro categorias', err))
        }
         function loadProduct() {
             api.get(`/announcements/${id}`).then(res => {
                setDataProduct(res.data);
                setPhotosProduct(res.data.images)
                setCategoryResponse(res.data.category.name)
                // setSelectedFiles(res.data.images)
            })
            .catch(_ => Swal.fire({
                titleText: 'Erro ao carregar a categoria',
                icon: 'error',
            }).then( _ => window.location.pathname = '/catalogo'))
        }
        loadProduct();
        loadCategorias();
        
    }, [])

    return (
        <div className={classes.root}>

            {
                loading ?
                <div className='loading-container'>
                    <img src={loadingGif} alt="Gif de carregamento" />
                </div>
                :
                null
             }

            <MenuAdmin />
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={1}>
                                {/* Actions Button  */}
            <Paper elevation={0} className={classes.paper} >

              {action === true ?
                <Alert
                  Title="Tem certeza que deseja alterar essas informações?"
                  SubTitle="Clicando em Sim, os dados enviados irão alterar. Para corrigir é so repetir o processo"
                  onClose={() => setAction(false)} onSubmit={() => setSubmit(true)} /> : null}
                  

              <div className="form-edit">
                <div style={{ justifyContent: 'space-between', alignItems: 'center' }} className="form-edit-row">
                  <h2>Editar Informações</h2>
                  {
                    info === false ?
                      <button onClick={() => setInfo(true)} style={{ backgroundColor: '#4A78FF' }} className="btn-action-edit">Editar</button>
                      :
                      <div >
                        <button onClick={() => setInfo(false)} style={{ backgroundColor: '#E8505B' }} className="btn-action-edit" >Cancelar</button>
                      </div>

                  }
                </div>
              </div>
            </Paper>
                        
            <Paper elevation={0} style={{marginTop: '30px'}} className={classes.paper} >
            {
                Object.keys(dataProduct).length === 0 
                ? <p>carregando...</p>
                :
                <div className={classes.paperModal}>
                <div style={{display:'flex', justifyContent: 'space-between'}} >
                    <div>
                        <h2 id="simple-modal-title">Visualize seu produto</h2>
                        <p id="simple-modal-description">
                            Realize a edição do seu produto da forma que quiser.
                        </p>
                    </div>
                    <button onClick={() => history.goBack()} style={{marginLeft: 'auto', fontWeight: 'bold', cursor: 'pointer', border: 'none', outline: 'none', backgroundColor: 'transparent'}}  >
                        Voltar
                    </button>
                </div>

                <div className="form-modal-products">
                    <div>
                        <div className="modal-products-container">
                            <h4>Tipo do Anúncio</h4>
                            <select onBlur={() => valuesProduct?.type ?  handleCategory(valuesProduct.type) : null } disabled={!info} defaultValue={dataProduct.type}  onChange={handleChangeProduct} name="type" type="text">
                                <option> Escolha uma opção</option>
                                <option value={'product'} >Produto</option>
                                <option value={'service'} >Serviço</option>
                            </select>
                        </div>

                        <div className="modal-products-container">
                            <h4>Categoria</h4>
                            <select onBlur={() => valuesProduct?.type ?  handleCategory(valuesProduct.type) : null } disabled={!info} onChange={handleChangeProduct} name="category" type="text">
                            <option >Escolha uma opção</option>
                            {
                                categorias && categorias.map(item =>{
                                    return(
                                        <option selected={item.name === categoryResponse ? true : false} key={item.id} value={item.id} >{item.name} </option>
                                    )
                                })
                            }
                            </select>
                        </div>

                        <div className="modal-products-container">
                            <h4>Nome</h4>
                            <input
                                defaultValue={dataProduct.name}
                                disabled={!info}
                                onChange={handleChangeProduct}
                                name="name"
                                placeholder="Qual o nome do produto/serviço?"
                                type="text"
                            />
                        </div>

                        <div className="modal-products-container">
                            <h4>Descrição</h4>
                            <textarea
                                defaultValue={dataProduct.description}
                                disabled={!info}
                                onChange={handleChangeProduct}
                                name="description"
                                placeholder="Uma breve descrição sobre"
                                type="text"
                            />
                        </div>

                        <div className="modal-products-container">
                            <h4>Preço</h4>
                            <IntlCurrencyInput
                                currency="BRL"
                                config={currencyConfig}
                                onChange={handleChangeCurrency}
                                disabled={!info}
                                name="price"
                                defaultValue={dataProduct.price}
                            />
                            {/* <input
                                defaultValue={dataProduct.price}
                                disabled={!info}
                                onChange={handleChangeProduct}
                                name="price"
                                placeholder="Qual o preço do produto/serviço?"
                                type="number"
                            /> */}
                        </div>

                        <div className="modal-products-container">
                            <h4>Quantidade para venda</h4>
                            <input
                                defaultValue={dataProduct.quantity}
                                disabled={!info}
                                onChange={handleChangeProduct}
                                name="quantity"
                                placeholder="Qual a quantidade para venda?"
                                type="number"
                            />
                        </div>

                        <div>
                            <div className="modal-products-container">
                                <h4>Status</h4>
                                <select  disabled={!info} defaultValue={dataProduct.active}  onChange={handleChangeProduct} name="active" type="text">
                                    <option>Escolha uma opção</option>
                                    <option value={true} >Publicado</option>
                                    <option value={false} >Invisível</option>
                                </select>
                            </div>

                        </div>

                    </div>
                    <div style={{marginTop: '30px'}} >
                        <h4>Imagem do seu anúncio nesse momento</h4>
                        <span>A primeira imagem será a imagem de destaque</span>
                        <div>
                                
                            <div className="result result-view">
                            <div  
                            style={{ pointerEvents: !info === true ? 'none' : 'all', position: 'relative'  }}
                            className="result">
                                {
                                    !info === true 
                                    ? <div style={{ backgroundColor: '#EBEBEB', opacity: '0.8',position: 'absolute', height:'100%', width: '100%'  }} ></div>
                                    : null
                                }
                               
                                {renderPhotosBefore(photosProduct)}
                            </div>
    
                            </div>
                        </div>
                    </div>


                    <div style={{marginTop: '30px'}} >
                        <h4>Adicionar mais imagens ao seu produto</h4>
                        <span>Lembrando que o máximo de imagem é 4</span>
                        <div>
                                
                            <div className="result result-view">
                            <div  
                            style={{ pointerEvents: !info === true ? 'none' : 'all', position: 'relative'  }}
                            className="result">
                                {
                                    !info === true 
                                    ? <div style={{ backgroundColor: '#EBEBEB', opacity: '0.8',position: 'absolute', height:'100%', width: '100%'  }} ></div>
                                    : null
                                }
                                <input  disabled={!info}  type="file" id="file" multiple onChange={handleImageChange} />
                                <div className="label-holder">
                                    <label htmlFor="file" className="label">
                                        <i className="material-icons">add_a_photo</i>
                                    </label>
                                </div> 
                                {renderPhotos(selectedFiles)}
                                
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                            {
                                info === false ?
                                null
                                :
                                <div style={{ textAlign: 'right', marginTop: 30 }}>
                                <button onClick={() => history.goBack()} className="btn-adc-item">
                                    Cancelar
                                </button>
                                <button
                                    // onClick={() => { setInfo(false); handleModal()}}
                                    onClick={() => {handleSubmit()}}
                                    style={{
                                        backgroundColor: '#1976d2',
                                        color: '#FFF',
                                        marginLeft: 10,
                                    }}
                                    className="btn-adc-item"
                                >
                                    Salvar
                                </button>
                            </div>

                            }
                
                        </div>
                        }
                        
                    </Paper>

                    </Grid>

                    <Box pt={4}>
                        <Footer />
                    </Box>
                </Container>
            </main>
        </div>
    );
}

export default ActionsProduct;

