import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import MenuAdmin from '../../../components/menu-admin';
import Footer from '../../../components/footer-admin';
import { Paper } from '@material-ui/core';

import './styles.css';
//import CardPedido from '../../../components/cardPedido';

import { history } from '../../../history';

import MaterialTable from 'material-table';
import api from '../../../utils/services';
import { format } from 'date-fns';
import formatStatus from '../../../common/formatstatus';
import ContentModal from '../DevolutionAndCanceled/components/ContentModal';
import { ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 0,
    height: '100vh',
    overflow: 'auto',
    padding: '0',
    width: '100vw',
    backgroundColor: '#f5f4f6',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    flexDirection: 'column',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function Pedidos() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [pedidoSelect, setPedidoSelect] = useState({})
  const [pageSelected, setPageSelected] = useState(1);
  const limitePage = 10;
  const [totalPages, setTotalPages] = useState(0)

  const handlePagination = (action) =>{
    if(action ==='add' && pageSelected+1 <= totalPages){
      api.get(`/sales/requests?page=${pageSelected+1}&limit=${limitePage}&isHistoryRequests=true`)
     .then( res =>{
      let newArray = res.data.requests;
      newArray.map(item => item.date = new Date(item.date))
      setPedidos(newArray)
    })
    .catch( error =>{
      console.log('error', error.response)
      if(error.response.status === 401){
        alert('Sua sessão expirou, faça o Login novamente')
        //logout();
        window.location.reload()
      }
    })
    }else if(action ==='prev' && pageSelected+1 >= 1){
      api.get(`/sales/requests?page=${pageSelected-1}&limit=${limitePage}&isHistoryRequests=true`)
    .then( res =>{
      let newArray = res.data.requests;
      newArray.map(item => item.date = new Date(item.date))
      setPedidos(newArray)
    })
    .catch( error =>{
      console.log('error', error.response)
      if(error.response.status === 401){
        alert('Sua sessão expirou, faça o Login novamente')
        //logout();
        window.location.reload()
      }
    })
    }else{
      alert('Não existe')
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPedidoSelect({})
  };

  useEffect(() => {
    function loadProducts() {
      api
        .get(`/sales/requests?page=1&limit=${limitePage}&isHistoryRequests=true`)
        .then((res) => {
          setTotalPages(Math.ceil(res.data.count/limitePage))
          let newArray = res.data.requests;
          newArray.map((item) => (item.date = new Date(item.date)));
          setPedidos(newArray);
        })
        .catch((error) => console.log('Erro', error));
    }
    loadProducts();
  }, []);

  return (
    <div className={classes.root}>
      <MenuAdmin />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Grid xs={12}>
              <Paper>
                <div style={{ padding: '5px 20px', overflow: 'auto' }}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <h1>Todos os pedidos</h1>
                    <h1
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.goBack()}
                    >
                      <ArrowBack  width="40px" style={{cursor: "pointer"}} />
                    </h1>
                  </div>
                </div>
              </Paper>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12}>
              <Paper elevation={0} style={{padding: '20px 5px'}} >

          
                <MaterialTable
                  elevation={0}
                  columns={[
                    { title: 'Código', field: 'orderNumber' },
                    { title: 'Cliente', field: 'clientName' },
                    {
                      title: 'Data',
                      field: 'date',
                      render: (rowData) =>
                        format(rowData.date, 'dd/MM/yyyy - HH:mm'),
                    },
                    {
                      title: 'Retirada',
                      field: 'delivery',
                      render: (rowData) =>
                        rowData.delivery ? 'Delivery' : 'Retirada em loja',
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      render: (rowData) => formatStatus(rowData.status)
                    },
                  ]}
                  data={pedidos}
                  title="Pedidos"
                  options={{
                    hideFilterIcons: true,
                    actionsColumnIndex: -1,
                    paging: false
                  }}
                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Pesquisar',
                      nRowsSelected: '{0} linha(s) selecionada(s)',
                    },
                    header: {
                      actions: 'Ações',
                    },
                    body: {
                      emptyDataSourceMessage:
                        'Não há registros a serem exibidos',
                    },
                  }}
                  actions={[
                    {
                      icon: 'visibility',
                      tooltip: 'Visualizar pedido',
                      onClick: (_, rowData) => {
                        setPedidoSelect(rowData)
                        handleOpen()
                      },
                    },
                  ]}
                />
            
                <div className='pagination-container' >
                  <div className="pagination-content">
                    <button
                      disabled={pageSelected - 1 >= 1 ? false : true}
                      onClick={() => {
                        setPageSelected(pageSelected - 1)
                        handlePagination('prev')
                      }}>
                      Anterior
                    </button>
                    <input style={{ textAlign: 'center' }} type="text" value={pageSelected} />
                    <input style={{ textAlign: 'center', backgroundColor: "#DDD" }} disabled type="text" value={totalPages} />
                    <button
                      disabled={pageSelected + 1 <= totalPages ? false : true}
                      onClick={() => {
                        setPageSelected(pageSelected + 1)
                        handlePagination('add')
                      }}>
                      Próximo
                    </button>
                  </div>
                </div> 

                  {
                    open &&
                    <ContentModal
                      open={open}
                      handleClose={handleClose}
                      data={pedidoSelect}
                    />
                  }
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}
